import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

const _hoisted_1 = { class: "p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "text-xl font-semibold text-gray-900" }
const _hoisted_3 = { class: "mt-2 text-sm text-gray-700" }
const _hoisted_4 = { class: "flex items-center space-x-4" }
const _hoisted_5 = { class: "space-y-6" }
const _hoisted_6 = { class: "grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mb-6 pb-6 border-b border-gray-200" }
const _hoisted_7 = { class: "font-semibold mb-1" }
const _hoisted_8 = { class: "font-semibold mb-1" }
const _hoisted_9 = { class: "text-base font-semibold leading-6 text-gray-900 mb-6" }
const _hoisted_10 = { class: "grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-6" }
const _hoisted_11 = { class: "mr-2" }
const _hoisted_12 = { class: "" }
const _hoisted_13 = { class: "flex items-center text-sm text-gray-500" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["UiPageHeader"], null, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t("level.view.title")), 1 /* TEXT */),
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("level.view.description")), 1 /* TEXT */)
      ]),
      actions: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          _createVNode($setup["UiButton"], {
            variant: "white",
            "text-variant": "gray-500",
            onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.goBack({ name: 'ListLevel' })))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Back")), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }),
          (_ctx.$can($setup.Permission.EDIT_LEVEL))
            ? (_openBlock(), _createBlock($setup["RouterLink"], {
                key: 0,
                to: {
              name: 'EditLevelById',
              params: {
                id: $setup.route.params.id,
              },
            },
                class: "block hover:bg-gray-50"
              }, {
                default: _withCtx(() => [
                  _createVNode($setup["UiButton"], {
                    variant: "primary",
                    "text-variant": "white",
                    themed: ""
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Edit")), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["to"]))
            : _createCommentVNode("v-if", true)
        ])
      ]),
      _: 1 /* STABLE */
    }),
    _createElementVNode("div", _hoisted_5, [
      _createVNode($setup["UiPanel"], null, {
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", null, [
              _createElementVNode("h3", _hoisted_7, _toDisplayString(_ctx.$t("level.labels.name")), 1 /* TEXT */),
              _createElementVNode("p", null, _toDisplayString($setup.level?.name), 1 /* TEXT */)
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("h3", _hoisted_8, _toDisplayString(_ctx.$t("level.labels.status")), 1 /* TEXT */),
              _createElementVNode("p", {
                class: _normalizeClass([[
                  $setup.level?.isActive
                    ? 'bg-green-100 text-green-800'
                    : 'bg-red-100 text-red-800',
                ], "inline-flex rounded-full px-2 text-sm font-semibold"])
              }, _toDisplayString($setup.level?.isActive
                    ? _ctx.$t("core.options.Active")
                    : _ctx.$t("core.options.Inactive")), 3 /* TEXT, CLASS */)
            ])
          ]),
          _createElementVNode("h3", _hoisted_9, _toDisplayString(_ctx.$t("level.view.section.users.title")), 1 /* TEXT */),
          _createElementVNode("div", _hoisted_10, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.level?.users, (user) => {
              return (_openBlock(), _createElementBlock("div", {
                key: user.id,
                class: "bg-gray-50 p-3 rounded shadow ring-1 ring-black ring-opacity-5 flex items-center text-ellipsis overflow-hidden"
              }, [
                _createElementVNode("div", _hoisted_11, [
                  _createVNode($setup["UiGravatar"], {
                    email: user.email,
                    name: user.name,
                    class: "h-12 w-12 rounded-full ring-1 ring-black ring-opacity-5"
                  }, null, 8 /* PROPS */, ["email", "name"])
                ]),
                _createElementVNode("div", _hoisted_12, [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.$can($setup.Permission.VIEW_USER) ? $setup.RouterLink : 'p'), {
                    to: {
                    name: 'ViewUserById',
                    params: {
                      id: user?.id,
                    },
                  },
                    class: "truncate text-sm font-medium text-primary"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(user?.name), 1 /* TEXT */)
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"])),
                  _createElementVNode("p", _hoisted_13, [
                    _createVNode($setup["MailIcon"], {
                      class: "mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400",
                      "aria-hidden": "true"
                    }),
                    _createTextVNode(" " + _toDisplayString(user?.email), 1 /* TEXT */)
                  ])
                ])
              ]))
            }), 128 /* KEYED_FRAGMENT */))
          ])
        ]),
        _: 1 /* STABLE */
      })
    ])
  ]))
}