import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "font-medium text-gray-600" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "sm:col-span-12" }
const _hoisted_5 = { class: "block text-sm font-medium leading-6 text-gray-900 mb-2" }
const _hoisted_6 = { class: "grid grid-cols-2 mt-5 gap-4 place-content-around text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode($setup["UiNode"], {
      nodeId: $props.node.id,
      showActions: "",
      title: _ctx.$t('connectFlow.node.timeValidation.title'),
      icon: $setup.ClockIcon,
      onEditNode: _cache[0] || (_cache[0] = ($event: any) => ($setup.showForm = true)),
      onRemoveNode: $setup.removeNode,
      class: _normalizeClass($setup.isFormCorrect ? '' : 'shadow-red-300 border-red-300')
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          ($setup.nodeWritable.data.related)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString($setup.nodeWritable.data.related.label), 1 /* TEXT */))
            : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t("connectFlow.node.timeValidation.empty")), 1 /* TEXT */))
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["nodeId", "title", "icon", "class"]),
    _createVNode($setup["UiRightPanel"], {
      modelValue: $setup.showForm,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.showForm) = $event)),
      title: _ctx.$t('connectFlow.node.timeValidation.title'),
      icon: $setup.ClockIcon
    }, {
      default: _withCtx(() => [
        _createElementVNode("form", {
          autocomplete: "off",
          class: "flex flex-col gap-3",
          onSubmit: _withModifiers($setup.onSubmit, ["prevent"])
        }, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t("connectFlow.node.timeValidation.selectLabel")), 1 /* TEXT */),
            _createVNode($setup["UiMultiselect"], {
              name: "schedule",
              modelValue: $setup.v$.schedule.$model,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.v$.schedule.$model) = $event)),
              options: $setup.scheduleOptions,
              label: "name",
              "value-prop": "id",
              errors: $setup.v$.schedule.$errors,
              required: ""
            }, null, 8 /* PROPS */, ["modelValue", "options", "errors"])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode($setup["UiButton"], {
              variant: "white",
              "text-variant": "gray-700",
              themed: "",
              onClick: _cache[2] || (_cache[2] = ($event: any) => ($setup.showForm = false))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Cancel")), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode($setup["UiButton"], {
              type: "submit",
              variant: "primary",
              "text-variant": "white",
              themed: ""
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Apply")), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            })
          ])
        ], 32 /* NEED_HYDRATION */)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "title", "icon"])
  ], 64 /* STABLE_FRAGMENT */))
}