import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "relative h-full w-full" }
const _hoisted_2 = { class: "min-w-full border-separate border-spacing-0" }
const _hoisted_3 = {
  scope: "col",
  class: "sticky top-0 z-10 border-b border-gray-300 bg-gray-200 whitespace-nowrap py-1.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900"
}
const _hoisted_4 = {
  scope: "col",
  class: "sticky top-0 z-10 border-b border-gray-300 bg-gray-200 px-3 py-1.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell max-h-4 whitespace-nowrap"
}
const _hoisted_5 = {
  scope: "col",
  class: "sticky top-0 z-10 border-b border-gray-300 bg-gray-200 px-3 py-1.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell max-h-4 whitespace-nowrap"
}
const _hoisted_6 = {
  scope: "col",
  class: "sticky top-0 z-10 border-b border-gray-300 bg-gray-200 px-3 py-1.5 text-center text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell max-h-4 whitespace-nowrap"
}
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "whitespace-nowrap border-b border-gray-200 py-1.5 px-3 text-sm font-medium" }
const _hoisted_9 = { class: "whitespace-nowrap border-b border-gray-200 px-3 py-1.5 text-sm font-medium" }
const _hoisted_10 = { class: "whitespace-nowrap border-b border-gray-200 px-3 py-1.5 text-sm font-medium" }
const _hoisted_11 = { class: "whitespace-nowrap border-b border-gray-200 py-1.5 px-3 text-sm font-medium text-center" }
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { class: "text-gray-700" }
const _hoisted_14 = {
  colspan: "4",
  class: "whitespace-nowrap border-b border-gray-200 px-3 text-sm font-medium py-5 text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("table", _hoisted_2, [
      _createElementVNode("thead", null, [
        _createElementVNode("tr", null, [
          _createElementVNode("th", _hoisted_3, _toDisplayString(_ctx.$t("report.realtime.detailsQueue.sourceNumber")), 1 /* TEXT */),
          _createElementVNode("th", _hoisted_4, _toDisplayString(_ctx.$t("report.realtime.detailsQueue.destinationNumber")), 1 /* TEXT */),
          _createElementVNode("th", _hoisted_5, _toDisplayString(_ctx.$t("report.realtime.detailsQueue.queueName")), 1 /* TEXT */),
          _createElementVNode("th", _hoisted_6, _toDisplayString(_ctx.$t("report.realtime.detailsQueue.duration")), 1 /* TEXT */)
        ])
      ]),
      ($setup.items.length)
        ? (_openBlock(), _createElementBlock("tbody", _hoisted_7, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.items, (item, idx) => {
              return (_openBlock(), _createElementBlock("tr", {
                key: `queueDetailItem${idx}`,
                class: "text-gray-700"
              }, [
                _createElementVNode("td", _hoisted_8, _toDisplayString($setup.applyPhoneMask(item.numberFrom)), 1 /* TEXT */),
                _createElementVNode("td", _hoisted_9, _toDisplayString($setup.applyPhoneMask(item.numberTo)), 1 /* TEXT */),
                _createElementVNode("td", _hoisted_10, _toDisplayString(item.queueName), 1 /* TEXT */),
                _createElementVNode("td", _hoisted_11, _toDisplayString(item.duration ? $setup.formatSecToTime(item.duration) : "-"), 1 /* TEXT */)
              ]))
            }), 128 /* KEYED_FRAGMENT */))
          ]))
        : (_openBlock(), _createElementBlock("tbody", _hoisted_12, [
            _createElementVNode("tr", _hoisted_13, [
              _createElementVNode("td", _hoisted_14, _toDisplayString(_ctx.$t("report.realtime.detailsQueue.empty")), 1 /* TEXT */)
            ])
          ]))
    ]),
    ($setup.loading)
      ? (_openBlock(), _createBlock($setup["UiSpinner"], {
          key: 0,
          class: "h-5 w-5 ml-3 absolute bottom-3 right-4 theme-color"
        }))
      : _createCommentVNode("v-if", true)
  ]))
}