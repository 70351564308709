import { createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-col justify-end pb-1.5 mt-6"
}
const _hoisted_2 = { class: "grid grid-cols-2 mt-7 gap-4 place-content-around text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["UiRightPanel"], {
    modelValue: $setup.internalShow,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.internalShow) = $event)),
    title: _ctx.$t('connectFlow.edge.titleInfoClient')
  }, {
    default: _withCtx(() => [
      _createElementVNode("form", {
        autocomplete: "off",
        class: "flex flex-col gap-3",
        onSubmit: _withModifiers($setup.onSubmit, ["prevent"])
      }, [
        _createVNode($setup["UiTextInput"], {
          label: _ctx.$t('connectFlow.edge.uraOption'),
          name: "uraOption",
          type: "number",
          modelValue: $setup.v$.option.$model,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.v$.option.$model) = $event)),
          disabled: $setup.formState.waitUser ?? false,
          errors: $setup.v$.waitUser.$errors,
          "silent-errors": $setup.v$.waitUser.$silentErrors
        }, null, 8 /* PROPS */, ["label", "modelValue", "disabled", "errors", "silent-errors"]),
        ($setup.nodeTarget?.data.component === $setup.NodeType.TRANSFER_EXTENSION)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode($setup["UiCheckboxInput"], {
                modelValue: $setup.v$.waitUser.$model,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.v$.waitUser.$model) = $event)),
                name: "isActive",
                label: _ctx.$t('connectFlow.edge.waitUser'),
                class: "h-6",
                errors: $setup.v$.waitUser.$errors
              }, null, 8 /* PROPS */, ["modelValue", "label", "errors"]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.v$.waitUser.$errors, (error) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "text-sm text-danger bg-red-50 px-2 py-2 w-full shadow z-10",
                  key: error.$uid
                }, [
                  _createElementVNode("p", null, _toDisplayString(error.$response ? error.$response : error.$message), 1 /* TEXT */)
                ]))
              }), 128 /* KEYED_FRAGMENT */))
            ]))
          : _createCommentVNode("v-if", true),
        _createElementVNode("div", _hoisted_2, [
          _createVNode($setup["UiButton"], {
            variant: "white",
            "text-variant": "gray-700",
            themed: "",
            onClick: _cache[2] || (_cache[2] = ($event: any) => ($setup.internalShow = false))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Cancel")), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode($setup["UiButton"], {
            type: "submit",
            variant: "primary",
            "text-variant": "white",
            themed: ""
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Apply")), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          })
        ])
      ], 32 /* NEED_HYDRATION */)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "title"]))
}