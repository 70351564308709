import { normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full flex items-end gap-1.5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["UiTextInput"], {
      name: "extension",
      type: "text",
      label: _ctx.$t('connectFlow.node.transferToExternal.number'),
      class: _normalizeClass($setup.props.removable ? 'w-11/12' : 'w-full'),
      modelValue: $setup.number,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.number) = $event)),
      modelModifiers: { lazy: true },
      errors: $setup.v$.formState.$silentErrors,
      "is-phone-number": ""
    }, null, 8 /* PROPS */, ["label", "class", "modelValue", "errors"]),
    ($setup.props.removable)
      ? (_openBlock(), _createBlock($setup["UiButton"], {
          key: 0,
          class: "text-lg py-4",
          variant: "white",
          size: "md",
          onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.emit('remove')))
        }, {
          default: _withCtx(() => [
            _createVNode($setup["TrashIcon"], { class: "h-4 w-4 text-gray-500" })
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true)
  ]))
}