import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "text-xl font-semibold text-gray-900" }
const _hoisted_3 = { class: "mt-2 text-sm text-gray-700" }
const _hoisted_4 = { class: "text-base font-semibold leading-6 text-gray-900" }
const _hoisted_5 = { class: "mt-1 text-sm text-gray-500" }
const _hoisted_6 = { class: "grid grid-cols-6 gap-6" }
const _hoisted_7 = { class: "col-span-6 sm:col-span-4" }
const _hoisted_8 = {
  for: "level",
  class: "block text-sm font-medium leading-6 text-gray-900"
}
const _hoisted_9 = { class: "col-span-6 sm:col-span-4" }
const _hoisted_10 = {
  for: "level",
  class: "block text-sm font-medium leading-6 text-gray-900"
}
const _hoisted_11 = { class: "flex justify-end px-4 sm:px-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["UiPageHeader"], null, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t("word." + $setup.props.action + ".title")), 1 /* TEXT */),
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("word." + $setup.props.action + ".description")), 1 /* TEXT */)
      ]),
      _: 1 /* STABLE */
    }),
    _createElementVNode("form", {
      class: "space-y-6",
      autocomplete: "off",
      onSubmit: _withModifiers($setup.onSubmit, ["prevent"])
    }, [
      _createVNode($setup["UiContainerSection"], null, {
        title: _withCtx(() => [
          _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.$t("word." + $setup.props.action + ".section.title")), 1 /* TEXT */),
          _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t("word." + $setup.props.action + ".section.description")), 1 /* TEXT */)
        ]),
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t("word.labels.level")), 1 /* TEXT */),
              _createVNode($setup["UiMultiselect"], {
                name: "level",
                label: "name",
                "value-prop": "id",
                modelValue: $setup.formState.levelId,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.formState.levelId) = $event)),
                primitive: true,
                options: $setup.levels
              }, null, 8 /* PROPS */, ["modelValue", "options"])
            ]),
            _createVNode($setup["UiTextInput"], {
              modelValue: $setup.v$.word.$model,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.v$.word.$model) = $event)),
              name: "word",
              type: "text",
              label: _ctx.$t('word.labels.word'),
              class: "col-span-6 sm:col-span-4",
              errors: $setup.v$.word.$errors,
              onBlur: $setup.v$.word.$touch
            }, null, 8 /* PROPS */, ["modelValue", "label", "errors", "onBlur"]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t("word.labels.callParticipant")), 1 /* TEXT */),
              _createVNode($setup["UiMultiselect"], {
                name: "callParticipants",
                modelValue: $setup.formState.callParticipant,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.formState.callParticipant) = $event)),
                primitive: true,
                options: $setup.participants,
                errors: $setup.v$.callParticipant.$errors
              }, null, 8 /* PROPS */, ["modelValue", "errors"])
            ]),
            _createVNode($setup["UiCheckboxInput"], {
              modelValue: $setup.v$.isActive.$model,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.v$.isActive.$model) = $event)),
              name: "isActive",
              label: _ctx.$t('word.labels.isActive'),
              class: "h-6 col-span-6 sm:col-span-4"
            }, null, 8 /* PROPS */, ["modelValue", "label"])
          ])
        ]),
        _: 1 /* STABLE */
      }),
      _createElementVNode("div", _hoisted_11, [
        _createVNode($setup["UiButton"], {
          variant: "white",
          "text-variant": "gray-500",
          onClick: $setup.handleBackClick
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Cancel")), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        (
            ($setup.props.action == $setup.FormAction.CREATE &&
              _ctx.$can($setup.Permission.CREATE_WORD)) ||
            ($setup.props.action == $setup.FormAction.EDIT && _ctx.$can($setup.Permission.EDIT_WORD))
          )
          ? (_openBlock(), _createBlock($setup["UiButton"], {
              key: 0,
              type: "submit",
              variant: "primary",
              "text-variant": "white",
              themed: "",
              class: "ml-4"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Save")), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true)
      ])
    ], 32 /* NEED_HYDRATION */),
    _createVNode($setup["UiCheckDirtyBeforeRouteLeave"], {
      dirty: $setup.v$.$anyDirty
    }, null, 8 /* PROPS */, ["dirty"])
  ]))
}