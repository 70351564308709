import { createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "grid grid-cols-2 mt-7 gap-4 place-content-around text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["UiRightPanel"], {
    modelValue: $setup.internalShow,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.internalShow) = $event)),
    title: _ctx.$t('connectFlow.edge.titleAwaitable')
  }, {
    default: _withCtx(() => [
      _createElementVNode("form", {
        autocomplete: "off",
        class: "flex flex-col gap-3",
        onSubmit: _withModifiers($setup.onSubmit, ["prevent"])
      }, [
        _createVNode($setup["UiTextInput"], {
          label: _ctx.$t('connectFlow.edge.timerWait'),
          name: "timerWait",
          type: "number",
          modelValue: $setup.v$.timer.$model,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.v$.timer.$model) = $event)),
          errors: $setup.v$.timer.$errors,
          "silent-errors": $setup.v$.timer.$silentErrors
        }, null, 8 /* PROPS */, ["label", "modelValue", "errors", "silent-errors"]),
        _createElementVNode("div", _hoisted_1, [
          _createVNode($setup["UiButton"], {
            variant: "white",
            "text-variant": "gray-700",
            themed: "",
            onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.internalShow = false))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Cancel")), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode($setup["UiButton"], {
            type: "submit",
            variant: "primary",
            "text-variant": "white",
            themed: ""
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Apply")), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          })
        ])
      ], 32 /* NEED_HYDRATION */)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "title"]))
}