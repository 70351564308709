import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-5 grid grid-cols-1 sm:grid-cols-5 gap-4" }
const _hoisted_2 = { class: "overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6" }
const _hoisted_3 = { class: "truncate text-sm font-medium text-gray-500 flex items-center" }
const _hoisted_4 = { class: "mt-1 text-3xl font-semibold tracking-tight text-gray-900" }
const _hoisted_5 = { class: "overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6" }
const _hoisted_6 = { class: "truncate text-sm font-medium text-gray-500 flex items-center" }
const _hoisted_7 = { class: "mt-1 text-3xl font-semibold tracking-tight text-gray-900" }
const _hoisted_8 = { class: "overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6" }
const _hoisted_9 = { class: "truncate text-sm font-medium text-gray-500 flex items-center" }
const _hoisted_10 = { class: "mt-1 text-3xl font-semibold tracking-tight text-gray-900" }
const _hoisted_11 = { class: "overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6" }
const _hoisted_12 = { class: "truncate text-sm font-medium text-gray-500 flex items-center" }
const _hoisted_13 = { class: "mt-1 text-3xl font-semibold tracking-tight text-gray-900" }
const _hoisted_14 = { class: "overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6" }
const _hoisted_15 = { class: "truncate text-sm font-medium text-gray-500 flex items-center" }
const _hoisted_16 = { class: "mt-1 text-3xl font-semibold tracking-tight text-gray-900" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("dl", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("dt", _hoisted_3, [
        _createElementVNode("span", null, _toDisplayString(_ctx.$t("report.realtime.callQueue")), 1 /* TEXT */),
        ($setup.props.loading)
          ? (_openBlock(), _createBlock($setup["UiSpinner"], {
              key: 0,
              class: "h-4 w-4 ml-3 theme-color"
            }))
          : _createCommentVNode("v-if", true)
      ]),
      _createElementVNode("dd", _hoisted_4, _toDisplayString($setup.props.data.callQueue ?? "-"), 1 /* TEXT */)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("dt", _hoisted_6, [
        _createElementVNode("span", null, _toDisplayString(_ctx.$t("report.realtime.callInboundInProgress")), 1 /* TEXT */),
        ($setup.props.loading)
          ? (_openBlock(), _createBlock($setup["UiSpinner"], {
              key: 0,
              class: "h-4 w-4 ml-3 theme-color"
            }))
          : _createCommentVNode("v-if", true)
      ]),
      _createElementVNode("dd", _hoisted_7, _toDisplayString($setup.props.data.callInboundInProgress ?? "-"), 1 /* TEXT */)
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("dt", _hoisted_9, [
        _createElementVNode("span", null, _toDisplayString(_ctx.$t("report.realtime.callOutboundInProgress")), 1 /* TEXT */),
        ($setup.props.loading)
          ? (_openBlock(), _createBlock($setup["UiSpinner"], {
              key: 0,
              class: "h-4 w-4 ml-3 theme-color"
            }))
          : _createCommentVNode("v-if", true)
      ]),
      _createElementVNode("dd", _hoisted_10, _toDisplayString($setup.props.data.callOutboundInProgress ?? "-"), 1 /* TEXT */)
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("dt", _hoisted_12, [
        _createElementVNode("span", null, _toDisplayString(_ctx.$t("report.realtime.devicesOffline")), 1 /* TEXT */),
        ($setup.props.loading)
          ? (_openBlock(), _createBlock($setup["UiSpinner"], {
              key: 0,
              class: "h-4 w-4 ml-3 theme-color"
            }))
          : _createCommentVNode("v-if", true)
      ]),
      _createElementVNode("dd", _hoisted_13, _toDisplayString($setup.props.data.userOffline ?? "-"), 1 /* TEXT */)
    ]),
    _createElementVNode("div", _hoisted_14, [
      _createElementVNode("dt", _hoisted_15, [
        _createElementVNode("span", null, _toDisplayString(_ctx.$t("report.realtime.averageQuality")), 1 /* TEXT */),
        ($setup.props.loading)
          ? (_openBlock(), _createBlock($setup["UiSpinner"], {
              key: 0,
              class: "h-4 w-4 ml-3 theme-color"
            }))
          : _createCommentVNode("v-if", true)
      ]),
      _createElementVNode("dd", _hoisted_16, _toDisplayString($setup.props.data.averageQuality ?? "-"), 1 /* TEXT */)
    ])
  ]))
}