import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  for: "search-field",
  class: "sr-only"
}
const _hoisted_2 = { class: "pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2 pr-5" }
const _hoisted_3 = ["placeholder", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("label", _hoisted_1, _toDisplayString(_ctx.$t("core.actions.Search")), 1 /* TEXT */),
    _createElementVNode("div", {
      class: _normalizeClass(["relative w-full text-gray-400 focus-within:text-gray-600", $setup.props.class])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode($setup["SearchIcon"], {
          class: "h-5 w-5 flex-shrink-0",
          "aria-hidden": "true"
        })
      ]),
      _createElementVNode("input", {
        name: "search-field",
        id: "search-field",
        class: "h-full w-full border-transparent py-2 pl-8 pr-3 text-base text-gray-900 placeholder-gray-500 focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-0 bg-transparent",
        placeholder: $setup.placeholderText,
        type: "search",
        value: $setup.props.modelValue,
        onInput: _cache[0] || (_cache[0] = ($event: any) => {
        $setup.dbounce(() => {
          $setup.updateValue(($event.target as HTMLInputElement).value);
        }, $setup.props.debounce)()
      })
      }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_3)
    ], 2 /* CLASS */)
  ], 64 /* STABLE_FRAGMENT */))
}