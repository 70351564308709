import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "text-xl font-semibold text-gray-900" }
const _hoisted_3 = { class: "mt-2 text-sm text-gray-700" }
const _hoisted_4 = { class: "grid grid-cols-1 md:grid-cols-3 gap-5 mb-5" }
const _hoisted_5 = {
  for: "level",
  class: "block text-sm font-medium leading-6 mb-2 text-gray-900"
}
const _hoisted_6 = {
  for: "users",
  class: "block text-sm font-medium leading-6 mb-2 text-gray-900"
}
const _hoisted_7 = {
  for: "level",
  class: "block text-sm font-medium leading-6 mb-2 text-gray-900"
}
const _hoisted_8 = { class: "grid grid-cols-1 md:grid-cols-4 gap-5" }
const _hoisted_9 = { class: "col-span-2" }
const _hoisted_10 = {
  for: "level",
  class: "block text-sm font-medium leading-6 mb-2 text-gray-900"
}
const _hoisted_11 = {
  key: 0,
  controls: "",
  class: "w-full mb-3"
}
const _hoisted_12 = ["src", "type"]
const _hoisted_13 = { class: "flex justify-end px-4 sm:px-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["UiPageHeader"], null, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t("queue." + $setup.props.action + ".title")), 1 /* TEXT */),
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("queue." + $setup.props.action + ".description")), 1 /* TEXT */)
      ]),
      _: 1 /* STABLE */
    }),
    _createElementVNode("form", {
      class: "space-y-6",
      autocomplete: "off",
      onSubmit: _withModifiers($setup.onSubmit, ["prevent"])
    }, [
      _createVNode($setup["UiPanel"], null, {
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", null, [
              _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t("queue.labels.level")), 1 /* TEXT */),
              _createVNode($setup["UiMultiselect"], {
                name: "level",
                label: "name",
                "value-prop": "id",
                modelValue: $setup.formState.levelId,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.formState.levelId) = $event)),
                primitive: true,
                options: $setup.levels
              }, null, 8 /* PROPS */, ["modelValue", "options"])
            ]),
            _createVNode($setup["UiTextInput"], {
              modelValue: $setup.formState.name,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.formState.name) = $event)),
              name: "name",
              type: "text",
              label: _ctx.$t('queue.labels.name'),
              errors: $setup.v$.name.$errors,
              onBlur: $setup.v$.name.$touch
            }, null, 8 /* PROPS */, ["modelValue", "label", "errors", "onBlur"]),
            _createElementVNode("div", null, [
              _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t("queue.labels.users")), 1 /* TEXT */),
              _createVNode($setup["UiMultiselect"], {
                id: "users",
                name: "users",
                label: "name",
                "value-prop": "id",
                modelValue: $setup.formState.users,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.formState.users) = $event)),
                multiple: "",
                primitive: true,
                options: $setup.users
              }, null, 8 /* PROPS */, ["modelValue", "options"])
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t("queue.labels.strategy")), 1 /* TEXT */),
              _createVNode($setup["UiMultiselect"], {
                name: "strategy",
                modelValue: $setup.formState.strategy,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.formState.strategy) = $event)),
                primitive: true,
                options: $setup.strategyOptions
              }, null, 8 /* PROPS */, ["modelValue", "options"])
            ]),
            _createVNode($setup["UiCheckboxInput"], {
              modelValue: $setup.formState.isActive,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.formState.isActive) = $event)),
              name: "isActive",
              label: _ctx.$t('queue.labels.isActive')
            }, null, 8 /* PROPS */, ["modelValue", "label"])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("label", _hoisted_10, _toDisplayString($setup.t("queue.labels.customAudio")), 1 /* TEXT */),
              ($setup.audioData)
                ? (_openBlock(), _createElementBlock("audio", _hoisted_11, [
                    _createElementVNode("source", {
                      src: $setup.audioData,
                      type: $setup.getAudioFormat($setup.formState.fileAudio || '')
                    }, null, 8 /* PROPS */, _hoisted_12)
                  ]))
                : _createCommentVNode("v-if", true),
              _createVNode($setup["UiAudioUpload"], {
                id: `audioUpload`,
                class: "flex-grow w-full md:w-auto",
                errors: $setup.v$.fileAudio.$errors,
                audioData: $setup.audioData,
                filePath: $setup.formState.fileAudio,
                "onUpdate:file": $setup.handleFileUpdate,
                "text-rule": _ctx.$t('connectFlowAudio.create.uploadRules')
              }, null, 8 /* PROPS */, ["errors", "audioData", "filePath", "text-rule"])
            ])
          ])
        ]),
        _: 1 /* STABLE */
      }),
      _createElementVNode("div", _hoisted_13, [
        _createVNode($setup["UiButton"], {
          variant: "white",
          "text-variant": "gray-500",
          to: { name: 'ListQueue' }
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Cancel")), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        (
            ($setup.props.action == $setup.FormAction.CREATE &&
              _ctx.$can($setup.Permission.CREATE_QUEUE)) ||
            ($setup.props.action == $setup.FormAction.EDIT && _ctx.$can($setup.Permission.EDIT_QUEUE))
          )
          ? (_openBlock(), _createBlock($setup["UiButton"], {
              key: 0,
              type: "submit",
              variant: "primary",
              "text-variant": "white",
              themed: "",
              class: "ml-4"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Save")), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true)
      ])
    ], 32 /* NEED_HYDRATION */),
    _createVNode($setup["UiCheckDirtyBeforeRouteLeave"], {
      dirty: $setup.v$.$anyDirty
    }, null, 8 /* PROPS */, ["dirty"])
  ]))
}