import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = {
  key: 1,
  class: "bg-cyan-100 text-cyan-700 flex items-center justify-center font-semibold text-lg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($setup.showImage)
    ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        src: $setup.gravatarUrl,
        onError: $setup.handleImageError
      }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_1))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString($setup.initials), 1 /* TEXT */))
}