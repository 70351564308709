import { createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "p-4 pt-0 sm:p-6 sm:pt-0 lg:p-8 lg:pt-0"
}
const _hoisted_2 = { class: "flex flex-row-reverse" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return ($setup.call !== null && !$setup.isLoading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_router_link, {
            class: "text-primary hover:text-primary-dark right-0 p-1.5 w-fit m-1.5 flex flex-row items-center",
            to: {
          name: 'CallList',
        }
          }, {
            default: _withCtx(() => [
              _createVNode($setup["ChevronLeftIcon"], { class: "h-5 w-6" }),
              _createTextVNode(" " + _toDisplayString(_ctx.$t("core.actions.Back")), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _createVNode($setup["CallDetails"]),
        _createVNode($setup["CallTags"]),
        _createVNode($setup["CallPlayer"], { class: "mt-5" }),
        _createVNode($setup["CallWordsInsight"]),
        ($setup.call?.survey)
          ? (_openBlock(), _createBlock($setup["CallSurveyAnswers"], {
              key: 0,
              surveyAnswers: $setup.call.survey
            }, null, 8 /* PROPS */, ["surveyAnswers"]))
          : _createCommentVNode("v-if", true),
        ($setup.chatBotIntegration)
          ? (_openBlock(), _createBlock($setup["CallChatBot"], {
              key: 1,
              chat: $setup.chatBotIntegration
            }, null, 8 /* PROPS */, ["chat"]))
          : _createCommentVNode("v-if", true)
      ]))
    : _createCommentVNode("v-if", true)
}