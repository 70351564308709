import { createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, resolveComponent as _resolveComponent, createSlots as _createSlots, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "text-xl font-semibold text-gray-900 flex items-center" }
const _hoisted_3 = { class: "mt-5" }
const _hoisted_4 = { class: "flex h-14 bg-gray-100 rounded ring-1 ring-black ring-opacity-5" }
const _hoisted_5 = { class: "flex flex-1 justify-between px-4" }
const _hoisted_6 = { class: "ml-2 flex items-center space-x-4 sm:ml-6 sm:space-x-6" }
const _hoisted_7 = { class: "ml-2 flex items-center space-x-4 sm:ml-6 sm:space-x-4" }
const _hoisted_8 = {
  key: 0,
  class: "flex p-2 border-t border-gray-200 bg-gray-100 gap-3 rounded ring-1 ring-black ring-opacity-5"
}
const _hoisted_9 = { role: "list" }
const _hoisted_10 = {
  key: 0,
  class: "flex flex-col flex-wrap items-center justify-center h-48 bg-gray-200 mt-0.5 rounded-b font-semibold text-gray-600"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["UiPageHeader"], null, _createSlots({
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, [
          _createVNode($setup["UsersIcon"], {
            class: "w-6 h-6 mr-1 text-gray-500",
            "aria-hidden": "true"
          }),
          _createTextVNode(" " + _toDisplayString(_ctx.$t("core.entity.Users")), 1 /* TEXT */)
        ])
      ]),
      _: 2 /* DYNAMIC */
    }, [
      (_ctx.$can($setup.Permission.CREATE_USER))
        ? {
            name: "actions",
            fn: _withCtx(() => [
              _createVNode(_component_router_link, { to: {
            name: 'CreateUser',
          } }, {
                default: _withCtx(() => [
                  _createVNode($setup["UiButton"], {
                    variant: "primary",
                    "text-variant": "white",
                    themed: ""
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Add")), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              })
            ]),
            key: "0"
          }
        : undefined
    ]), 1024 /* DYNAMIC_SLOTS */),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("form", {
            class: "flex flex-1",
            action: "#",
            method: "GET",
            onSubmit: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"]))
          }, [
            _createVNode($setup["UiListSearch"], {
              modelValue: $setup.search,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.search) = $event)),
              placeholder: "Pesquisar usuário"
            }, null, 8 /* PROPS */, ["modelValue"])
          ], 32 /* NEED_HYDRATION */),
          _createElementVNode("div", _hoisted_6, [
            _createVNode($setup["UiButton"], {
              variant: "white",
              "text-variant": "primary",
              themed: "",
              onClick: _cache[2] || (_cache[2] = ($event: any) => ($setup.getCsv()))
            }, {
              default: _withCtx(() => [
                _createVNode($setup["DocumentDownloadIcon"], {
                  class: "-ml-1 mr-2 h-5 w-5",
                  "aria-hidden": "true"
                }),
                _createTextVNode(" " + _toDisplayString(_ctx.$t("core.actions.export")), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode($setup["UserListSort"], {
              modelValue: $setup.sortMenuSelected,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.sortMenuSelected) = $event))
            }, null, 8 /* PROPS */, ["modelValue"]),
            _createVNode($setup["UserListFilters"])
          ])
        ])
      ]),
      ($setup.sortMenuSelected.length || $setup.activeFilters.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.sortMenuSelected, (selected) => {
              return (_openBlock(), _createBlock($setup["UiSortMenuBadge"], {
                key: selected.key,
                item: selected,
                onRemove: ($event: any) => ($setup.handleRemoveSortClick(selected))
              }, null, 8 /* PROPS */, ["item", "onRemove"]))
            }), 128 /* KEYED_FRAGMENT */)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.activeFilters, (item) => {
              return (_openBlock(), _createBlock($setup["UiBadge"], {
                removable: "",
                key: `activeFilter${item.field}`,
                onRemove: ($event: any) => ($setup.handleRemoveStatusFilterClick(item.field))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t(`user.filters.${item.field}.label`)) + ": " + _toDisplayString(item.label), 1 /* TEXT */)
                ]),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onRemove"]))
            }), 128 /* KEYED_FRAGMENT */))
          ]))
        : _createCommentVNode("v-if", true),
      _createElementVNode("ul", _hoisted_9, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.users, (user) => {
          return (_openBlock(), _createBlock($setup["UserListItem"], {
            key: user.id,
            "model-value": user,
            onActivate: $setup.handleActivateClick,
            onInactivate: $setup.handleInactivateClick
          }, null, 8 /* PROPS */, ["model-value"]))
        }), 128 /* KEYED_FRAGMENT */)),
        (!$setup.users.length)
          ? (_openBlock(), _createElementBlock("li", _hoisted_10, _toDisplayString(_ctx.$t("core.messages.noRecordsFound")), 1 /* TEXT */))
          : _createCommentVNode("v-if", true)
      ]),
      ($setup.users.length)
        ? (_openBlock(), _createBlock($setup["UiPagination"], {
            key: 1,
            modelValue: $setup.currentPage,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.currentPage) = $event)),
            count: $setup.count,
            perPage: $setup.perPage,
            i18n: _ctx.$t,
            class: "h-14 px-4 bg-gray-100 rounded ring-1 ring-black ring-opacity-5"
          }, null, 8 /* PROPS */, ["modelValue", "count", "i18n"]))
        : _createCommentVNode("v-if", true)
    ])
  ]))
}