import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "text-xl font-semibold text-gray-900" }
const _hoisted_3 = {
  key: 0,
  class: "mt-5 flex px-4 py-5 sm:p-6 bg-white shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"
}
const _hoisted_4 = { class: "truncate text-sm font-medium text-gray-500" }
const _hoisted_5 = { class: "mt-1 text-3xl font-semibold tracking-tight text-gray-900" }
const _hoisted_6 = {
  key: 2,
  class: "mt-5 grid grid-cols-1 gap-5 lg:grid-cols-2"
}
const _hoisted_7 = { class: "h-[300px] -mx-4 sm:-mx-6 md:mx-0 bg-white shadow ring-1 ring-black ring-opacity-5 md:rounded-lg col-span-2 md:col-span-1" }
const _hoisted_8 = { class: "h-[300px] -mx-4 sm:-mx-6 md:mx-0 bg-white shadow ring-1 ring-black ring-opacity-5 md:rounded-lg col-span-2 md:col-span-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["UiPageHeader"], null, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t("report.contactCenterPerDay.title")), 1 /* TEXT */)
      ]),
      actions: _withCtx(() => [
        _createVNode($setup["ContactCenterPerDayReportFilters"]),
        _createVNode($setup["UiDatePicker"], {
          modelValue: $setup.dateRange,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.dateRange) = $event)),
          range: "",
          multiCalendars: ""
        }, null, 8 /* PROPS */, ["modelValue"])
      ]),
      _: 1 /* STABLE */
    }),
    ($setup.activeFilters.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.activeFilters, (filter) => {
            return (_openBlock(), _createBlock($setup["UiBadge"], {
              key: filter,
              removable: "",
              onRemove: ($event: any) => ($setup.handleRemoveFilter(filter))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t(`report.contactCenterPerDay.filters.activeTag.${filter.field}`)) + ": " + _toDisplayString(filter.label), 1 /* TEXT */)
              ]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onRemove"]))
          }), 128 /* KEYED_FRAGMENT */))
        ]))
      : _createCommentVNode("v-if", true),
    ($setup.reportInfo?.stats)
      ? (_openBlock(), _createElementBlock("dl", {
          key: 1,
          class: _normalizeClass(["mt-5 grid grid-cols-1 gap-5", ['sm:grid-cols-' + $setup.headerStats.length]])
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.headerStats, (stat) => {
            return (_openBlock(), _createElementBlock("div", {
              key: stat.name,
              class: "overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6"
            }, [
              _createElementVNode("dt", _hoisted_4, _toDisplayString(_ctx.$t("report.contactCenterPerDay.stats." + stat.name)), 1 /* TEXT */),
              _createElementVNode("dd", _hoisted_5, _toDisplayString(stat.value), 1 /* TEXT */)
            ]))
          }), 128 /* KEYED_FRAGMENT */))
        ], 2 /* CLASS */))
      : _createCommentVNode("v-if", true),
    ($setup.reportInfo)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createVNode($setup["CallTypePerDayColumnChart"], {
              title: 
            $setup.t('report.contactCenterPerDay.callTypePerDayColumnChart.title')
          ,
              data: $setup.reportInfo.callsStatsPerDay
            }, null, 8 /* PROPS */, ["title", "data"])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode($setup["CallStatusPerDayColumnChart"], {
              title: 
            $setup.t('report.contactCenterPerDay.callStatusPerDayColumnChart.title')
          ,
              data: $setup.reportInfo.callsStatsPerDay
            }, null, 8 /* PROPS */, ["title", "data"])
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.charts, (chart, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "h-[300px] -mx-4 sm:-mx-6 md:mx-0 bg-white shadow ring-1 ring-black ring-opacity-5 md:rounded-lg col-span-2 md:col-span-1"
            }, [
              _createVNode($setup["BarChart"], {
                title: chart.title,
                categoryData: chart.category,
                seriesData: chart.series,
                xAxisLabelFormatter: $setup.xAxisLabelFormatterAsDate,
                yAxisLabelFormatter: chart.yAxisLabelFormatter,
                column: ""
              }, null, 8 /* PROPS */, ["title", "categoryData", "seriesData", "yAxisLabelFormatter"])
            ]))
          }), 128 /* KEYED_FRAGMENT */))
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}