import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "p-4 sm:p-6 lg:p-8"
}
const _hoisted_2 = { class: "text-xl font-semibold text-gray-900" }
const _hoisted_3 = { class: "mt-2 text-sm text-gray-700" }
const _hoisted_4 = { class: "space-y-6" }
const _hoisted_5 = { class: "text-base font-semibold leading-6 text-gray-900" }
const _hoisted_6 = { class: "mt-1 text-sm text-gray-500" }
const _hoisted_7 = { class: "bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6" }
const _hoisted_8 = { class: "text-sm font-medium text-gray-500" }
const _hoisted_9 = { class: "mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0" }
const _hoisted_10 = { class: "px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6" }
const _hoisted_11 = { class: "text-sm font-medium text-gray-500" }
const _hoisted_12 = { class: "mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0" }
const _hoisted_13 = { class: "bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6" }
const _hoisted_14 = { class: "text-sm font-medium text-gray-500" }
const _hoisted_15 = { class: "mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0" }
const _hoisted_16 = { class: "bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6" }
const _hoisted_17 = { class: "text-sm font-medium text-gray-500" }
const _hoisted_18 = { class: "mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0" }
const _hoisted_19 = { class: "flex justify-end px-4 sm:px-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($setup.word !== undefined)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode($setup["UiPageHeader"], null, {
          info: _withCtx(() => [
            _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t("word.view.title")), 1 /* TEXT */),
            _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("word.view.description")), 1 /* TEXT */)
          ]),
          actions: _withCtx(() => [
            (_ctx.$can($setup.Permission.EDIT_WORD))
              ? (_openBlock(), _createBlock($setup["RouterLink"], {
                  key: 0,
                  to: {
            name: 'EditWordById',
            params: {
              id: $setup.word?.id,
            },
          },
                  class: "block hover:bg-gray-50"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["UiButton"], {
                      variant: "primary",
                      "text-variant": "white",
                      themed: ""
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Edit")), 1 /* TEXT */)
                      ]),
                      _: 1 /* STABLE */
                    })
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["to"]))
              : _createCommentVNode("v-if", true)
          ]),
          _: 1 /* STABLE */
        }),
        _createElementVNode("div", _hoisted_4, [
          _createVNode($setup["UiContainerSection"], null, {
            title: _withCtx(() => [
              _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.$t("word.view.section.word.title")), 1 /* TEXT */),
              _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t("word.view.section.word.description")), 1 /* TEXT */)
            ]),
            content: _withCtx(() => [
              _createElementVNode("dl", null, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("dt", _hoisted_8, _toDisplayString(_ctx.$t("word.labels.level")), 1 /* TEXT */),
                  _createElementVNode("dd", _hoisted_9, _toDisplayString($setup.word?.level.name), 1 /* TEXT */)
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("dt", _hoisted_11, _toDisplayString(_ctx.$t("word.labels.word")), 1 /* TEXT */),
                  _createElementVNode("dd", _hoisted_12, _toDisplayString($setup.word.word), 1 /* TEXT */)
                ]),
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("dt", _hoisted_14, _toDisplayString(_ctx.$t("word.labels.callParticipant")), 1 /* TEXT */),
                  _createElementVNode("dd", _hoisted_15, _toDisplayString(_ctx.$t(`word.participant.${$setup.word.callParticipant}`)), 1 /* TEXT */)
                ]),
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("dt", _hoisted_17, _toDisplayString(_ctx.$t("word.isActive.label")), 1 /* TEXT */),
                  _createElementVNode("dd", _hoisted_18, _toDisplayString(_ctx.$t(`word.isActive.options.${$setup.word.isActive}`)), 1 /* TEXT */)
                ])
              ])
            ]),
            _: 1 /* STABLE */
          }),
          _createElementVNode("div", _hoisted_19, [
            _createVNode($setup["UiButton"], {
              variant: "white",
              "text-variant": "gray-500",
              onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.goBack({ name: 'ListWord' })))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Back")), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            })
          ])
        ])
      ]))
    : _createCommentVNode("v-if", true)
}