import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "bg-white/40 w-full p-2 pb-0 flex items-center justify-center font-medium gap-x-2 ring-1 ring-black ring-opacity-5"
}
const _hoisted_2 = { class: "p-4 sm:p-6 lg:p-8 sm:pt-4 lg:pt-4" }
const _hoisted_3 = { class: "text-xl font-semibold text-gray-900 mb-5" }
const _hoisted_4 = { class: "flex flex-col lg:flex-row justify-between" }
const _hoisted_5 = { class: "md:flex flex-wrap items-center gap-2 hidden" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "w-full lg:w-64 md:mt-3 lg:mt-0" }
const _hoisted_9 = { class: "w-full mt-5" }
const _hoisted_10 = { class: "flex h-14 bg-gray-100 rounded ring-1 ring-black ring-opacity-5" }
const _hoisted_11 = { class: "flex flex-1 justify-between px-4" }
const _hoisted_12 = {
  class: "flex flex-1",
  action: "#",
  method: "GET"
}
const _hoisted_13 = { class: "ml-2 flex items-center space-x-4 sm:ml-6 sm:space-x-6" }
const _hoisted_14 = { class: "ml-2 flex items-center space-x-4 sm:ml-6 sm:space-x-6" }
const _hoisted_15 = {
  key: 0,
  class: "flex p-2 border-t border-gray-200 bg-gray-100 gap-3 rounded ring-1 ring-black ring-opacity-5"
}
const _hoisted_16 = {
  role: "list",
  class: ""
}
const _hoisted_17 = {
  key: 0,
  class: "flex flex-col flex-wrap items-center justify-center h-48 bg-gray-200 mt-0.5 rounded-b font-semibold text-gray-600"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.$can($setup.Permission.VIEW_CALL_REPORT))
      ? (_openBlock(), _createElementBlock("nav", _hoisted_1, [
          _createVNode(_component_router_link, {
            class: _normalizeClass(["pr-3 pl-2 py-1 hover:text-indigo-800 hover:border-b-2 hover:border-indigo-800 flex items-center", [
        $setup.route.name === 'CallList'
          ? 'text-indigo-800 border-b-2 border-indigo-800'
          : 'text-gray-500 border-b-2 border-transparent',
      ]]),
            to: { name: 'CallList' }
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("dashboard.calls")), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["class"]),
          _createVNode(_component_router_link, {
            class: _normalizeClass(["pr-3 pl-2 py-1 hover:text-indigo-800 hover:border-b-2 hover:border-indigo-800 flex items-center", [
        $setup.route.name === 'CallReport'
          ? 'text-indigo-800 border-b-2 border-indigo-800'
          : 'text-gray-500 border-b-2 border-transparent',
      ]]),
            to: { name: 'CallReport' }
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("report.call.title")), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["class"])
        ]))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_2, [
      _createVNode($setup["UiPageHeader"], null, {
        info: _withCtx(() => [
          _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t("dashboard.calls")), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.showAllCalls())),
            class: _normalizeClass([$setup.tabFilter === null ? 'bg-white/50' : '', "text-sm flex items-center cursor-pointer px-2 py-1 rounded-xl ring-1 ring-black ring-opacity-5 shadow-sm hover:bg-white/50"])
          }, [
            _createVNode($setup["PhoneIcon"], {
              class: "h-4 w-4 mr-1 text-gray-500",
              "aria-hidden": "true"
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.$t("call.filters.allTypesAndStatus")), 1 /* TEXT */)
          ], 2 /* CLASS */),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.CallType, (type) => {
            return (_openBlock(), _createElementBlock("div", {
              key: `filterBar${type}`,
              onClick: ($event: any) => ($setup.applyTabFilter(type)),
              class: _normalizeClass(["text-sm flex items-center cursor-pointer px-2 py-1 rounded-xl ring-1 ring-black ring-opacity-5 shadow-sm hover:bg-white/50", 
              $setup.tabFilter !== null && $setup.tabFilter?.value === type
                ? 'bg-white/50'
                : ''
            ])
            }, [
              (type === $setup.CallType.INBOUND)
                ? (_openBlock(), _createBlock($setup["PhoneIncomingIcon"], {
                    key: 0,
                    class: "h-4 w-4 mr-1 text-cyan-500",
                    "aria-hidden": "true"
                  }))
                : (type === $setup.CallType.OUTBOUND)
                  ? (_openBlock(), _createBlock($setup["PhoneOutgoingIcon"], {
                      key: 1,
                      class: "h-4 w-4 mr-1 text-orange-500",
                      "aria-hidden": "true"
                    }))
                  : (type === $setup.CallType.INTERNAL)
                    ? (_openBlock(), _createBlock($setup["PhoneIcon"], {
                        key: 2,
                        class: "h-4 w-4 mr-1 text-gray-500",
                        "aria-hidden": "true"
                      }))
                    : _createCommentVNode("v-if", true),
              _createTextVNode(" " + _toDisplayString(_ctx.$t(`call.type.${type}`)), 1 /* TEXT */)
            ], 10 /* CLASS, PROPS */, _hoisted_6))
          }), 128 /* KEYED_FRAGMENT */)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.values($setup.CallStatus), (status) => {
            return (_openBlock(), _createElementBlock(_Fragment, {
              key: `filterBar${status}`
            }, [
              (status !== $setup.CallStatus.ON_PROGRESS)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    onClick: ($event: any) => ($setup.applyTabFilter(status)),
                    class: _normalizeClass(["text-sm flex items-center cursor-pointer px-2 py-1 rounded-xl ring-1 ring-black ring-opacity-5 shadow-sm hover:bg-white/50", 
              $setup.tabFilter !== null && $setup.tabFilter?.value === status
                ? 'bg-white/50'
                : ''
            ])
                  }, [
                    _createElementVNode("div", {
                      class: _normalizeClass(["flex rounded-full h-2.5 w-2.5 mr-1", $setup.bulletCallStatusColor(status)])
                    }, null, 2 /* CLASS */),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t(`call.status.${status}`)), 1 /* TEXT */)
                  ], 10 /* CLASS, PROPS */, _hoisted_7))
                : _createCommentVNode("v-if", true)
            ], 64 /* STABLE_FRAGMENT */))
          }), 128 /* KEYED_FRAGMENT */))
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createVNode($setup["UiDatePicker"], {
            modelValue: $setup.dateRange,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.dateRange) = $event)),
            range: "",
            multiCalendars: ""
          }, null, 8 /* PROPS */, ["modelValue"])
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("form", _hoisted_12, [
              _createVNode($setup["UiListSearch"], {
                modelValue: $setup.search,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.search) = $event))
              }, null, 8 /* PROPS */, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createVNode($setup["UiButton"], {
                variant: "white",
                "text-variant": "primary",
                themed: "",
                onClick: _cache[3] || (_cache[3] = ($event: any) => ($setup.getCsv()))
              }, {
                default: _withCtx(() => [
                  _createVNode($setup["DocumentDownloadIcon"], {
                    class: "-ml-1 mr-2 h-5 w-5",
                    "aria-hidden": "true"
                  }),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t("core.actions.export")), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _createElementVNode("div", _hoisted_14, [
              _createVNode($setup["CallListSort"], {
                modelValue: $setup.sortMenuSelected,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.sortMenuSelected) = $event))
              }, null, 8 /* PROPS */, ["modelValue"]),
              _createVNode($setup["CallListFilters"])
            ])
          ])
        ]),
        ($setup.sortMenuSelected.length || $setup.activeFilters.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.sortMenuSelected, (selected) => {
                return (_openBlock(), _createBlock($setup["UiSortMenuBadge"], {
                  key: selected.key,
                  item: selected,
                  onRemove: ($event: any) => ($setup.handleRemoveSortClick(selected))
                }, null, 8 /* PROPS */, ["item", "onRemove"]))
              }), 128 /* KEYED_FRAGMENT */)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.activeFilters, (filter) => {
                return (_openBlock(), _createBlock($setup["UiBadge"], {
                  key: filter,
                  removable: "",
                  onRemove: ($event: any) => ($setup.handleRemoveFilter(filter.field))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t(`call.filters.activeTag.${filter.field}`)) + ": " + _toDisplayString(filter.label), 1 /* TEXT */)
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onRemove"]))
              }), 128 /* KEYED_FRAGMENT */))
            ]))
          : _createCommentVNode("v-if", true),
        _createElementVNode("ul", _hoisted_16, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.calls, (call) => {
            return (_openBlock(), _createBlock($setup["CallListItem"], {
              key: call.id,
              "model-value": call
            }, null, 8 /* PROPS */, ["model-value"]))
          }), 128 /* KEYED_FRAGMENT */)),
          (!$setup.calls.length)
            ? (_openBlock(), _createElementBlock("li", _hoisted_17, _toDisplayString(_ctx.$t("core.messages.noRecordsFound")), 1 /* TEXT */))
            : _createCommentVNode("v-if", true)
        ]),
        ($setup.calls.length)
          ? (_openBlock(), _createBlock($setup["UiPagination"], {
              key: 1,
              modelValue: $setup.currentPage,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.currentPage) = $event)),
              count: $setup.count,
              perPage: $setup.perPage,
              i18n: _ctx.$t,
              class: "h-14 px-4 bg-gray-100 rounded ring-1 ring-black ring-opacity-5"
            }, null, 8 /* PROPS */, ["modelValue", "count", "i18n"]))
          : _createCommentVNode("v-if", true)
      ])
    ])
  ], 64 /* STABLE_FRAGMENT */))
}