import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createVNode as _createVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4" }
const _hoisted_3 = {
  key: 0,
  class: "border border-gray-300 bg-gray-50 rounded-xl shadow hover:bg-white min-h-full flex flex-col justify-between"
}
const _hoisted_4 = { class: "" }
const _hoisted_5 = { class: "px-4 pt-3 font-semibold text-gray-700 flex items-center" }
const _hoisted_6 = { class: "-ml-0.5 p-1 bg-teal-100 rounded-lg mr-2 ring-1 ring-black ring-opacity-5" }
const _hoisted_7 = { class: "px-4 pt-2 text-sm text-gray-600" }
const _hoisted_8 = { class: "px-4 mt-3 py-2 border-t border-gray-300/50 text-sm rounded-b-xl flex items-center text-primary font-semibold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList($setup.items, (block, index) => {
        return _createVNode(_component_router_link, {
          key: `dashboardItem${index}`,
          to: { name: block.routeName }
        }, {
          default: _withCtx(() => [
            (block.permission && _ctx.$can(block.permission))
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("h3", _hoisted_5, [
                      _createElementVNode("span", _hoisted_6, [
                        (_openBlock(), _createBlock(_resolveDynamicComponent(block.icon), {
                          class: "h-5 w-5 text-secondary",
                          "aria-hidden": "true"
                        }))
                      ]),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t(block.title)), 1 /* TEXT */)
                    ]),
                    _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t(block.description)), 1 /* TEXT */)
                  ]),
                  _createElementVNode("div", _hoisted_8, [
                    _createVNode($setup["ArrowRightIcon"], {
                      class: "-ml-1 mr-1 h-4 w-4",
                      "aria-hidden": "true"
                    }),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t(block.callToAction)), 1 /* TEXT */)
                  ])
                ]))
              : _createCommentVNode("v-if", true)
          ]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"])
      }), 64 /* STABLE_FRAGMENT */))
    ])
  ]))
}