import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "block hover:bg-gray-50 p-3.5" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = { class: "flex flex-1 gap-2" }
const _hoisted_4 = { class: "flex items-center w-2/5 gap-1" }
const _hoisted_5 = ["datetime"]
const _hoisted_6 = { class: "w-1/5" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "flex" }
const _hoisted_9 = { class: "sr-only" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode($setup["CalendarIcon"], {
            class: "mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400",
            "aria-hidden": "true"
          }),
          _createElementVNode("time", {
            datetime: $setup.props.modelValue?.createdAt
          }, _toDisplayString($setup.formatDateAsString(
                $setup.props.modelValue.createdAt,
                "DD/MM/YYYY HH:mm:ss",
              )), 9 /* TEXT, PROPS */, _hoisted_5)
        ]),
        _createElementVNode("div", _hoisted_6, [
          ($setup.props.modelValue?.createdByUser)
            ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.$can($setup.Permission.VIEW_USER) ? 'RouterLink' : 'span'), {
                key: 0,
                to: {
              name: 'ViewUserById',
              params: {
                id: $setup.props.modelValue?.createdByUser?.id,
              },
            },
                class: "truncate font-medium text-primary"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($setup.props.modelValue.createdByUser.name), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["to"]))
            : _createCommentVNode("v-if", true)
        ]),
        ($props.published)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createVNode($setup["BookmarkIcon"], { class: "h-6 w-6 text-primary" })
            ]))
          : _createCommentVNode("v-if", true)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode($setup["UiButton"], {
          size: "",
          shape: "rounded-full",
          variant: "inherit",
          "text-variant": "primary",
          shadow: "none",
          themed: "",
          class: "border-transparent focus:ring-primary shadow-none ml-1 text-xs",
          to: {
            name: 'ModelingConnectFlowHistory',
            params: {
              id: $props.modelValue.connectFlowId,
              historyId: $props.modelValue.id,
            },
          }
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t("connectFlow.actions.modeling")), 1 /* TEXT */),
            _createVNode($setup["ConnectFlowIcon"], {
              class: "h-6 w-6",
              "aria-hidden": "true"
            })
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["to"])
      ])
    ])
  ]))
}