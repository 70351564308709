import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "p-4 sm:p-6 lg:p-8 sm:pt-4 lg:pt-4" }
const _hoisted_2 = { class: "text-xl font-semibold text-gray-900 mb-5" }
const _hoisted_3 = { class: "flex flex-col lg:flex-row justify-end" }
const _hoisted_4 = { class: "w-full lg:w-64 md:mt-3 lg:mt-0" }
const _hoisted_5 = { class: "w-full mt-5" }
const _hoisted_6 = { class: "flex h-14 bg-gray-100 rounded ring-1 ring-black ring-opacity-5" }
const _hoisted_7 = { class: "flex flex-1 justify-between px-4" }
const _hoisted_8 = {
  class: "flex flex-1",
  action: "#",
  method: "GET"
}
const _hoisted_9 = { class: "ml-2 flex items-center space-x-4 sm:ml-6 sm:space-x-6" }
const _hoisted_10 = {
  key: 0,
  class: "flex p-2 border-t border-gray-200 bg-gray-100 gap-3 rounded ring-1 ring-black ring-opacity-5"
}
const _hoisted_11 = {
  role: "list",
  class: ""
}
const _hoisted_12 = {
  key: 0,
  class: "flex flex-col flex-wrap items-center justify-center h-48 bg-gray-200 mt-0.5 rounded-b font-semibold text-gray-600"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["UiPageHeader"], null, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t("dashboard.meetings")), 1 /* TEXT */)
      ]),
      _: 1 /* STABLE */
    }),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode($setup["UiDatePicker"], {
          modelValue: $setup.dateRange,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.dateRange) = $event)),
          range: "",
          multiCalendars: ""
        }, null, 8 /* PROPS */, ["modelValue"])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("form", _hoisted_8, [
            _createVNode($setup["UiListSearch"], {
              modelValue: $setup.search,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.search) = $event))
            }, null, 8 /* PROPS */, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createVNode($setup["MeetingListSort"], {
              modelValue: $setup.sortMenuSelected,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.sortMenuSelected) = $event))
            }, null, 8 /* PROPS */, ["modelValue"]),
            _createVNode($setup["MeetingListFilters"])
          ])
        ])
      ]),
      (
          ($setup.sortMenuSelected && $setup.sortMenuSelected.length) ||
          ($setup.activeFilters && $setup.activeFilters.length)
        )
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.sortMenuSelected, (selected) => {
              return (_openBlock(), _createBlock($setup["UiSortMenuBadge"], {
                key: selected.key,
                item: selected,
                onRemove: ($event: any) => ($setup.handleRemoveSortClick(selected))
              }, null, 8 /* PROPS */, ["item", "onRemove"]))
            }), 128 /* KEYED_FRAGMENT */)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.activeFilters, (filter) => {
              return (_openBlock(), _createBlock($setup["UiBadge"], {
                key: filter,
                removable: "",
                onRemove: ($event: any) => ($setup.handleRemoveFilter(filter.field))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t(`meeting.filters.${filter.field}.label`)) + ": " + _toDisplayString(filter.label), 1 /* TEXT */)
                ]),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onRemove"]))
            }), 128 /* KEYED_FRAGMENT */))
          ]))
        : _createCommentVNode("v-if", true),
      _createElementVNode("ul", _hoisted_11, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.meetings, (meeting, index) => {
          return (_openBlock(), _createBlock($setup["MeetingListItem"], {
            key: index,
            "model-value": meeting
          }, null, 8 /* PROPS */, ["model-value"]))
        }), 128 /* KEYED_FRAGMENT */)),
        (!$setup.meetings.length)
          ? (_openBlock(), _createElementBlock("li", _hoisted_12, _toDisplayString(_ctx.$t("core.messages.noRecordsFound")), 1 /* TEXT */))
          : _createCommentVNode("v-if", true)
      ]),
      ($setup.meetings.length)
        ? (_openBlock(), _createBlock($setup["UiPagination"], {
            key: 1,
            modelValue: $setup.currentPage,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.currentPage) = $event)),
            count: $setup.count,
            perPage: $setup.perPage,
            i18n: _ctx.$t,
            class: "h-14 px-4 bg-gray-100 rounded ring-1 ring-black ring-opacity-5"
          }, null, 8 /* PROPS */, ["modelValue", "count", "i18n"]))
        : _createCommentVNode("v-if", true)
    ])
  ]))
}