import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["UiBadge"], {
    removable: "",
    onRemove: _cache[0] || (_cache[0] = ($event: any) => ($setup.handleRemoveClick($setup.props.item)))
  }, {
    default: _withCtx(() => [
      (_openBlock(), _createBlock(_resolveDynamicComponent(
        $setup.props.item.direction == $setup.ISortMenuItemDirection.ASC
          ? $setup.ArrowCircleDownIcon
          : $setup.ArrowCircleUpIcon
      ), {
        class: "h-4 w-4 cursor-pointer text-primary",
        "aria-hidden": "true"
      })),
      _createTextVNode(" " + _toDisplayString($setup.props.item.label), 1 /* TEXT */)
    ]),
    _: 1 /* STABLE */
  }))
}