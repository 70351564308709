import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "p-4 sm:p-6 lg:p-8"
}
const _hoisted_2 = { class: "text-xl font-semibold text-gray-900" }
const _hoisted_3 = { class: "flex items-center space-x-4" }
const _hoisted_4 = { class: "space-y-6" }
const _hoisted_5 = { class: "grid grid-cols-1 md:grid-cols-4 gap-6" }
const _hoisted_6 = { class: "font-semibold mb-1" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "font-semibold mb-1" }
const _hoisted_10 = { class: "font-semibold mb-1" }
const _hoisted_11 = { class: "font-semibold mb-1" }
const _hoisted_12 = { class: "font-semibold mb-1" }
const _hoisted_13 = { class: "font-semibold mb-1" }
const _hoisted_14 = { class: "text-base font-semibold leading-6 text-gray-900" }
const _hoisted_15 = { class: "mt-1 text-sm text-gray-500" }
const _hoisted_16 = { class: "ml-3" }
const _hoisted_17 = { class: "flex items-center text-sm text-gray-500" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($setup.number !== undefined)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode($setup["UiPageHeader"], null, {
          info: _withCtx(() => [
            _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t("number.view.section.number.title")), 1 /* TEXT */)
          ]),
          actions: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createVNode($setup["UiButton"], {
                variant: "white",
                "text-variant": "gray-500",
                onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.goBack({ name: 'ListNumber' })))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Back")), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }),
              (_ctx.$can($setup.Permission.EDIT_USER))
                ? (_openBlock(), _createBlock($setup["RouterLink"], {
                    key: 0,
                    to: {
              name: 'EditNumberById',
              params: {
                id: $setup.number?.id,
              },
            },
                    class: "block hover:bg-gray-50"
                  }, {
                    default: _withCtx(() => [
                      _createVNode($setup["UiButton"], {
                        variant: "primary",
                        "text-variant": "white",
                        themed: ""
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Edit")), 1 /* TEXT */)
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["to"]))
                : _createCommentVNode("v-if", true)
            ])
          ]),
          _: 1 /* STABLE */
        }),
        _createElementVNode("div", _hoisted_4, [
          _createVNode($setup["UiPanel"], null, {
            content: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", null, [
                  _createElementVNode("h3", _hoisted_6, _toDisplayString(_ctx.$t("number.labels.number")), 1 /* TEXT */),
                  ($setup.number.number)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString($setup.applyPhoneMask($setup.number.number)), 1 /* TEXT */))
                    : (_openBlock(), _createElementBlock("p", _hoisted_8, "-"))
                ]),
                _createElementVNode("div", null, [
                  _createElementVNode("h3", _hoisted_9, _toDisplayString(_ctx.$t("number.labels.city")), 1 /* TEXT */),
                  _createElementVNode("p", null, _toDisplayString($setup.number?.city) + "/" + _toDisplayString($setup.number?.state), 1 /* TEXT */)
                ]),
                _createElementVNode("div", null, [
                  _createElementVNode("h3", _hoisted_10, _toDisplayString(_ctx.$t("number.labels.type")), 1 /* TEXT */),
                  _createElementVNode("p", null, _toDisplayString(_ctx.$t(`number.types.${$setup.number?.type}`)), 1 /* TEXT */)
                ]),
                _createElementVNode("div", null, [
                  _createElementVNode("h3", _hoisted_11, _toDisplayString(_ctx.$t("number.labels.level")), 1 /* TEXT */),
                  _createElementVNode("p", null, _toDisplayString($setup.number?.level.name), 1 /* TEXT */)
                ]),
                _createElementVNode("div", null, [
                  _createElementVNode("h3", _hoisted_12, _toDisplayString(_ctx.$t("core.entity.ConnectFlow")), 1 /* TEXT */),
                  _createElementVNode("p", null, _toDisplayString($setup.number?.connectFlow?.name), 1 /* TEXT */)
                ]),
                _createElementVNode("div", null, [
                  _createElementVNode("h3", _hoisted_13, _toDisplayString(_ctx.$t("number.labels.mediaLabel")), 1 /* TEXT */),
                  _createElementVNode("p", null, _toDisplayString($setup.number.mediaLabel), 1 /* TEXT */)
                ])
              ])
            ]),
            _: 1 /* STABLE */
          }),
          false
            ? (_openBlock(), _createBlock($setup["UiContainerSection"], { key: 0 }, {
                title: _withCtx(() => [
                  _createElementVNode("h3", _hoisted_14, _toDisplayString(_ctx.$t("number.view.section.agent.title")), 1 /* TEXT */),
                  _createElementVNode("p", _hoisted_15, _toDisplayString(_ctx.$t("number.view.section.agent.description")), 1 /* TEXT */)
                ]),
                content: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.number?.agents, (agent) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: agent.id,
                      class: "flex items-center p-2"
                    }, [
                      _createElementVNode("div", null, [
                        _createVNode($setup["UiGravatar"], {
                          name: agent.name,
                          class: "inline-block h-10 w-10 rounded-full",
                          email: agent.email
                        }, null, 8 /* PROPS */, ["name", "email"])
                      ]),
                      _createElementVNode("div", _hoisted_16, [
                        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.$can($setup.Permission.VIEW_USER) ? $setup.RouterLink : 'p'), {
                          to: {
                  name: 'ViewUserById',
                  params: {
                    id: agent?.id,
                  },
                },
                          class: "truncate text-sm font-medium text-primary"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(agent?.name), 1 /* TEXT */)
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"])),
                        _createElementVNode("p", _hoisted_17, [
                          _createVNode($setup["MailIcon"], {
                            class: "mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400",
                            "aria-hidden": "true"
                          }),
                          _createTextVNode(" " + _toDisplayString(agent?.email), 1 /* TEXT */)
                        ])
                      ])
                    ]))
                  }), 128 /* KEYED_FRAGMENT */))
                ]),
                _: 1 /* STABLE */
              }))
            : _createCommentVNode("v-if", true)
        ])
      ]))
    : _createCommentVNode("v-if", true)
}