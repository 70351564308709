/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree, GetterTree, MutationTree } from "vuex";
import router from "@/router";
import {
  AuthActions,
  AuthGetters,
  AuthMutations,
  FilterActions,
  IAuthServiceLoginResult,
  IAuthState,
  ICompany,
  INTEGRATION,
  IUser,
  Permission,
} from "@/definitions";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import analytics from "@/utils/analytics";

const state: IAuthState = {
  user: undefined,
  company: undefined,
};

const mutations: MutationTree<IAuthState> = {
  [AuthMutations.SET_USER](state, user: IUser) {
    state.user = user;
  },
  [AuthMutations.SET_COMPANY](state, company: ICompany) {
    state.company = company;
  },
};

const actions: ActionTree<IAuthState, any> = {
  [AuthActions.LOGIN]({ commit }, loginResult: IAuthServiceLoginResult) {
    const { user, level, company } = loginResult;

    commit(AuthMutations.SET_USER, user);
    commit(AuthMutations.SET_COMPANY, company);
    commit(FilterActions.SET_LEVEL, level);
    localStorage.setItem("level", btoa(JSON.stringify(level)));

    if (loginResult.jwt) {
      localStorage.setItem("token", loginResult.jwt);
    }

    if (process.env.NODE_ENV === "production") {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      analytics?.identify(user.id.toString(), user);
    }
  },
  [AuthActions.LOGOUT]({ commit }) {
    commit(AuthMutations.SET_USER, null);
    commit(AuthMutations.SET_COMPANY, null);
    localStorage.removeItem("token");
    localStorage.removeItem("level");
    localStorage.removeItem("locale");
    router.push("/login").then();
  },
};

const getters: GetterTree<IAuthState, any> = {
  [AuthGetters.USER]: (state) => state.user,
  [AuthGetters.USER_COMPANY]: (state) => state.company,
  [AuthGetters.USER_LEVELS]: (state) => state.user?.levels,
  [AuthGetters.USER_ROLES]: (state) => state.user?.roles,
  [AuthGetters.USER_PERMISSIONS]: (state) => {
    const permissions: Permission[] = [];

    const roles = state.user?.roles || [];
    for (let i = 0; i < roles.length; i++) {
      const rolePermissions = roles[i].permissions || [];
      for (let ii = 0; ii < rolePermissions.length; ii++) {
        const rolePermission = rolePermissions[ii].id as Permission;
        if (
          Object.values(Permission).includes(rolePermission) &&
          !permissions.includes(rolePermission)
        )
          permissions.push(rolePermission);
      }
    }

    return permissions;
  },
  [AuthGetters.CHAT_BOT_INTEGRATION]: (state) => {
    if (state.user !== undefined && state.user?.integrations !== undefined)
      return state.user?.integrations.find((integration) => {
        return (
          integration.integration === INTEGRATION.CHAT_BOT &&
          integration.levelId === state.user?.currentLevel?.id
        );
      });
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
