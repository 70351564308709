import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid grid-cols-1 sm:grid-cols-2 w-full" }
const _hoisted_2 = { class: "bg-white flex flex-col items-center sm:items-start sm:justify-center pt-20 sm:pt-0 w-full px-8 lg:px-28 xl:px-40" }
const _hoisted_3 = { class: "mb-8 text-2xl font-semibold text-gray-700" }
const _hoisted_4 = { class: "text-sm mb-4" }
const _hoisted_5 = { class: "list-decimal pl-6 text-sm mb-10" }
const _hoisted_6 = { class: "block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", { class: "bg-primary hidden sm:flex items-center justify-center w-full relative" }, [
      _createElementVNode("img", {
        src: $setup.imageLoginPath,
        class: "h-[150px] xl:h-[250px]",
        alt: "logo Mobcall"
      })
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        class: "mb-8 w-[225px]",
        src: $setup.logoPath,
        alt: "logo Mobcall"
      }),
      _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.$t("authentication.updatePassword.title")), 1 /* TEXT */),
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t("authentication.updatePassword.description")), 1 /* TEXT */),
      _createElementVNode("ul", _hoisted_5, [
        _createElementVNode("li", null, _toDisplayString(_ctx.$t("authentication.updatePassword.rule.size")), 1 /* TEXT */),
        _createElementVNode("li", null, _toDisplayString(_ctx.$t("authentication.updatePassword.rule.upperCase")), 1 /* TEXT */),
        _createElementVNode("li", null, _toDisplayString(_ctx.$t("authentication.updatePassword.rule.lowerCase")), 1 /* TEXT */),
        _createElementVNode("li", null, _toDisplayString(_ctx.$t("authentication.updatePassword.rule.number")), 1 /* TEXT */),
        _createElementVNode("li", null, _toDisplayString(_ctx.$t("authentication.updatePassword.rule.special")), 1 /* TEXT */)
      ]),
      _createElementVNode("form", {
        class: "space-y-6 w-full",
        onSubmit: _withModifiers($setup.onSubmit, ["prevent"])
      }, [
        _createVNode($setup["UiTextInput"], {
          modelValue: $setup.formState.password,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.formState.password) = $event)),
          name: "password",
          type: "password",
          label: _ctx.$t('authentication.updatePassword.label.password'),
          class: "",
          inputClass: "text-base px-4 py-3",
          autocomplete: "password",
          errors: $setup.v$.password?.$errors,
          onBlur: $setup.v$.password?.$touch
        }, null, 8 /* PROPS */, ["modelValue", "label", "errors", "onBlur"]),
        _createVNode($setup["UiTextInput"], {
          modelValue: $setup.formState.confirmPassword,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.formState.confirmPassword) = $event)),
          name: "confirmPassword",
          type: "password",
          label: _ctx.$t('authentication.updatePassword.label.confirmPassword'),
          class: "",
          inputClass: "text-base px-4 py-3",
          autocomplete: "off",
          errors: $setup.v$.confirmPassword?.$errors,
          onBlur: $setup.v$.confirmPassword?.$touch
        }, null, 8 /* PROPS */, ["modelValue", "label", "errors", "onBlur"]),
        _createElementVNode("div", null, [
          _createVNode($setup["UiButton"], {
            type: "submit",
            themed: "",
            variant: "primary",
            class: "w-full text-lg shadow",
            size: "xl"
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t("login.form.signIn")), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          })
        ])
      ], 32 /* NEED_HYDRATION */)
    ])
  ]))
}