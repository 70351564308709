import { normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "py-1" }
const _hoisted_2 = { class: "flex-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_tippy = _resolveDirective("tippy")!

  return (_openBlock(), _createBlock($setup["Menu"], {
    as: "div",
    class: "relative"
  }, {
    default: _withCtx(() => [
      _createVNode($setup["MenuButton"], {
        class: _normalizeClass(["inline-flex w-full justify-center rounded-md border border-gray-300 bg-white py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary", [$setup.props.fixmobile ? 'px-3 sm:px-4' : 'px-4']])
      }, {
        default: _withCtx(() => [
          _createVNode($setup["SwitchVerticalIcon"], {
            class: _normalizeClass(["mr-3 h-5 w-5 text-gray-400", [$setup.props.fixmobile ? 'mr-0 sm:mr-3' : '']]),
            "aria-hidden": "true"
          }, null, 8 /* PROPS */, ["class"]),
          _createElementVNode("span", {
            class: _normalizeClass([$setup.props.fixmobile ? 'hidden sm:block' : ''])
          }, _toDisplayString($setup.props.label), 3 /* TEXT, CLASS */),
          _createVNode($setup["ChevronDownIcon"], {
            class: _normalizeClass(["ml-2.5 -mr-1.5 h-5 w-5 text-gray-400", [$setup.props.fixmobile ? 'hidden sm:block' : '']]),
            "aria-hidden": "true"
          }, null, 8 /* PROPS */, ["class"])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["class"]),
      _createVNode($setup["MenuItems"], { class: "absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.items, (item, index) => {
              return (_openBlock(), _createBlock($setup["MenuItem"], { key: index }, {
                default: _withCtx(({ active }) => [
                  _createElementVNode("div", {
                    class: _normalizeClass([
              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
              'group flex items-center px-4 py-2 text-sm',
            ]),
                    onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"]))
                  }, [
                    _createElementVNode("span", _hoisted_2, _toDisplayString(item.label), 1 /* TEXT */),
                    _withDirectives(_createVNode($setup["ArrowCircleDownIcon"], {
                      class: _normalizeClass([[
                $setup.props.modelValue.find(
                  (m) =>
                    m.key == item.key &&
                    m.direction == $setup.ISortMenuItemDirection.ASC,
                )
                  ? 'text-primary hover:text-gray-300'
                  : 'text-gray-400 hover:text-primary',
              ], "h-6 w-6 cursor-pointer"]),
                      "aria-hidden": "true",
                      onClick: ($event: any) => ($setup.handleItemClick(item, $setup.ISortMenuItemDirection.ASC))
                    }, null, 8 /* PROPS */, ["class", "onClick"]), [
                      [_directive_tippy, _ctx.$t('sort.ascending')]
                    ]),
                    _withDirectives(_createVNode($setup["ArrowCircleUpIcon"], {
                      class: _normalizeClass([[
                $setup.props.modelValue.find(
                  (m) =>
                    m.key == item.key &&
                    m.direction == $setup.ISortMenuItemDirection.DESC,
                )
                  ? 'text-primary hover:text-gray-300'
                  : 'text-gray-400 hover:text-primary',
              ], "h-6 w-6 cursor-pointer"]),
                      "aria-hidden": "true",
                      onClick: ($event: any) => ($setup.handleItemClick(item, $setup.ISortMenuItemDirection.DESC))
                    }, null, 8 /* PROPS */, ["class", "onClick"]), [
                      [_directive_tippy, _ctx.$t('sort.descending')]
                    ])
                  ], 2 /* CLASS */)
                ]),
                _: 2 /* DYNAMIC */
              }, 1024 /* DYNAMIC_SLOTS */))
            }), 128 /* KEYED_FRAGMENT */))
          ])
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}