import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "mt-5" }
const _hoisted_2 = { class: "grid grid-cols-1 gap-5 sm:grid-cols-2" }
const _hoisted_3 = { class: "text-lg leading-6 font-medium mb-3 text-purple-500" }
const _hoisted_4 = { class: "text-lg leading-6 font-medium mb-3 text-blue-500" }
const _hoisted_5 = { class: "flex flex-row gap-5" }
const _hoisted_6 = { class: "px-4 py-5 bg-white shadow rounded-lg sm:p-3 border-l-4 basis-1/2 border-purple-500" }
const _hoisted_7 = { class: "mb-3 text-gray-700 flex" }
const _hoisted_8 = { class: "mb-3 text-gray-700 flex" }
const _hoisted_9 = { class: "px-4 py-5 bg-white shadow rounded-lg sm:p-3 border-l-4 basis-1/2 border-blue-500" }
const _hoisted_10 = { class: "text-gray-700 mb-3 flex" }
const _hoisted_11 = { class: "text-gray-700 mb-3 flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.$t("call.view.participant.agent")), 1 /* TEXT */),
      _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.$t("call.view.participant.client")), 1 /* TEXT */)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        ($setup.words !== undefined)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createElementVNode("h3", _hoisted_7, [
                _createVNode($setup["SpeakerphoneIcon"], { class: "h-6 w-6 mr-1" }),
                _createTextVNode(" " + _toDisplayString(_ctx.$t("call.intelligence.spokenWords")), 1 /* TEXT */)
              ]),
              _createVNode($setup["WordsBlock"], {
                words: $setup.agentWords,
                persona: $setup.ParticipantType.Agent
              }, null, 8 /* PROPS */, ["words", "persona"])
            ], 64 /* STABLE_FRAGMENT */))
          : _createCommentVNode("v-if", true),
        ($props.insight)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createElementVNode("h3", _hoisted_8, [
                _createVNode($setup["AnnotationIcon"], { class: "h-6 w-6 mr-1" }),
                _createTextVNode(" " + _toDisplayString(_ctx.$t("call.intelligence.foundInsights")), 1 /* TEXT */)
              ]),
              _createVNode($setup["InsightBlock"], {
                insights: $setup.agentInsight,
                persona: $setup.ParticipantType.Agent
              }, null, 8 /* PROPS */, ["insights", "persona"])
            ], 64 /* STABLE_FRAGMENT */))
          : _createCommentVNode("v-if", true)
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("h3", _hoisted_10, [
          _createVNode($setup["SpeakerphoneIcon"], { class: "h-6 w-6 mr-1" }),
          _createTextVNode(" " + _toDisplayString(_ctx.$t("call.intelligence.spokenWords")), 1 /* TEXT */)
        ]),
        _createVNode($setup["WordsBlock"], {
          words: $setup.clientWords,
          persona: $setup.ParticipantType.Client
        }, null, 8 /* PROPS */, ["words", "persona"]),
        ($props.insight)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createElementVNode("h3", _hoisted_11, [
                _createVNode($setup["AnnotationIcon"], { class: "h-6 w-6 mr-1" }),
                _createTextVNode(" " + _toDisplayString(_ctx.$t("call.intelligence.foundInsights")), 1 /* TEXT */)
              ]),
              _createVNode($setup["InsightBlock"], {
                insights: $setup.clientInsight,
                persona: $setup.ParticipantType.Client
              }, null, 8 /* PROPS */, ["insights", "persona"])
            ], 64 /* STABLE_FRAGMENT */))
          : _createCommentVNode("v-if", true)
      ])
    ])
  ]))
}