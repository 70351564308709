import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "text-xl font-semibold text-gray-900" }
const _hoisted_3 = { class: "mt-2 text-sm text-gray-700" }
const _hoisted_4 = { class: "text-base font-semibold leading-6 text-gray-900" }
const _hoisted_5 = { class: "mt-1 text-sm text-gray-500" }
const _hoisted_6 = { class: "grid grid-cols-6 gap-6" }
const _hoisted_7 = {
  key: 0,
  class: "col-span-6 sm:col-span-4"
}
const _hoisted_8 = {
  for: "users",
  class: "block text-sm font-medium leading-6 text-gray-900"
}
const _hoisted_9 = { class: "flex justify-end px-4 sm:px-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["UiPageHeader"], null, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t("role." + $setup.props.action + ".title")), 1 /* TEXT */),
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("role." + $setup.props.action + ".description")), 1 /* TEXT */)
      ]),
      _: 1 /* STABLE */
    }),
    _createElementVNode("form", {
      class: "space-y-6",
      autocomplete: "off",
      onSubmit: _withModifiers($setup.onSubmit, ["prevent"])
    }, [
      _createVNode($setup["UiContainerSection"], null, {
        title: _withCtx(() => [
          _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.$t("role." + $setup.props.action + ".section.title")), 1 /* TEXT */),
          _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t("role." + $setup.props.action + ".section.description")), 1 /* TEXT */)
        ]),
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_6, [
            _createVNode($setup["UiTextInput"], {
              modelValue: $setup.v$.name.$model,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.v$.name.$model) = $event)),
              name: "name",
              type: "text",
              label: _ctx.$t('role.labels.name'),
              class: "col-span-6 sm:col-span-3",
              errors: $setup.v$.name.$errors,
              onBlur: $setup.v$.name.$touch
            }, null, 8 /* PROPS */, ["modelValue", "label", "errors", "onBlur"]),
            (_ctx.$can($setup.Permission.ASSIGN_ROLE_TO_USER))
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t("role.labels.users")), 1 /* TEXT */),
                  _createVNode($setup["UiMultiselect"], {
                    name: "users",
                    modelValue: $setup.formState.users,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.formState.users) = $event)),
                    options: $setup.users,
                    "can-clear": true,
                    multiple: "",
                    primitive: "",
                    label: "name",
                    "value-prop": "id"
                  }, null, 8 /* PROPS */, ["modelValue", "options"])
                ]))
              : _createCommentVNode("v-if", true)
          ])
        ]),
        _: 1 /* STABLE */
      }),
      _createElementVNode("div", _hoisted_9, [
        _createVNode($setup["UiButton"], {
          variant: "white",
          "text-variant": "gray-500",
          onClick: $setup.handleBackClick
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Cancel")), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        (
            ($setup.props.action == $setup.FormAction.CREATE &&
              _ctx.$can($setup.Permission.CREATE_ROLE)) ||
            ($setup.props.action == $setup.FormAction.EDIT && _ctx.$can($setup.Permission.EDIT_ROLE))
          )
          ? (_openBlock(), _createBlock($setup["UiButton"], {
              key: 0,
              type: "submit",
              variant: "primary",
              "text-variant": "white",
              themed: "",
              class: "ml-4"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Save")), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true)
      ])
    ], 32 /* NEED_HYDRATION */),
    _createVNode($setup["UiCheckDirtyBeforeRouteLeave"], {
      dirty: $setup.v$.$anyDirty
    }, null, 8 /* PROPS */, ["dirty"])
  ]))
}