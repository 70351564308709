import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "text-xl font-semibold text-gray-900" }
const _hoisted_3 = { class: "mt-4" }
const _hoisted_4 = { class: "mt-8 grid grid-cols-12 gap-4" }
const _hoisted_5 = { class: "bg-white rounded-lg shadow w-full h-[200px] col-span-12 md:h-[277px] md:col-span-4 md:p-6" }
const _hoisted_6 = { class: "col-span-12 md:col-span-8" }
const _hoisted_7 = { class: "mb-2 text-lg font-semibold" }
const _hoisted_8 = { class: "mt-0 px-0 py-0 overflow-y-auto h-60 bg-white rounded-lg shadow" }
const _hoisted_9 = { class: "mt-8 grid grid-cols-12 gap-4" }
const _hoisted_10 = { class: "bg-white rounded-lg shadow w-full h-[200px] col-span-12 md:h-[277px] md:col-span-4" }
const _hoisted_11 = { class: "col-span-12 md:col-span-8" }
const _hoisted_12 = { class: "mb-2 text-lg font-semibold" }
const _hoisted_13 = { class: "mt-0 px-0 py-0 overflow-y-auto h-60 bg-white rounded-lg shadow" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["UiPageHeader"], null, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t("report.realtime.title")), 1 /* TEXT */)
      ]),
      _: 1 /* STABLE */
    }),
    _createElementVNode("div", _hoisted_3, [
      _createVNode($setup["DataTotalize"], {
        data: $setup.dataTotalize,
        loading: $setup.loading
      }, null, 8 /* PROPS */, ["data", "loading"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode($setup["CallQueueGaugeChart"], {
          data: $setup.dataTotalize.callQueue,
          loading: $setup.loading
        }, null, 8 /* PROPS */, ["data", "loading"])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("h1", _hoisted_7, _toDisplayString(_ctx.$t("report.realtime.detailsQueue.title")), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_8, [
          _createVNode($setup["QueueDetailsTable"])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _createVNode($setup["UsersStatusDoughnutChart"], {
          data: $setup.dataTotalize,
          loading: $setup.loading
        }, null, 8 /* PROPS */, ["data", "loading"])
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("h1", _hoisted_12, _toDisplayString(_ctx.$t("report.realtime.detailsUsers.title")), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_13, [
          _createVNode($setup["UsersExtensionsDetailsTable"])
        ])
      ])
    ])
  ]))
}