import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["BarChart"], {
    title: $setup.props.title,
    categoryData: $setup.chartData.category,
    seriesData: $setup.chartData.series,
    xAxisLabelFormatter: $setup.xAxisLabelFormatter,
    column: ""
  }, null, 8 /* PROPS */, ["title", "categoryData", "seriesData"]))
}