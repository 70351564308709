import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  autocomplete: "off",
  class: "flex flex-col gap-3"
}
const _hoisted_2 = { class: "grid grid-cols-1 gap-3 w-full h-full" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "bg-gray-50 py-5 h-full flex flex-col justify-center px-2 rounded-l-lg" }
const _hoisted_5 = { class: "flex flex-col items-start p-3" }
const _hoisted_6 = { class: "text-gray-700 font-semibold" }
const _hoisted_7 = { class: "text-gray-500 text-xs text-left" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["UiRightPanel"], {
    modelValue: $setup.showForm,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.showForm) = $event)),
    title: _ctx.$t('connectFlow.componentSelector')
  }, {
    default: _withCtx(() => [
      ($setup.rules !== undefined)
        ? (_openBlock(), _createElementBlock("form", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.NodeType, (type) => {
                return (_openBlock(), _createElementBlock(_Fragment, {
                  key: `nodeButton${type}`
                }, [
                  ($setup.rules.typesChildren.includes(type))
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        type: "button",
                        onClick: ($event: any) => ($setup.setUpNode(type)),
                        class: "bg-gray-100 hover:bg-white cursor-pointer rounded-lg shadow ring-1 ring-black ring-opacity-5 flex items-center"
                      }, [
                        _createElementVNode("div", _hoisted_4, [
                          (_openBlock(), _createBlock(_resolveDynamicComponent($setup.getNodeComponent(type)), { class: "h-8 w-8 text-secondary" }))
                        ]),
                        _createElementVNode("div", _hoisted_5, [
                          _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t(`connectFlow.node.${type}.title`)), 1 /* TEXT */),
                          _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t(`connectFlow.node.${type}.helpText`)), 1 /* TEXT */)
                        ])
                      ], 8 /* PROPS */, _hoisted_3))
                    : _createCommentVNode("v-if", true)
                ], 64 /* STABLE_FRAGMENT */))
              }), 128 /* KEYED_FRAGMENT */))
            ])
          ]))
        : _createCommentVNode("v-if", true)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "title"]))
}