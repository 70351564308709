import { normalizeStyle as _normalizeStyle, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode($setup["StepEdge"], {
      id: $props.id,
      style: _normalizeStyle($props.style),
      path: $setup.path[0],
      "marker-end": $props.markerEnd
    }, null, 8 /* PROPS */, ["id", "style", "path", "marker-end"]),
    ($setup.edge.label)
      ? (_openBlock(), _createBlock($setup["EdgeText"], {
          key: 0,
          x: $setup.props.targetX - 0,
          y: $setup.props.targetY - 30,
          "label-show-bg": true,
          "label-bg-padding": [8, 4],
          "label-bg-border-radius": 5
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($setup.edge.label), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["x", "y"]))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}