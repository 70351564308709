import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["UiSortMenu"], {
    label: _ctx.$t('core.actions.Sort'),
    items: $setup.sortMenuItems,
    "model-value": $setup.props.modelValue,
    i18n: _ctx.$t,
    fixmobile: "",
    "onUpdate:modelValue": $setup.updateValue
  }, null, 8 /* PROPS */, ["label", "items", "model-value", "i18n"]))
}