import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "p-4 sm:p-12 sm:pt-0 mb-10" }
const _hoisted_2 = { class: "hidden md:flex flex-col-reverse md:flex-row items-center justify-between xl:space-x-8 mb-6" }
const _hoisted_3 = { class: "flex flex-col h-[230px] md:h-[330px] xl:h-[350px] relative" }
const _hoisted_4 = {
  key: 0,
  class: "text-2xl md:my-8 xl:text-4xl font-bold text-gray-800 my-4 xl:mt-16 xl:mb-8"
}
const _hoisted_5 = { class: "font-medium text-gray-500 xl:text-md md:w-5/6 block w-full" }
const _hoisted_6 = { class: "text-primary" }
const _hoisted_7 = { class: "text-xl xl:text-2xl font-semibold text-gray-700 absolute bottom-1 flex items-center" }
const _hoisted_8 = ["alt"]
const _hoisted_9 = { class: "grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4" }
const _hoisted_10 = {
  key: 0,
  class: "border border-gray-300 bg-gray-100 rounded-xl shadow hover:bg-white min-h-full flex flex-col justify-between"
}
const _hoisted_11 = { class: "flex items-center justify-between relative" }
const _hoisted_12 = { class: "px-4 pt-3 font-semibold text-gray-700 flex items-center leading-5 w-2/3" }
const _hoisted_13 = { class: "p-1 bg-teal-100 rounded-tr-xl absolute right-0 top-0" }
const _hoisted_14 = { class: "px-4 pt-2 text-sm text-gray-600" }
const _hoisted_15 = { class: "px-4 mt-3 py-2 border-t border-gray-300/50 text-sm rounded-b-xl flex items-center text-primary font-semibold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode($setup["UiPageHeader"], null, {
          info: _withCtx(() => [
            ($setup.user !== null)
              ? (_openBlock(), _createElementBlock("h1", _hoisted_4, _toDisplayString(_ctx.$t("welcomeLogged")) + ", " + _toDisplayString($setup.user.name) + ". ", 1 /* TEXT */))
              : _createCommentVNode("v-if", true)
          ]),
          _: 1 /* STABLE */
        }),
        _createElementVNode("p", _hoisted_5, [
          _createTextVNode(_toDisplayString(_ctx.$t("dashboard.welcome")) + " ", 1 /* TEXT */),
          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t("dashboard.welcomeAct")), 1 /* TEXT */)
        ]),
        _createElementVNode("h2", _hoisted_7, [
          _createVNode($setup["ReplyIcon"], {
            class: "h-8 w-8 text-secondary rotate-180 mr-1",
            "aria-hidden": "true"
          }),
          _createTextVNode(" " + _toDisplayString(_ctx.$t("dashboard.gettingStarted")), 1 /* TEXT */)
        ])
      ]),
      _createElementVNode("img", {
        src: "/img/image-home-verde.png",
        class: "h-[200px] md:h-[200px] xl:h-[350px]",
        alt: _ctx.$t('welcomeLogged')
      }, null, 8 /* PROPS */, _hoisted_8)
    ]),
    _createElementVNode("div", _hoisted_9, [
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList($setup.items, (block, index) => {
        return _createVNode(_component_router_link, {
          key: `dashboardItem${index}`,
          to: { name: block.routeName }
        }, {
          default: _withCtx(() => [
            (block.permission && _ctx.$can(block.permission))
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("h3", _hoisted_12, _toDisplayString(_ctx.$t(block.title)), 1 /* TEXT */),
                    _createElementVNode("div", _hoisted_13, [
                      (_openBlock(), _createBlock(_resolveDynamicComponent(block.icon), {
                        class: "h-8 w-8 text-secondary",
                        "aria-hidden": "true"
                      }))
                    ])
                  ]),
                  _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.$t(block.description)), 1 /* TEXT */),
                  _createElementVNode("div", _hoisted_15, [
                    _createVNode($setup["ArrowRightIcon"], {
                      class: "-ml-1 mr-1 h-4 w-4",
                      "aria-hidden": "true"
                    }),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t(block.callToAction)), 1 /* TEXT */)
                  ])
                ]))
              : _createCommentVNode("v-if", true)
          ]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"])
      }), 64 /* STABLE_FRAGMENT */))
    ])
  ]))
}