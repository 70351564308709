import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex h-screen w-full" }
const _hoisted_2 = { class: "sm:fixed flex flex-col justify-between overflow-y-auto sm:w-60 h-full bg-primary" }
const _hoisted_3 = { class: "h-full pb-16" }
const _hoisted_4 = { class: "flex flex-shrink-0 items-center px-4 my-4" }
const _hoisted_5 = { class: "text-xl font-bold leading-7 text-white sm:truncate" }
const _hoisted_6 = { class: "w-full sm:pl-60" }
const _hoisted_7 = { class: "flex flex-col justify-between" }
const _hoisted_8 = { class: "w-full flex items-center justify-between" }
const _hoisted_9 = { class: "grid grid-cols-2 md:grid-cols-5 gap-4 w-full" }
const _hoisted_10 = { class: "flex items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["TemplateSidebar"], null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_router_link, { to: { name: 'Configurations' } }, {
                default: _withCtx(() => [
                  _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.$t("sideNavigation.config")), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _createVNode($setup["TemplateNavigation"], { items: $setup.configSideNavigationItems }, null, 8 /* PROPS */, ["items"])
          ]),
          _createVNode($setup["TemplateProfile"])
        ])
      ]),
      _: 1 /* STABLE */
    }),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", {
          class: _normalizeClass([$setup.headerClass, "p-2 sm:px-4 sm:py-2 shadow flex items-center sticky top-0 z-30"])
        }, [
          _createElementVNode("header", _hoisted_8, [
            _createVNode($setup["TemplateHeader"], null, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_9, [
                  _createVNode($setup["UiMultiselect"], {
                    name: "template_level",
                    label: "name",
                    "value-prop": "id",
                    modelValue: $setup.level,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.level) = $event)),
                    options: $setup.levels,
                    class: "col-span-2",
                    disabled: $setup.disabledIfRouterWithId
                  }, null, 8 /* PROPS */, ["modelValue", "options", "disabled"])
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createVNode($setup["UiButton"], {
                    size: "sm",
                    variant: "inherit",
                    "text-variant": "gray-400",
                    shadow: "none",
                    class: "border-transparent focus:ring-primary flex items-center ml-auto whitespace-nowrap",
                    onClick: $setup.handleBackToAppClick
                  }, {
                    default: _withCtx(() => [
                      _createVNode($setup["HomeIcon"], {
                        class: "h-5 w-5 mr-1",
                        "aria-hidden": "true"
                      }),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t("sideNavigation.BackToDashboard")), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  })
                ])
              ]),
              _: 1 /* STABLE */
            })
          ])
        ], 2 /* CLASS */),
        _createElementVNode("main", null, [
          _createVNode(_component_router_view)
        ])
      ]),
      _createVNode($setup["UiLoadingDialog"], {
        show: $setup.loading,
        text: _ctx.$t('ui-loading-dialog-text')
      }, null, 8 /* PROPS */, ["show", "text"])
    ])
  ]))
}