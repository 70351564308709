import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["VueDatePicker"], {
    "model-value": $setup.props.modelValue,
    "model-type": $setup.props.modelType,
    range: $setup.props.range,
    partialRange: $setup.props.partialRange,
    multiCalendars: $setup.props.multiCalendars,
    autoApply: $setup.props.autoApply,
    enableTimePicker: $setup.props.enableTimePicker,
    clearable: $setup.props.clearable,
    utc: $setup.props.utc,
    disabled: $setup.props.disabled,
    readonly: $setup.props.readonly,
    locale: $setup.currentLocale,
    format: $setup.format,
    "max-date": new Date(),
    "onUpdate:modelValue": $setup.updateValue
  }, null, 8 /* PROPS */, ["model-value", "model-type", "range", "partialRange", "multiCalendars", "autoApply", "enableTimePicker", "clearable", "utc", "disabled", "readonly", "locale", "max-date"]))
}