import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "text-xl font-semibold text-gray-900" }
const _hoisted_3 = { class: "grid grid-cols-1 md:grid-cols-4 gap-6" }
const _hoisted_4 = { class: "block text-sm font-medium leading-6 text-gray-900" }
const _hoisted_5 = ["value"]
const _hoisted_6 = { class: "block text-sm font-medium leading-6 text-gray-900 mb-2" }
const _hoisted_7 = ["value"]
const _hoisted_8 = { class: "block text-sm font-medium leading-6 text-gray-900 mb-2" }
const _hoisted_9 = ["value"]
const _hoisted_10 = {
  for: "level",
  class: "block text-sm font-medium leading-6 text-gray-900 mb-2"
}
const _hoisted_11 = {
  key: 0,
  class: "text-xs mt-2"
}
const _hoisted_12 = {
  for: "connectFlow",
  class: "block text-sm font-medium leading-6 text-gray-900 mb-2"
}
const _hoisted_13 = {
  key: 0,
  class: "text-xs mt-2"
}
const _hoisted_14 = { class: "flex justify-end px-4 sm:px-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["UiPageHeader"], null, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t("number." + $setup.props.action + ".title")), 1 /* TEXT */)
      ]),
      _: 1 /* STABLE */
    }),
    _createElementVNode("form", {
      class: "space-y-6",
      autocomplete: "off",
      onSubmit: _withModifiers($setup.onSubmit, ["prevent"])
    }, [
      _createVNode($setup["UiPanel"], null, {
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", null, [
              _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t("number.labels.number")), 1 /* TEXT */),
              _createElementVNode("input", {
                name: "number",
                type: "text",
                readonly: true,
                disabled: true,
                class: "mt-2 block w-full rounded-md border-0 px-4 py-3 bg-gray-200 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset focus:ring-2 focus:ring-inset shadow-sm ring-gray-300 focus:ring-primary",
                value: $setup.number ? $setup.applyPhoneMask($setup.number.number) : ''
              }, null, 8 /* PROPS */, _hoisted_5)
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t("number.labels.city")), 1 /* TEXT */),
              _createElementVNode("input", {
                name: "city",
                type: "text",
                readonly: true,
                disabled: true,
                class: "mt-2 block w-full rounded-md border-0 px-4 py-3 bg-gray-200 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset focus:ring-2 focus:ring-inset shadow-sm ring-gray-300 focus:ring-primary",
                value: `${$setup.number?.city}/${$setup.number?.state}`
              }, null, 8 /* PROPS */, _hoisted_7)
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t("number.labels.type")), 1 /* TEXT */),
              ($setup.number?.type)
                ? (_openBlock(), _createElementBlock("input", {
                    key: 0,
                    name: "city",
                    type: "text",
                    readonly: true,
                    disabled: true,
                    class: "mt-2 block w-full rounded-md border-0 px-4 py-3 bg-gray-200 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset focus:ring-2 focus:ring-inset shadow-sm ring-gray-300 focus:ring-primary",
                    value: _ctx.$t(`number.types.${$setup.number?.type}`)
                  }, null, 8 /* PROPS */, _hoisted_9))
                : _createCommentVNode("v-if", true)
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t("number.labels.level")), 1 /* TEXT */),
              _createVNode($setup["UiMultiselect"], {
                name: "level",
                label: "name",
                "value-prop": "id",
                modelValue: $setup.formState.levelId,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.formState.levelId) = $event)),
                disabled: !$setup.levelFilter.root,
                primitive: true,
                options: $setup.levels
              }, null, 8 /* PROPS */, ["modelValue", "disabled", "options"]),
              ($setup.formState.agents.length > 0)
                ? (_openBlock(), _createElementBlock("p", _hoisted_11, _toDisplayString(_ctx.$t("number.edit.blockLevel")), 1 /* TEXT */))
                : _createCommentVNode("v-if", true)
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("label", _hoisted_12, _toDisplayString(_ctx.$t("core.entity.ConnectFlow")), 1 /* TEXT */),
              _createVNode($setup["UiMultiselect"], {
                name: "connectFlowId",
                label: "name",
                "value-prop": "id",
                modelValue: $setup.formState.connectFlowId,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.formState.connectFlowId) = $event)),
                disabled: $setup.formState.agents.length > 0,
                primitive: true,
                options: $setup.connectFlows,
                "can-clear": ""
              }, null, 8 /* PROPS */, ["modelValue", "disabled", "options"]),
              ($setup.formState.agents.length > 0)
                ? (_openBlock(), _createElementBlock("p", _hoisted_13, _toDisplayString(_ctx.$t("number.edit.blockLevel")), 1 /* TEXT */))
                : _createCommentVNode("v-if", true)
            ]),
            _createElementVNode("div", null, [
              _createVNode($setup["UiTextInput"], {
                modelValue: $setup.formState.mediaLabel,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.formState.mediaLabel) = $event)),
                name: "mediaLabel",
                type: "text",
                label: _ctx.$t('number.edit.mediaLabel'),
                class: "col-span-6 sm:col-span-4",
                onBlur: $setup.v$.mediaLabel.$touch
              }, null, 8 /* PROPS */, ["modelValue", "label", "onBlur"])
            ])
          ])
        ]),
        _: 1 /* STABLE */
      }),
      _createElementVNode("div", _hoisted_14, [
        _createVNode($setup["UiButton"], {
          variant: "white",
          "text-variant": "gray-500",
          to: { name: 'ListNumber' }
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Cancel")), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        ($setup.props.action == $setup.FormAction.EDIT && _ctx.$can($setup.Permission.EDIT_NUMBER))
          ? (_openBlock(), _createBlock($setup["UiButton"], {
              key: 0,
              type: "submit",
              variant: "primary",
              "text-variant": "white",
              themed: "",
              class: "ml-4"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Save")), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true)
      ])
    ], 32 /* NEED_HYDRATION */),
    _createVNode($setup["UiCheckDirtyBeforeRouteLeave"], {
      dirty: $setup.v$.$anyDirty
    }, null, 8 /* PROPS */, ["dirty"])
  ]))
}