import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative w-full text-gray-400 focus-within:text-gray-600" }
const _hoisted_2 = ["placeholder", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["SearchIcon"], {
      class: "pointer-events-none absolute top-2 left-2 mr-5 h-5 w-5",
      "aria-hidden": "true"
    }),
    _createElementVNode("input", {
      name: "search-field",
      id: "search-field",
      class: "h-full w-full border-transparent py-2 pl-9 pr-3 text-base text-gray-900 placeholder-gray-500 focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-0 bg-transparent",
      placeholder: $setup.placeholderText,
      type: "search",
      value: $setup.props.modelValue,
      autocomplete: "off",
      onInput: _cache[0] || (_cache[0] = ($event: any) => {
        $setup.dbounce(() => {
          $setup.updateValue(($event.target as HTMLInputElement).value);
        }, $setup.props.debounce)()
      })
    }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_2)
  ]))
}