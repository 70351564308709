import { createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "p-6 space-y-6 overflow-hidden"
}
const _hoisted_2 = { class: "flex justify-end" }
const _hoisted_3 = { class: "text-2xl font-semibold text-gray-900" }
const _hoisted_4 = { class: "text-sm text-gray-600 mt-1" }
const _hoisted_5 = { class: "flex flex-col lg:flex-row gap-6" }
const _hoisted_6 = { class: "lg:w-1/2 space-y-6 h-[75vh]" }
const _hoisted_7 = { class: "lg:w-1/2 flex flex-col h-[75vh]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return ($setup.meeting !== null && !$setup.isLoading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createCommentVNode(" Título da reunião e detalhes "),
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_router_link, {
              class: "text-primary hover:text-primary-dark flex flex-row items-center ml-auto",
              to: { name: 'MeetingList' }
            }, {
              default: _withCtx(() => [
                _createVNode($setup["ChevronLeftIcon"], { class: "h-5 w-6" }),
                _createTextVNode(" " + _toDisplayString($setup.t("core.actions.Back")), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _createElementVNode("h1", _hoisted_3, _toDisplayString($setup.meeting.title), 1 /* TEXT */),
          _createElementVNode("p", _hoisted_4, " Gravado por " + _toDisplayString($setup.meeting.createdByUser?.name) + ", " + _toDisplayString($setup.formattedCreatedAt) + ", " + _toDisplayString(Math.floor($setup.meeting.speechDuration / 60)) + " min. ", 1 /* TEXT */)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createCommentVNode(" Coluna da esquerda "),
          _createElementVNode("div", _hoisted_6, [
            _createCommentVNode(" Insights dos palestrantes "),
            _createVNode($setup["SpeakerInsights"], {
              participants: $setup.meeting.participants
            }, null, 8 /* PROPS */, ["participants"])
          ]),
          _createCommentVNode(" Coluna da direita: Transcrição da reunião "),
          _createElementVNode("div", _hoisted_7, [
            _createVNode($setup["TranscriptSection"], {
              messages: $setup.meeting.messages,
              class: "overflow-y-auto rounded-md"
            }, null, 8 /* PROPS */, ["messages"])
          ])
        ])
      ]))
    : _createCommentVNode("v-if", true)
}