import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col flex-wrap sm:flex-row gap-2 pb-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.insights, (insight) => {
      return (_openBlock(), _createBlock($setup["UiBadge"], {
        key: `${$props.persona}-insight-${insight.name}`,
        class: "rounded-md cursor-pointer",
        variant: insight.selected ? 'orange' : 'yellow',
        border: insight.selected,
        onClick: ($event: any) => (insight.selected = !insight.selected)
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(insight.value), 1 /* TEXT */)
        ]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["variant", "border", "onClick"]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}