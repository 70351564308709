import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "block hover:bg-gray-50" }
const _hoisted_2 = { class: "px-4 py-4 sm:px-6" }
const _hoisted_3 = { class: "flex items-center justify-between" }
const _hoisted_4 = { class: "flex-1" }
const _hoisted_5 = {
  key: 0,
  class: "flex"
}
const _hoisted_6 = { class: "sr-only" }
const _hoisted_7 = { class: "mt-2 sm:flex sm:justify-between" }
const _hoisted_8 = { class: "sm:flex" }
const _hoisted_9 = { class: "flex items-center text-sm text-gray-500 mr-4" }
const _hoisted_10 = {
  key: 0,
  class: "mt-2 flex items-center text-sm text-gray-500 sm:mt-0"
}
const _hoisted_11 = ["datetime"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_tippy = _resolveDirective("tippy")!

  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.$can($setup.Permission.VIEW_SPAM_NUMBER) ? 'RouterLink' : 'span'), {
            to: {
              name: 'ViewNumberById',
              params: {
                id: $props.modelValue.id,
              },
            },
            class: "truncate text-md font-medium text-primary"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($setup.applyPhoneMask($props.modelValue.number)), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["to"]))
        ]),
        (_ctx.$can($setup.Permission.EDIT_SPAM_NUMBER))
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _withDirectives((_openBlock(), _createBlock($setup["UiButton"], {
                size: "",
                shape: "rounded-full",
                variant: "inherit",
                "text-variant": "primary",
                shadow: "none",
                themed: "",
                class: "border-transparent focus:ring-primary shadow-none ml-1 text-xs",
                onClick: $setup.handleEditClick
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t("core.actions.Edit")), 1 /* TEXT */),
                  _createVNode($setup["PencilAltIcon"], {
                    class: "h-6 w-6",
                    "aria-hidden": "true"
                  })
                ]),
                _: 1 /* STABLE */
              })), [
                [_directive_tippy, _ctx.$t('core.actions.Edit')]
              ])
            ]))
          : _createCommentVNode("v-if", true)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("p", _hoisted_9, [
            _createVNode($setup["FolderIcon"], {
              class: "mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400",
              "aria-hidden": "true"
            }),
            _createTextVNode(" " + _toDisplayString($props.modelValue.level.name), 1 /* TEXT */)
          ])
        ]),
        ($setup.lastModifiedBy != null)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createVNode($setup["CalendarIcon"], {
                class: "mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400",
                "aria-hidden": "true"
              }),
              _createElementVNode("p", null, [
                _createTextVNode(_toDisplayString($props.modelValue.updatedByUser
                ? _ctx.$t("core.updatedAt")
                : _ctx.$t("core.createdAt")) + " ", 1 /* TEXT */),
                _createElementVNode("time", {
                  datetime: $props.modelValue.updatedAt || $props.modelValue.createdAt
                }, _toDisplayString($setup.formatDateAsString(
                  $props.modelValue.updatedAt || $props.modelValue.createdAt,
                  "LL",
                )), 9 /* TEXT, PROPS */, _hoisted_11),
                _createTextVNode(" " + _toDisplayString(_ctx.$t("core.by")) + " ", 1 /* TEXT */),
                (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.$can($setup.Permission.VIEW_USER) ? 'RouterLink' : 'span'), {
                  to: {
                name: 'ViewUserById',
                params: {
                  id: $setup.lastModifiedBy.id,
                },
              },
                  class: "truncate text-sm font-medium text-primary"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString($setup.lastModifiedBy.name), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["to"]))
              ])
            ]))
          : _createCommentVNode("v-if", true)
      ])
    ])
  ]))
}