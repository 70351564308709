/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree, GetterTree, MutationTree } from "vuex";
import {
  INumberState,
  INumberFilter,
  NumberMutations,
  NumberActions,
  NumberGetters,
  ISortMenuItem,
  IFilterItem,
} from "@/definitions";

const getDefaultState = () => {
  return {
    search: null,
    sort: [],
    page: 1,
    filter: {
      hasConnectFlow: null,
      state: null,
      city: null,
    },
  } as INumberState;
};

const state: INumberState = getDefaultState();

const mutations: MutationTree<INumberState> = {
  [NumberMutations.SET_SEARCH]: (state, search: string) =>
    (state.search = search),
  [NumberMutations.SET_SORT]: (state, sort: ISortMenuItem[]) =>
    (state.sort = sort),
  [NumberMutations.SET_PAGE]: (state, page: number) => (state.page = page),
  [NumberMutations.SET_FILTER](state, payload: INumberFilter) {
    state.filter.hasConnectFlow = payload.hasConnectFlow;
    state.filter.state = payload.state;
    state.filter.city = payload.city;
  },
  [NumberMutations.CLEAR_FILTER](state, field?: string) {
    switch (field) {
      case "hasConnectFlow":
        state.filter.hasConnectFlow = getDefaultState().filter.hasConnectFlow;
        break;
      case "state":
        state.filter.state = getDefaultState().filter.state;
        break;
      case "city":
        state.filter.city = getDefaultState().filter.city;
        break;
      default:
        state.filter.state = getDefaultState().filter.state;
        state.filter.city = getDefaultState().filter.city;
    }
  },
};

const actions: ActionTree<INumberState, any> = {
  [NumberActions.SET_SEARCH]: ({ commit }, search: string) =>
    commit(NumberMutations.SET_SEARCH, search),
  [NumberActions.SET_SORT]: ({ commit }, sort: ISortMenuItem[]) =>
    commit(NumberMutations.SET_SORT, sort),
  [NumberActions.SET_PAGE]: ({ commit }, page: number) =>
    commit(NumberMutations.SET_PAGE, page),
  [NumberActions.SET_FILTER]({ commit }, payload: INumberFilter) {
    commit(NumberMutations.SET_FILTER, payload);
  },
  [NumberActions.CLEAR_FILTER]({ commit }, field?: string) {
    commit(NumberMutations.CLEAR_FILTER, field);
  },
};

const getters: GetterTree<INumberState, any> = {
  [NumberGetters.SEARCH]: (state): string | null => state.search,
  [NumberGetters.SORT]: (state): ISortMenuItem[] => state.sort,
  [NumberGetters.PAGE]: (state): number => state.page,
  [NumberGetters.DEFAULT_FILTER]: (): INumberFilter => getDefaultState().filter,
  [NumberGetters.FILTER]: (state): INumberFilter => state.filter,
  [NumberGetters.ACTIVE_FILTERS](state): IFilterItem[] {
    const filters: Array<IFilterItem> = [];

    if (state.filter.hasConnectFlow !== null) {
      filters.push({
        field: "hasConnectFlow",
        value: state.filter.hasConnectFlow.value,
        label: state.filter.hasConnectFlow.label,
      });
    }

    if (state.filter.state !== null) {
      filters.push({
        field: "state",
        value: state.filter.state.value,
        label: state.filter.state.label,
      });
    }

    if (state.filter.city !== null) {
      filters.push({
        field: "city",
        value: state.filter.city.value,
        label: state.filter.city.label,
      });
    }

    return filters;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
