import { createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "text-xl font-semibold text-gray-900 flex items-center" }
const _hoisted_3 = { class: "flex items-center space-x-4" }
const _hoisted_4 = { class: "-mx-4 mt-5 overflow-hidden bg-white shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg" }
const _hoisted_5 = { class: "flex h-16 border-b border-gray-200 bg-white shadow-sm" }
const _hoisted_6 = { class: "flex flex-1 justify-between px-4 sm:px-6" }
const _hoisted_7 = { class: "ml-2 flex items-center space-x-4 sm:ml-6 sm:space-x-6" }
const _hoisted_8 = {
  key: 0,
  class: "flex py-4 px-3 border-b border-gray-200 bg-white shadow-sm gap-1.5"
}
const _hoisted_9 = { class: "min-w-full divide-y divide-gray-300" }
const _hoisted_10 = { class: "bg-gray-200" }
const _hoisted_11 = { class: "divide-y divide-gray-200 bg-white" }
const _hoisted_12 = {
  key: 0,
  class: "whitespace-nowrap p-4 text-sm text-gray-900 text-center",
  colspan: "7"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["UiPageHeader"], null, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, [
          _createVNode($setup["LinkIcon"], {
            class: "w-6 h-6 mr-1 text-gray-500",
            "aria-hidden": "true"
          }),
          _createTextVNode(" " + _toDisplayString(_ctx.$t("core.entity.Integrations")) + " - " + _toDisplayString(_ctx.$t("integration.integrations." + $setup.props.type + ".title")) + " - " + _toDisplayString(_ctx.$t("integration.labels.logs")), 1 /* TEXT */)
        ])
      ]),
      actions: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createVNode($setup["UiButton"], {
            variant: "white",
            "text-variant": "gray-500",
            onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.goBack({ name: 'ListIntegration' })))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Back")), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          })
        ])
      ]),
      _: 1 /* STABLE */
    }),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("form", {
            class: "flex flex-1",
            action: "#",
            method: "GET",
            onSubmit: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"]))
          }, [
            _createVNode($setup["UiListSearch"], {
              modelValue: $setup.search,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.search) = $event)),
              placeholder: _ctx.$t('core.actions.Search')
            }, null, 8 /* PROPS */, ["modelValue", "placeholder"])
          ], 32 /* NEED_HYDRATION */),
          _createElementVNode("div", _hoisted_7, [
            _createVNode($setup["IntegrationLogListSort"], {
              modelValue: $setup.sortMenuSelected,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.sortMenuSelected) = $event))
            }, null, 8 /* PROPS */, ["modelValue"]),
            _createVNode($setup["IntegrationLogListFilters"])
          ])
        ])
      ]),
      ($setup.sortMenuSelected.length || $setup.activeFilters.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.sortMenuSelected, (selected) => {
              return (_openBlock(), _createBlock($setup["UiSortMenuBadge"], {
                key: selected.key,
                item: selected,
                onRemove: ($event: any) => ($setup.handleRemoveSortClick(selected))
              }, null, 8 /* PROPS */, ["item", "onRemove"]))
            }), 128 /* KEYED_FRAGMENT */)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.activeFilters, (filter) => {
              return (_openBlock(), _createBlock($setup["UiBadge"], {
                key: filter,
                removable: "",
                onRemove: ($event: any) => ($setup.handleRemoveFilter(filter.field))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t(`integration.logs.filters.activeTag.${filter.field}`)) + ": " + _toDisplayString(filter.label), 1 /* TEXT */)
                ]),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onRemove"]))
            }), 128 /* KEYED_FRAGMENT */))
          ]))
        : _createCommentVNode("v-if", true),
      _createElementVNode("table", _hoisted_9, [
        _createElementVNode("thead", null, [
          _createElementVNode("tr", _hoisted_10, [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList($setup.cols, (col) => {
              return _createElementVNode("th", {
                key: col,
                scope: "col",
                class: "whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
              }, _toDisplayString(_ctx.$t(`integration.logs.cols.${col}`)), 1 /* TEXT */)
            }), 64 /* STABLE_FRAGMENT */))
          ])
        ]),
        _createElementVNode("tbody", _hoisted_11, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.integrationLogs, (log, index) => {
            return (_openBlock(), _createBlock($setup["IntegrationLogListItem"], {
              key: log.id,
              "model-value": log,
              class: _normalizeClass({
              'bg-gray-100': index % 2,
            })
            }, null, 8 /* PROPS */, ["model-value", "class"]))
          }), 128 /* KEYED_FRAGMENT */)),
          (!$setup.integrationLogs.length)
            ? (_openBlock(), _createElementBlock("td", _hoisted_12, _toDisplayString(_ctx.$t("core.messages.noRecordsFound")), 1 /* TEXT */))
            : _createCommentVNode("v-if", true)
        ])
      ]),
      _createVNode($setup["UiPagination"], {
        modelValue: $setup.currentPage,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.currentPage) = $event)),
        count: $setup.count,
        perPage: $setup.perPage,
        i18n: _ctx.$t,
        class: "h-16 border-t border-gray-200 bg-white shadow-sm px-4 sm:px-6"
      }, null, 8 /* PROPS */, ["modelValue", "count", "i18n"])
    ])
  ]))
}