import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "bg-white shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg relative" }
const _hoisted_2 = { class: "absolute z-30 md:rounded-lg py-30 px-4 flex w-full h-full items-center place-content-center" }
const _hoisted_3 = { class: "text-white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($props.locked)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", {
            class: _normalizeClass(["absolute w-full opacity-50 h-full bg-gray-700 md:rounded-lg z-20", $setup.defaultClasses])
          }, null, 2 /* CLASS */),
          _createElementVNode("div", _hoisted_2, [
            _createVNode($setup["LockClosedIcon"], { class: "h-6 w-6 mr-3 text-white" }),
            _createElementVNode("p", _hoisted_3, _toDisplayString($props.textLock), 1 /* TEXT */)
          ])
        ], 64 /* STABLE_FRAGMENT */))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", {
      class: _normalizeClass(["mt-8", $setup.defaultClasses])
    }, [
      _renderSlot(_ctx.$slots, "content")
    ], 2 /* CLASS */)
  ]))
}