import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative h-full w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["UiChart"], { option: $setup.callStatusOptions }, null, 8 /* PROPS */, ["option"]),
    ($setup.props.loading)
      ? (_openBlock(), _createBlock($setup["UiSpinner"], {
          key: 0,
          class: "h-5 w-5 ml-3 absolute bottom-3 right-4 theme-color"
        }))
      : _createCommentVNode("v-if", true)
  ]))
}