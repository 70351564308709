import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center" }
const _hoisted_2 = ["name", "id"]
const _hoisted_3 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      name: $props.name,
      id: $props.name,
      type: "checkbox",
      class: "h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.model) = $event))
    }, null, 8 /* PROPS */, _hoisted_2), [
      [_vModelCheckbox, $setup.model]
    ]),
    _createElementVNode("label", {
      for: $props.name,
      class: "ml-3 text-sm font-medium leading-6 text-gray-900"
    }, _toDisplayString($props.label), 9 /* TEXT, PROPS */, _hoisted_3)
  ]))
}