import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "relative h-full w-full" }
const _hoisted_2 = { class: "min-w-full border-separate border-spacing-0" }
const _hoisted_3 = {
  scope: "col",
  class: "sticky top-0 z-10 border-b border-gray-300 bg-gray-200 whitespace-nowrap py-1.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900"
}
const _hoisted_4 = {
  scope: "col",
  class: "sticky top-0 z-10 border-b border-gray-300 bg-gray-200 px-3 py-1.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell max-h-4 whitespace-nowrap"
}
const _hoisted_5 = {
  scope: "col",
  class: "sticky top-0 z-10 border-b border-gray-300 bg-gray-200 px-3 py-1.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell max-h-4 whitespace-nowrap"
}
const _hoisted_6 = {
  scope: "col",
  class: "sticky top-0 z-10 border-b border-gray-300 bg-gray-200 px-3 py-1.5 text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell max-h-4 text-center whitespace-nowrap"
}
const _hoisted_7 = {
  scope: "col",
  class: "sticky top-0 z-10 border-b border-gray-300 bg-gray-200 px-3 py-1.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell max-h-4 whitespace-nowrap"
}
const _hoisted_8 = {
  scope: "col",
  class: "sticky top-0 z-10 border-b border-gray-300 bg-gray-200 px-3 py-1.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell max-h-4 whitespace-nowrap"
}
const _hoisted_9 = {
  scope: "col",
  class: "sticky top-0 z-10 border-b border-gray-300 bg-gray-200 px-3 py-1.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell max-h-4 whitespace-nowrap"
}
const _hoisted_10 = {
  scope: "col",
  class: "sticky top-0 z-10 border-b border-gray-300 bg-gray-200 px-3 py-1.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell max-h-4 whitespace-nowrap"
}
const _hoisted_11 = { class: "whitespace-nowrap border-b border-gray-200 px-3 py-1.5 text-sm font-medium" }
const _hoisted_12 = { class: "whitespace-nowrap border-b border-gray-200 px-3 py-1.5 text-sm font-medium" }
const _hoisted_13 = { class: "whitespace-nowrap border-b border-gray-200 px-3 py-1.5 text-sm font-medium" }
const _hoisted_14 = { class: "whitespace-nowrap border-b border-gray-200 px-3 py-1.5 text-sm font-medium" }
const _hoisted_15 = { class: "whitespace-nowrap border-b border-gray-200 px-3 py-1.5 text-sm font-medium" }
const _hoisted_16 = { class: "whitespace-nowrap border-b border-gray-200 px-3 py-1.5 text-sm font-medium" }
const _hoisted_17 = { class: "whitespace-nowrap border-b border-gray-200 px-3 py-1.5 text-sm font-medium" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("table", _hoisted_2, [
      _createElementVNode("thead", null, [
        _createElementVNode("tr", null, [
          _createElementVNode("th", _hoisted_3, _toDisplayString(_ctx.$t("report.realtime.detailsUsers.extension")), 1 /* TEXT */),
          _createElementVNode("th", _hoisted_4, _toDisplayString(_ctx.$t("report.realtime.detailsUsers.user")), 1 /* TEXT */),
          _createElementVNode("th", _hoisted_5, _toDisplayString(_ctx.$t("report.realtime.detailsUsers.device")), 1 /* TEXT */),
          _createElementVNode("th", _hoisted_6, _toDisplayString(_ctx.$t("report.realtime.detailsUsers.status")), 1 /* TEXT */),
          _createElementVNode("th", _hoisted_7, _toDisplayString(_ctx.$t("report.realtime.detailsUsers.numberClient")), 1 /* TEXT */),
          _createElementVNode("th", _hoisted_8, _toDisplayString(_ctx.$t("report.realtime.detailsUsers.quality")), 1 /* TEXT */),
          _createElementVNode("th", _hoisted_9, _toDisplayString(_ctx.$t("report.realtime.detailsUsers.type")), 1 /* TEXT */),
          _createElementVNode("th", _hoisted_10, _toDisplayString(_ctx.$t("report.realtime.detailsUsers.duration")), 1 /* TEXT */)
        ])
      ]),
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.items, (item, idx) => {
          return (_openBlock(), _createElementBlock("tr", {
            key: `queueDetailItem${idx}`
          }, [
            _createElementVNode("td", _hoisted_11, _toDisplayString(item.extension), 1 /* TEXT */),
            _createElementVNode("td", _hoisted_12, _toDisplayString(item.user), 1 /* TEXT */),
            _createElementVNode("td", _hoisted_13, _toDisplayString(_ctx.$t(`core.device.${item.userDevice}`)), 1 /* TEXT */),
            _createElementVNode("td", {
              class: _normalizeClass([[
              item.status == $setup.UserDeviceStatus.OFFLINE
                ? 'bg-red-50 text-red-700'
                : 'bg-green-50 text-green-700',
            ], "whitespace-nowrap border-b border-gray-200 px-3 py-1.5 text-sm font-medium text-center"])
            }, _toDisplayString(_ctx.$t(`report.realtime.device.status.${item.status}`)), 3 /* TEXT, CLASS */),
            _createElementVNode("td", _hoisted_14, _toDisplayString(item.numberClient), 1 /* TEXT */),
            _createElementVNode("td", _hoisted_15, _toDisplayString(item.quality), 1 /* TEXT */),
            _createElementVNode("td", _hoisted_16, _toDisplayString(item.type), 1 /* TEXT */),
            _createElementVNode("td", _hoisted_17, _toDisplayString(item.duration ? $setup.formatSecToTime(item.duration) : ""), 1 /* TEXT */)
          ]))
        }), 128 /* KEYED_FRAGMENT */))
      ])
    ]),
    ($setup.loading)
      ? (_openBlock(), _createBlock($setup["UiSpinner"], {
          key: 0,
          class: "h-5 w-5 ml-3 absolute bottom-3 right-4 theme-color"
        }))
      : _createCommentVNode("v-if", true)
  ]))
}