import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "border-b border-gray-300 pt-4 relative mb-4 pb-7" }
const _hoisted_2 = { class: "grid sm:grid-cols-8 grid-cols-1 items-center gap-4 gap-y-5" }
const _hoisted_3 = { class: "col-span-3" }
const _hoisted_4 = {
  for: "level",
  class: "block text-sm font-medium leading-6 mb-2 text-gray-900"
}
const _hoisted_5 = { class: "grid sm:grid-cols-8 grid-cols-1 items-center gap-4 gap-y-5 mt-4" }
const _hoisted_6 = { class: "col-span-3 h-full flex flex-col" }
const _hoisted_7 = {
  for: "level",
  class: "block text-sm font-medium leading-6 mb-2 text-gray-900"
}
const _hoisted_8 = {
  key: 0,
  class: "col-span-3"
}
const _hoisted_9 = {
  controls: "",
  class: "w-full"
}
const _hoisted_10 = ["src", "type"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($props.showRemove)
      ? (_openBlock(), _createBlock($setup["UiButton"], {
          key: 0,
          variant: "orange",
          class: "absolute top-1 right-1",
          onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.removeQuestion()))
        }, {
          default: _withCtx(() => [
            _createVNode($setup["TrashIcon"], { class: "w-4 h-4" })
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_2, [
      _createVNode($setup["UiTextInput"], {
        id: `questionLabel-${$setup.props.index}`,
        name: "questionLabel",
        modelValue: $setup.v$.label.$model,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.v$.label.$model) = $event)),
        label: _ctx.$t('survey.labels.name'),
        placeholder: _ctx.$t('survey.create.section.questions.description'),
        class: "col-span-5",
        errors: $setup.v$.label.$errors
      }, null, 8 /* PROPS */, ["id", "modelValue", "label", "placeholder", "errors"]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t("survey.view.section.questions.scoreRange")), 1 /* TEXT */),
        _createVNode($setup["UiMultiselect"], {
          id: `initScore-${$setup.props.index}`,
          name: "initScore",
          primitive: true,
          modelValue: $setup.formState.rangeAnswer,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.formState.rangeAnswer) = $event)),
          options: $setup.scores,
          class: "w-full min-w-[120px]",
          errors: $setup.v$.rangeAnswer.$errors
        }, null, 8 /* PROPS */, ["id", "modelValue", "errors"])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode($setup["UiTextArea"], {
        id: `textToAudio-${$setup.props.index}`,
        name: "textToAudio",
        label: _ctx.$t('survey.labels.text'),
        placeholder: _ctx.$t('survey.labels.questionLabel'),
        disabled: $setup.isAudioEmpty,
        rows: "2",
        modelValue: $setup.v$.textToAudio.$model,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.v$.textToAudio.$model) = $event)),
        class: "col-span-5",
        errors: $setup.v$.textToAudio.$errors
      }, null, 8 /* PROPS */, ["id", "label", "placeholder", "disabled", "modelValue", "errors"]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t("survey.view.section.questions.audio")), 1 /* TEXT */),
        _createVNode($setup["UiAudioUpload"], {
          id: `audioUpload-${$setup.props.index}`,
          index: $setup.props.index,
          class: "flex-grow",
          disabled: $setup.isTextAreaEmpty,
          errors: $setup.v$.audio.$errors,
          "text-rule": _ctx.$t('connectFlowAudio.create.uploadRules'),
          "onUpdate:file": $setup.handleFileUpdate
        }, null, 8 /* PROPS */, ["id", "index", "disabled", "errors", "text-rule"])
      ]),
      ($setup.audioData)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createElementVNode("audio", _hoisted_9, [
              _createElementVNode("source", {
                src: $setup.audioData,
                type: $setup.getAudioFormat($setup.formState.audioFilePath || '')
              }, null, 8 /* PROPS */, _hoisted_10)
            ])
          ]))
        : _createCommentVNode("v-if", true)
    ])
  ]))
}