import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = { class: "h-fit text-gray-600 w-full text-sm" }
const _hoisted_3 = {
  key: 0,
  class: "w-full block"
}
const _hoisted_4 = { class: "block w-full text-[10px] leading-5 text-gray-600" }
const _hoisted_5 = ["id", "disabled"]
const _hoisted_6 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex content-center w-full rounded-md relative border-0 text-gray-900 placeholder:text-gray-400 ring-1 ring-inset focus:ring-2 focus:ring-inset shadow-sm p-2", [
      $setup.disabledClass,
      !$setup.hasErros
        ? 'ring-gray-300 focus:ring-primary'
        : 'ring-danger focus:ring-danger',
    ]])
  }, [
    ($setup.fileName.length)
      ? (_openBlock(), _createBlock($setup["XIcon"], {
          key: 0,
          class: "h-4 w-4 text-gray-400 absolute top-2 right-2 font-bold cursor-pointer",
          onClick: $setup.removeFile
        }))
      : _createCommentVNode("v-if", true),
    ($setup.props.file === null)
      ? (_openBlock(), _createElementBlock("label", {
          key: 1,
          for: `audio${$setup.props.index}`,
          class: _normalizeClass(["text-sm gap-3 flex font-semibold w-11/12 shrink max-h-fit", [
        $setup.props.disabled ? '' : 'cursor-pointer',
        'focus-within:outline-none focus-within:ring-2 focus-within:ring-cyan-600 focus-within:ring-offset-2',
      ]])
        }, [
          _createVNode($setup["CloudUploadIcon"], { class: "h-8 w-8 mt-1 text-gray-300 disabled:text-white" }),
          _createElementVNode("span", _hoisted_2, [
            ($setup.fileName.length)
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString($setup.fileName), 1 /* TEXT */))
              : (_openBlock(), _createElementBlock("span", {
                  key: 1,
                  class: _normalizeClass([$setup.props.disabled ? 'text-gray-500' : 'text-primary'])
                }, _toDisplayString(_ctx.$t("connectFlowAudio.create.fileLabel")), 3 /* TEXT, CLASS */)),
            _createElementVNode("span", _hoisted_4, _toDisplayString($setup.props.textRule), 1 /* TEXT */),
            _createElementVNode("input", {
              onChange: $setup.handleAudioUpload,
              name: "audio",
              id: `audio${$setup.props.index}`,
              type: "file",
              disabled: $setup.props.disabled,
              class: _normalizeClass(['sr-only'])
            }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_5)
          ])
        ], 10 /* CLASS, PROPS */, _hoisted_1))
      : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString($setup.props.file?.name), 1 /* TEXT */))
  ], 2 /* CLASS */))
}