import { createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, resolveComponent as _resolveComponent, createSlots as _createSlots, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "text-xl font-semibold text-gray-900 flex items-center" }
const _hoisted_3 = { class: "-mx-4 mt-5 overflow-hidden bg-white shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg" }
const _hoisted_4 = { class: "flex h-16 border-b border-gray-200 bg-white shadow-sm" }
const _hoisted_5 = { class: "flex flex-1 justify-between px-4 sm:px-6" }
const _hoisted_6 = { class: "ml-2 flex items-center space-x-4 sm:ml-6 sm:space-x-6" }
const _hoisted_7 = {
  key: 0,
  class: "flex py-4 px-3 border-b border-gray-200 bg-white shadow-sm gap-1.5"
}
const _hoisted_8 = {
  role: "list",
  class: "divide-y divide-gray-200"
}
const _hoisted_9 = {
  key: 0,
  class: "block hover:bg-gray-50"
}
const _hoisted_10 = { class: "px-4 py-4 sm:px-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["UiPageHeader"], null, _createSlots({
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, [
          _createVNode($setup["UserGroupIcon"], {
            class: "w-6 h-6 mr-1 text-gray-500",
            "aria-hidden": "true"
          }),
          _createTextVNode(" " + _toDisplayString(_ctx.$t("core.entity.UsersLabels")), 1 /* TEXT */)
        ])
      ]),
      _: 2 /* DYNAMIC */
    }, [
      (_ctx.$can($setup.Permission.CREATE_USER_LABEL))
        ? {
            name: "actions",
            fn: _withCtx(() => [
              _createVNode(_component_router_link, { to: {
            name: 'CreateUserLabel',
          } }, {
                default: _withCtx(() => [
                  _createVNode($setup["UiButton"], {
                    variant: "primary",
                    "text-variant": "white",
                    themed: ""
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Add")), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              })
            ]),
            key: "0"
          }
        : undefined
    ]), 1024 /* DYNAMIC_SLOTS */),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("form", {
            class: "flex flex-1",
            action: "#",
            method: "GET",
            onSubmit: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"]))
          }, [
            _createVNode($setup["UiListSearch"], {
              modelValue: $setup.search,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.search) = $event)),
              placeholder: _ctx.$t('userLabel.filters.search')
            }, null, 8 /* PROPS */, ["modelValue", "placeholder"])
          ], 32 /* NEED_HYDRATION */),
          _createElementVNode("div", _hoisted_6, [
            _createVNode($setup["UserLabelListSort"], {
              modelValue: $setup.sortMenuSelected,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.sortMenuSelected) = $event))
            }, null, 8 /* PROPS */, ["modelValue"]),
            _createVNode($setup["UserLabelListFilters"])
          ])
        ])
      ]),
      ($setup.sortMenuSelected.length || $setup.activeFilters.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.sortMenuSelected, (selected) => {
              return (_openBlock(), _createBlock($setup["UiSortMenuBadge"], {
                key: selected.key,
                item: selected,
                onRemove: ($event: any) => ($setup.handleRemoveSortClick(selected))
              }, null, 8 /* PROPS */, ["item", "onRemove"]))
            }), 128 /* KEYED_FRAGMENT */)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.activeFilters, (filter) => {
              return (_openBlock(), _createBlock($setup["UiBadge"], {
                key: filter,
                removable: "",
                onRemove: ($event: any) => ($setup.handleRemoveFilter(filter.field))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t(`userLabel.filters.activeTag.${filter.field}`)) + ": " + _toDisplayString(filter.label), 1 /* TEXT */)
                ]),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onRemove"]))
            }), 128 /* KEYED_FRAGMENT */))
          ]))
        : _createCommentVNode("v-if", true),
      _createElementVNode("ul", _hoisted_8, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.userLabels, (labels) => {
          return (_openBlock(), _createBlock($setup["UserLabelListItem"], {
            key: `userLabels${labels.id}`,
            "model-value": labels,
            onActivate: $setup.handleActivateClick,
            onInactivate: $setup.handleInactivateClick
          }, null, 8 /* PROPS */, ["model-value"]))
        }), 128 /* KEYED_FRAGMENT */)),
        (!$setup.userLabels.length)
          ? (_openBlock(), _createElementBlock("li", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t("core.messages.noRecordsFound")), 1 /* TEXT */)
            ]))
          : _createCommentVNode("v-if", true)
      ]),
      _createVNode($setup["UiPagination"], {
        modelValue: $setup.currentPage,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.currentPage) = $event)),
        count: $setup.count,
        perPage: $setup.perPage,
        i18n: _ctx.$t,
        class: "h-16 border-t border-gray-200 bg-white shadow-sm px-4 sm:px-6"
      }, null, 8 /* PROPS */, ["modelValue", "count", "i18n"])
    ])
  ]))
}