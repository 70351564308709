import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "hidden sm:block" }
const _hoisted_2 = {
  autocomplete: "off",
  class: "flex flex-col gap-3"
}
const _hoisted_3 = { class: "grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6" }
const _hoisted_4 = {
  key: 0,
  class: "sm:col-span-4"
}
const _hoisted_5 = { class: "block text-sm font-medium leading-6 text-gray-900" }
const _hoisted_6 = {
  key: 1,
  class: "sm:col-span-4"
}
const _hoisted_7 = { class: "block text-sm font-medium leading-6 text-gray-900" }
const _hoisted_8 = { class: "sm:col-span-4" }
const _hoisted_9 = { class: "block text-sm font-medium leading-6 text-gray-900" }
const _hoisted_10 = { class: "sm:col-span-4" }
const _hoisted_11 = { class: "block text-sm font-medium leading-6 text-gray-900" }
const _hoisted_12 = { class: "flex flex-col gap-0.5 py-1" }
const _hoisted_13 = { class: "flex flex-row gap-3" }
const _hoisted_14 = { class: "text-sm text-gray-600" }
const _hoisted_15 = { class: "sm:col-span-4" }
const _hoisted_16 = { class: "block text-sm font-medium leading-6 text-gray-900" }
const _hoisted_17 = { class: "sm:col-span-4" }
const _hoisted_18 = { class: "block text-sm font-medium leading-6 text-gray-900 text-left" }
const _hoisted_19 = { class: "flex items-center justify-center mb-1 p-0 pt-1 rounded-md shadow bg-gray-100" }
const _hoisted_20 = { class: "grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6" }
const _hoisted_21 = { class: "sm:col-span-4" }
const _hoisted_22 = { class: "block text-sm font-medium leading-6 text-gray-900" }
const _hoisted_23 = { class: "grid grid-cols-2 mt-5 gap-4 place-content-around text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["UiRightPanel"], {
    modelValue: $setup.filtersOpened,
    "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => (($setup.filtersOpened) = $event)),
    title: _ctx.$t('filter.sidebar.title')
  }, {
    button: _withCtx(() => [
      _createVNode($setup["UiButton"], {
        size: "md",
        variant: "white",
        "text-variant": "gray-700",
        class: "border border-gray-300 hover:bg-gray-50 focus:ring-primary shadow-none mr-1",
        onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.filtersOpened = true))
      }, {
        default: _withCtx(() => [
          _createVNode($setup["FilterIcon"], {
            class: "h-5 w-5 text-gray-400 mr-0 sm:mr-3",
            "aria-hidden": "true"
          }),
          _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t("core.actions.Filter")), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      })
    ]),
    default: _withCtx(() => [
      _createElementVNode("form", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          (!$setup.isCallTypeRoute)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t("call.filters.type")), 1 /* TEXT */),
                _createVNode($setup["UiMultiselect"], {
                  name: "filters_type",
                  modelValue: $setup.filter.type,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.filter.type) = $event)),
                  "can-clear": true,
                  options: $setup.typeFilterOptions,
                  placeholder: $setup.t('core.options.All')
                }, null, 8 /* PROPS */, ["modelValue", "placeholder"])
              ]))
            : _createCommentVNode("v-if", true),
          (!$setup.isCallStatusRoute)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t("call.filters.status")), 1 /* TEXT */),
                _createVNode($setup["UiMultiselect"], {
                  name: "filters_status",
                  modelValue: $setup.filter.status,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.filter.status) = $event)),
                  "can-clear": true,
                  options: $setup.statusFilterOptions,
                  placeholder: $setup.t('core.options.All')
                }, null, 8 /* PROPS */, ["modelValue", "placeholder"])
              ]))
            : _createCommentVNode("v-if", true),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t("call.filters.numberClient")), 1 /* TEXT */),
            _createVNode($setup["UiMultiselect"], {
              name: "filters_number_client",
              modelValue: $setup.filter.numberClient,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.filter.numberClient) = $event)),
              searchable: "async",
              "can-clear": true,
              minCharMessage: true,
              placeholder: _ctx.$t('core.multiselect.typeSearch'),
              options: $setup.getClientNumbers
            }, null, 8 /* PROPS */, ["modelValue", "placeholder"])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.$t("call.filters.nameAgent")), 1 /* TEXT */),
            _createVNode($setup["UiMultiselect"], {
              name: "filters_user",
              modelValue: $setup.filter.user,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.filter.user) = $event)),
              label: "name",
              "value-prop": "id",
              searchable: "local",
              "can-clear": true,
              placeholder: _ctx.$t('core.multiselect.typeSearch'),
              options: $setup.agentOptions
            }, {
              "option-label": _withCtx(({ option }) => [
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("span", null, _toDisplayString(option.name), 1 /* TEXT */),
                    _createElementVNode("span", {
                      class: _normalizeClass([
                        option.isActive
                          ? 'bg-green-100 text-green-700'
                          : 'bg-red-100 text-red-700',
                        'px-2 py-0.5 rounded text-xs border',
                      ])
                    }, _toDisplayString(option.isActive
                          ? _ctx.$t("core.options.Active")
                          : _ctx.$t("core.options.Inactive")), 3 /* TEXT, CLASS */)
                  ]),
                  _createElementVNode("div", _hoisted_14, _toDisplayString(option.email), 1 /* TEXT */)
                ])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["modelValue", "placeholder", "options"])
          ]),
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("label", _hoisted_16, _toDisplayString(_ctx.$t("report.call.filters.userLabel")), 1 /* TEXT */),
            _createVNode($setup["UiMultiselect"], {
              name: "filter_userLabel",
              modelValue: $setup.filter.userLabel,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.filter.userLabel) = $event)),
              label: "label",
              "value-prop": "id",
              options: $setup.userLabelOptions,
              "can-clear": true,
              placeholder: $setup.t('core.options.All')
            }, null, 8 /* PROPS */, ["modelValue", "options", "placeholder"])
          ]),
          _createElementVNode("div", _hoisted_17, [
            _createElementVNode("label", _hoisted_18, _toDisplayString(_ctx.$t("call.filters.words")), 1 /* TEXT */),
            _createElementVNode("div", _hoisted_19, [
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList($setup.callParticipantOptions, (option, index) => {
                return _createVNode($setup["UiButton"], {
                  key: option.value,
                  variant: $setup.getButtonVariant(option.value),
                  "text-variant": $setup.getTextVariant(option.value),
                  themed: true,
                  size: 'md',
                  shape: 'rounded',
                  shadow: 'sm',
                  id: `participant-button-${index}`,
                  class: "w-full text-center mx-0 flex-grow",
                  onClick: ($event: any) => ($setup.filterCallParticipants(option.value))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(option.label), 1 /* TEXT */)
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["variant", "text-variant", "id", "onClick"])
              }), 64 /* STABLE_FRAGMENT */))
            ]),
            _createVNode($setup["UiMultiselect"], {
              class: "mb-4",
              name: "filters_word",
              modelValue: $setup.filter.word,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($setup.filter.word) = $event)),
              label: "word",
              "value-prop": "id",
              options: $setup.words,
              "can-clear": true,
              placeholder: $setup.t('core.options.All')
            }, null, 8 /* PROPS */, ["modelValue", "options", "placeholder"])
          ])
        ]),
        _createElementVNode("div", _hoisted_20, [
          _createElementVNode("div", _hoisted_21, [
            _createElementVNode("label", _hoisted_22, _toDisplayString(_ctx.$t("call.filters.media")), 1 /* TEXT */),
            _createVNode($setup["UiMultiselect"], {
              name: "filters_media",
              modelValue: $setup.filter.media,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($setup.filter.media) = $event)),
              "can-clear": true,
              options: $setup.mediaFilterOptions,
              placeholder: $setup.t('core.options.All')
            }, null, 8 /* PROPS */, ["modelValue", "options", "placeholder"])
          ])
        ]),
        _createElementVNode("div", _hoisted_23, [
          _createVNode($setup["UiButton"], {
            variant: "white",
            "text-variant": "gray-700",
            themed: "",
            onClick: _cache[8] || (_cache[8] = ($event: any) => ($setup.clearFilters()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Clear")), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode($setup["UiButton"], {
            variant: "primary",
            "text-variant": "white",
            themed: "",
            onClick: _cache[9] || (_cache[9] = ($event: any) => ($setup.applyFilters()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Apply")), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          })
        ])
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "title"]))
}