import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "bg-white/40 w-full p-2 pb-0 flex items-center justify-center font-medium gap-x-2 ring-1 ring-black ring-opacity-5"
}
const _hoisted_2 = { class: "p-4 sm:p-6 lg:p-8 sm:pt-4 lg:pt-4" }
const _hoisted_3 = { class: "text-xl font-semibold text-gray-900" }
const _hoisted_4 = { class: "w-full sm:w-64" }
const _hoisted_5 = {
  key: 0,
  class: "mt-5 flex px-4 py-5 sm:p-6 bg-white shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"
}
const _hoisted_6 = { class: "truncate text-xs font-medium text-gray-500 text-center" }
const _hoisted_7 = { class: "mt-1 text-2xl font-semibold tracking-tight text-gray-800 text-center" }
const _hoisted_8 = {
  key: 2,
  class: "mt-5 grid grid-cols-1 gap-5 lg:grid-cols-2 px-4 sm:px-0"
}
const _hoisted_9 = { class: "h-[300px] -mx-4 sm:-mx-6 md:mx-0 bg-white shadow ring-1 ring-black ring-opacity-5 rounded-lg col-span-2 md:col-span-1" }
const _hoisted_10 = { class: "h-[300px] -mx-4 sm:-mx-6 md:mx-0 bg-white shadow ring-1 ring-black ring-opacity-5 rounded-lg col-span-2 md:col-span-1" }
const _hoisted_11 = { class: "h-[300px] -mx-4 sm:-mx-6 md:mx-0 bg-white shadow ring-1 ring-black ring-opacity-5 rounded-lg col-span-2 md:col-span-1" }
const _hoisted_12 = { class: "h-[300px] -mx-4 sm:-mx-6 md:mx-0 bg-white shadow ring-1 ring-black ring-opacity-5 rounded-lg col-span-2 md:col-span-1" }
const _hoisted_13 = { class: "h-[300px] -mx-4 sm:-mx-6 md:mx-0 bg-white shadow ring-1 ring-black ring-opacity-5 rounded-lg col-span-2 hidden sm:block" }
const _hoisted_14 = { class: "h-[300px] -mx-4 sm:-mx-6 md:mx-0 bg-white shadow ring-1 ring-black ring-opacity-5 rounded-lg col-span-2 md:col-span-1" }
const _hoisted_15 = { class: "h-[300px] -mx-4 sm:-mx-6 md:mx-0 bg-white shadow ring-1 ring-black ring-opacity-5 md:rounded-lg col-span-2 md:col-span-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.$can($setup.Permission.VIEW_CALL))
      ? (_openBlock(), _createElementBlock("nav", _hoisted_1, [
          _createVNode(_component_router_link, {
            class: _normalizeClass(["pr-3 pl-2 py-1 hover:text-indigo-800 hover:border-b-2 hover:border-indigo-800 flex items-center", [
        _ctx.$route.name === 'CallList'
          ? 'text-indigo-800 border-b-2 border-indigo-800'
          : 'text-gray-500 border-b-2 border-transparent',
      ]]),
            to: { name: 'CallList' }
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("dashboard.calls")), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["class"]),
          _createVNode(_component_router_link, {
            class: _normalizeClass(["pr-3 pl-2 py-1 hover:text-indigo-800 hover:border-b-2 hover:border-indigo-800 flex items-center", [
        _ctx.$route.name === 'CallReport'
          ? 'text-indigo-800 border-b-2 border-indigo-800'
          : 'text-gray-500 border-b-2 border-transparent',
      ]]),
            to: { name: 'CallReport' }
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("report.call.title")), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["class"])
        ]))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_2, [
      _createVNode($setup["UiPageHeader"], null, {
        info: _withCtx(() => [
          _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t("report.call.title")), 1 /* TEXT */)
        ]),
        actions: _withCtx(() => [
          _createVNode($setup["CallReportFilters"]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode($setup["UiDatePicker"], {
              modelValue: $setup.dateRange,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.dateRange) = $event)),
              range: "",
              multiCalendars: ""
            }, null, 8 /* PROPS */, ["modelValue"])
          ])
        ]),
        _: 1 /* STABLE */
      }),
      ($setup.activeFilters.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.activeFilters, (filter) => {
              return (_openBlock(), _createBlock($setup["UiBadge"], {
                key: filter,
                removable: "",
                onRemove: ($event: any) => ($setup.handleRemoveFilter(filter))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t(`report.call.filters.activeTag.${filter.field}`)) + ": " + _toDisplayString(filter.label), 1 /* TEXT */)
                ]),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onRemove"]))
            }), 128 /* KEYED_FRAGMENT */))
          ]))
        : _createCommentVNode("v-if", true),
      ($setup.headerStats)
        ? (_openBlock(), _createElementBlock("dl", {
            key: 1,
            class: _normalizeClass(["mt-5 grid grid-cols-2 gap-3", ['sm:grid-cols-' + $setup.headerStats.length]])
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.headerStats, (stat) => {
              return (_openBlock(), _createElementBlock("div", {
                key: stat.name,
                class: "overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-3"
              }, [
                _createElementVNode("dt", _hoisted_6, _toDisplayString(_ctx.$t("report.call.stats." + stat.name)), 1 /* TEXT */),
                _createElementVNode("dd", _hoisted_7, _toDisplayString(stat.value), 1 /* TEXT */)
              ]))
            }), 128 /* KEYED_FRAGMENT */))
          ], 2 /* CLASS */))
        : _createCommentVNode("v-if", true),
      ($setup.callsStats)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createVNode($setup["CallStatusDoughnutChart"], {
                title: _ctx.$t('report.call.callStatusDoughnutChart.title'),
                data: $setup.callsStats.status
              }, null, 8 /* PROPS */, ["title", "data"])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createVNode($setup["CallStatusPerDayBarChart"], {
                title: _ctx.$t('report.call.callStatusPerDayBarChart.title'),
                data: $setup.callsStats.statusPerDay
              }, null, 8 /* PROPS */, ["title", "data"])
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createVNode($setup["CallTypesPerDayBarChart"], {
                title: _ctx.$t('report.call.callTypePerDayBarChart.title'),
                data: $setup.callsStats.typePerDay
              }, null, 8 /* PROPS */, ["title", "data"])
            ]),
            _createElementVNode("div", _hoisted_12, [
              _createVNode($setup["CallAverageTimePerDayBarChart"], {
                title: _ctx.$t('report.call.callAverageTimesChart.title'),
                data: $setup.callsStats.tmPerDay
              }, null, 8 /* PROPS */, ["title", "data"])
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createVNode($setup["CallTimeOfDayHeatmapChart"], {
                title: _ctx.$t('report.call.callTimeOfDayHeatmapChart.title'),
                data: $setup.callsStats.timeOfDay
              }, null, 8 /* PROPS */, ["title", "data"])
            ]),
            _createElementVNode("div", _hoisted_14, [
              _createVNode($setup["CallServiceTimeRangesDoughnutChart"], {
                title: _ctx.$t('report.call.callServiceTimeRangesDoughnutChart.title'),
                data: $setup.callsStats.serviceTimeRange
              }, null, 8 /* PROPS */, ["title", "data"])
            ]),
            _createElementVNode("div", _hoisted_15, [
              _createVNode($setup["CallMediasDoughnutChart"], {
                title: _ctx.$t('report.call.callMediasDoughnutChart.title'),
                data: $setup.callsStats.media
              }, null, 8 /* PROPS */, ["title", "data"])
            ])
          ]))
        : _createCommentVNode("v-if", true)
    ])
  ], 64 /* STABLE_FRAGMENT */))
}