import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "text-xl font-semibold text-gray-900" }
const _hoisted_3 = { class: "grid grid-cols-1 md:grid-cols-3 gap-6 mb-10" }
const _hoisted_4 = {
  for: "level",
  class: "block text-sm font-medium leading-6 mb-2 text-gray-900"
}
const _hoisted_5 = { class: "block text-sm font-medium leading-6 mb-2 text-gray-900" }
const _hoisted_6 = { class: "grid grid-cols-2 md:grid-cols-7 gap-6 mb-10" }
const _hoisted_7 = { class: "grid grid-cols-1 md:grid-cols-3 gap-6 mb-10" }
const _hoisted_8 = { class: "flex justify-end px-4 sm:px-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["UiPageHeader"], null, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t("schedule." + $setup.props.action + ".title")), 1 /* TEXT */)
      ]),
      _: 1 /* STABLE */
    }),
    _createElementVNode("form", {
      class: "space-y-6",
      autocomplete: "off",
      onSubmit: _withModifiers($setup.onSubmit, ["prevent"])
    }, [
      _createVNode($setup["UiPanel"], null, {
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", null, [
              _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t("userLabel.labels.level")), 1 /* TEXT */),
              _createVNode($setup["UiMultiselect"], {
                name: "level",
                label: "name",
                "value-prop": "id",
                modelValue: $setup.formState.levelId,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.formState.levelId) = $event)),
                primitive: true,
                options: $setup.levels
              }, null, 8 /* PROPS */, ["modelValue", "options"])
            ]),
            _createVNode($setup["UiTextInput"], {
              modelValue: $setup.v$.name.$model,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.v$.name.$model) = $event)),
              name: "name",
              type: "text",
              label: _ctx.$t('schedule.labels.name'),
              errors: $setup.v$.name.$errors,
              onBlur: $setup.v$.name.$touch
            }, null, 8 /* PROPS */, ["modelValue", "label", "errors", "onBlur"])
          ]),
          _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.$t("schedule.labels.daysActive")), 1 /* TEXT */),
          _createElementVNode("div", _hoisted_6, [
            _createVNode($setup["UiCheckboxInput"], {
              modelValue: $setup.v$.monday.$model,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.v$.monday.$model) = $event)),
              name: "monday",
              label: _ctx.$t('schedule.labels.monday'),
              class: "mr-8"
            }, null, 8 /* PROPS */, ["modelValue", "label"]),
            _createVNode($setup["UiCheckboxInput"], {
              modelValue: $setup.v$.tuesday.$model,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.v$.tuesday.$model) = $event)),
              name: "tuesday",
              label: _ctx.$t('schedule.labels.tuesday'),
              class: "mr-8"
            }, null, 8 /* PROPS */, ["modelValue", "label"]),
            _createVNode($setup["UiCheckboxInput"], {
              modelValue: $setup.v$.wednesday.$model,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.v$.wednesday.$model) = $event)),
              name: "wednesday",
              label: _ctx.$t('schedule.labels.wednesday'),
              class: "mr-8"
            }, null, 8 /* PROPS */, ["modelValue", "label"]),
            _createVNode($setup["UiCheckboxInput"], {
              modelValue: $setup.v$.thursday.$model,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.v$.thursday.$model) = $event)),
              name: "thursday",
              label: _ctx.$t('schedule.labels.thursday'),
              class: "mr-8"
            }, null, 8 /* PROPS */, ["modelValue", "label"]),
            _createVNode($setup["UiCheckboxInput"], {
              modelValue: $setup.v$.friday.$model,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($setup.v$.friday.$model) = $event)),
              name: "friday",
              label: _ctx.$t('schedule.labels.friday'),
              class: "mr-8"
            }, null, 8 /* PROPS */, ["modelValue", "label"]),
            _createVNode($setup["UiCheckboxInput"], {
              modelValue: $setup.v$.saturday.$model,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($setup.v$.saturday.$model) = $event)),
              name: "saturday",
              label: _ctx.$t('schedule.labels.saturday'),
              class: "mr-8"
            }, null, 8 /* PROPS */, ["modelValue", "label"]),
            _createVNode($setup["UiCheckboxInput"], {
              modelValue: $setup.v$.sunday.$model,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (($setup.v$.sunday.$model) = $event)),
              name: "sunday",
              label: _ctx.$t('schedule.labels.sunday'),
              class: "mr-8"
            }, null, 8 /* PROPS */, ["modelValue", "label"])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode($setup["UiTimePicker"], {
              modelValue: $setup.v$.timeBegin.$model,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (($setup.v$.timeBegin.$model) = $event)),
              name: "initialHour",
              label: _ctx.$t('schedule.labels.timeBegin'),
              errors: $setup.v$.timeBegin.$errors,
              onBlur: $setup.v$.timeBegin.$touch
            }, null, 8 /* PROPS */, ["modelValue", "label", "errors", "onBlur"]),
            _createVNode($setup["UiTimePicker"], {
              modelValue: $setup.v$.timeEnd.$model,
              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => (($setup.v$.timeEnd.$model) = $event)),
              name: "finalHour",
              label: _ctx.$t('schedule.labels.timeEnd'),
              errors: $setup.v$.timeEnd.$errors,
              onBlur: $setup.v$.timeEnd.$touch
            }, null, 8 /* PROPS */, ["modelValue", "label", "errors", "onBlur"])
          ]),
          _createElementVNode("div", null, [
            _createVNode($setup["UiCheckboxInput"], {
              modelValue: $setup.v$.isActive.$model,
              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => (($setup.v$.isActive.$model) = $event)),
              name: "isActive",
              label: _ctx.$t('schedule.labels.isActive')
            }, null, 8 /* PROPS */, ["modelValue", "label"])
          ])
        ]),
        _: 1 /* STABLE */
      }),
      _createElementVNode("div", _hoisted_8, [
        _createVNode($setup["UiButton"], {
          variant: "white",
          "text-variant": "gray-500",
          onClick: $setup.handleBackClick
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Cancel")), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        (
            ($setup.props.action == $setup.FormAction.CREATE &&
              _ctx.$can($setup.Permission.CREATE_SCHEDULE)) ||
            ($setup.props.action == $setup.FormAction.EDIT && _ctx.$can($setup.Permission.EDIT_SCHEDULE))
          )
          ? (_openBlock(), _createBlock($setup["UiButton"], {
              key: 0,
              type: "submit",
              variant: "primary",
              "text-variant": "white",
              themed: "",
              class: "ml-4"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Save")), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true)
      ])
    ], 32 /* NEED_HYDRATION */),
    _createVNode($setup["UiCheckDirtyBeforeRouteLeave"], {
      dirty: $setup.v$.$anyDirty
    }, null, 8 /* PROPS */, ["dirty"])
  ]))
}