import { createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col items-center absolute top-5 left-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["UiNode"], {
    nodeId: $props.node.id,
    showActions: "",
    noEdition: "",
    onRemoveNode: $setup.removeNode,
    showHandleSource: false,
    hideNodeId: "",
    bgColor: "bg-gray-50",
    borderColor: "border-gray-300",
    roundedStyle: "rounded-2xl",
    padding: "p-0 pt-2 pr-2",
    nodeWidth: "w-24",
    nodeHeight: "h-20"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode($setup["PhoneMissedCallIcon"], { class: "h-10 w-10 text-gray-600 mb-1" })
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["nodeId"]))
}