import { createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-shrink-0 bg-black/20 p-4 shadow-md" }
const _hoisted_2 = {
  key: 0,
  class: "flex items-center"
}
const _hoisted_3 = { class: "ml-3" }
const _hoisted_4 = { class: "text-sm font-medium text-white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.user !== null)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", null, [
            _createVNode($setup["UiGravatar"], {
              email: $setup.user.email,
              name: $setup.user.name,
              class: "inline-block h-10 w-10 rounded-full"
            }, null, 8 /* PROPS */, ["email", "name"])
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("p", _hoisted_4, _toDisplayString($setup.user.name), 1 /* TEXT */)
          ])
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}