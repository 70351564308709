import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex flex-col flex-wrap sm:flex-row gap-2 pb-3 relative" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.words, (word, idx) => {
      return (_openBlock(), _createElementBlock(_Fragment, {
        key: `${$props.persona}-word-${word.text}`
      }, [
        (idx <= 10)
          ? (_openBlock(), _createBlock($setup["UiBadge"], {
              key: 0,
              class: "rounded-md cursor-pointer",
              variant: 
          word.selected ? 'orange' : word.registered ? 'purple' : 'gray'
        ,
              border: word.selected,
              onClick: ($event: any) => ($setup.searchWord(word))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(word.text), 1 /* TEXT */)
              ]),
              number: _withCtx(() => [
                _createTextVNode(_toDisplayString(word.counter), 1 /* TEXT */)
              ]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["variant", "border", "onClick"]))
          : _createCommentVNode("v-if", true),
        (idx > 10)
          ? (_openBlock(), _createBlock($setup["UiBadge"], {
              key: 1,
              class: _normalizeClass(["rounded-md cursor-pointer", { hidden: $setup.hiddenItems }]),
              onClick: ($event: any) => ($setup.searchWord(word)),
              variant: 
          word.selected ? 'orange' : word.registered ? 'purple' : 'gray'
        ,
              border: word.selected
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(word.text), 1 /* TEXT */)
              ]),
              number: _withCtx(() => [
                _createTextVNode(_toDisplayString(word.counter), 1 /* TEXT */)
              ]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["class", "onClick", "variant", "border"]))
          : _createCommentVNode("v-if", true)
      ], 64 /* STABLE_FRAGMENT */))
    }), 128 /* KEYED_FRAGMENT */)),
    ($props.words.length > 10)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "p-2 text-sm text-orange-600 cursor-pointer hover:text-orange-800 right-5 bottom-0 absolute",
          onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.toggleItems()))
        }, [
          ($setup.hiddenItems)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$t("core.actions.SeeMore")), 1 /* TEXT */))
            : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t("core.actions.SeeLess")), 1 /* TEXT */))
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}