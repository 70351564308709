import { createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "text-xl font-semibold text-gray-900 flex items-center" }
const _hoisted_3 = { class: "-mx-4 mt-5 overflow-hidden bg-white shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg" }
const _hoisted_4 = {
  class: "flex flex-1 px-4 sm:px-6 h-16 border-b border-gray-200 bg-white shadow-sm",
  action: "#",
  method: "GET"
}
const _hoisted_5 = { class: "max-w-md min-w-full overflow-x-auto" }
const _hoisted_6 = { class: "min-w-full" }
const _hoisted_7 = { class: "bg-gray-50" }
const _hoisted_8 = {
  scope: "col",
  class: "py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
}
const _hoisted_9 = {
  scope: "col",
  class: "py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900"
}
const _hoisted_10 = { class: "divide-y divide-gray-200 bg-white" }
const _hoisted_11 = { class: "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6" }
const _hoisted_12 = { class: "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900" }
const _hoisted_13 = ["checked", "onChange"]
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { class: "whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_tippy = _resolveDirective("tippy")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["UiPageHeader"], null, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, [
          _createVNode($setup["LockClosedIcon"], {
            class: "w-6 h-6 mr-1 text-gray-500",
            "aria-hidden": "true"
          }),
          _createTextVNode(" " + _toDisplayString(_ctx.$t("core.entity.Permissions")), 1 /* TEXT */)
        ])
      ]),
      actions: _withCtx(() => [
        (_ctx.$can($setup.Permission.CREATE_ROLE))
          ? (_openBlock(), _createBlock($setup["RouterLink"], {
              key: 0,
              to: {
            name: 'CreateRole',
          }
            }, {
              default: _withCtx(() => [
                _createVNode($setup["UiButton"], {
                  variant: "primary",
                  "text-variant": "white",
                  themed: ""
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("permission.actions.newRole")), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    }),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("form", _hoisted_4, [
        _createVNode($setup["UiListSearch"], {
          modelValue: $setup.search,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.search) = $event))
        }, null, 8 /* PROPS */, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("table", _hoisted_6, [
          _createElementVNode("thead", _hoisted_7, [
            _createElementVNode("tr", null, [
              _createElementVNode("th", _hoisted_8, _toDisplayString(_ctx.$t("permission.labels.module")), 1 /* TEXT */),
              _createElementVNode("th", _hoisted_9, _toDisplayString(_ctx.$t("permission.labels.name")), 1 /* TEXT */),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.roles, (role) => {
                return (_openBlock(), _createElementBlock("th", {
                  key: role.id,
                  scope: "col",
                  class: "px-3 py-3.5 text-center text-sm font-semibold text-gray-900"
                }, [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.$can($setup.Permission.VIEW_ROLE) ? $setup.RouterLink : 'span'), {
                    to: {
                    name: 'ViewRoleById',
                    params: {
                      id: role.id,
                    },
                  },
                    class: "truncate text-sm font-medium text-primary inline-flex items-center"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(role.name) + " ", 1 /* TEXT */),
                      (_ctx.$can($setup.Permission.VIEW_ROLE))
                        ? (_openBlock(), _createBlock($setup["PencilIcon"], {
                            key: 0,
                            class: "ml-1 h-4 w-4",
                            "aria-hidden": "true"
                          }))
                        : _createCommentVNode("v-if", true)
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"]))
                ]))
              }), 128 /* KEYED_FRAGMENT */))
            ])
          ]),
          _createElementVNode("tbody", _hoisted_10, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.paginatedPermissions, (permission) => {
              return (_openBlock(), _createElementBlock("tr", {
                key: permission.id
              }, [
                _createElementVNode("td", _hoisted_11, _toDisplayString(_ctx.$t(`permission.modules.${permission.module}`)), 1 /* TEXT */),
                _createElementVNode("td", _hoisted_12, _toDisplayString(permission.description), 1 /* TEXT */),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.roles, (role) => {
                  return (_openBlock(), _createElementBlock("td", {
                    key: role.id,
                    class: "whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center"
                  }, [
                    _withDirectives(_createElementVNode("input", {
                      type: "checkbox",
                      checked: 
                    permission.roles.find((r) => r.id == role.id)
                      ? true
                      : false
                  ,
                      onChange: ($event: any) => ($setup.handleAddRemovePermissionClick(role, permission))
                    }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_13), [
                      [_directive_tippy, {
                    content: permission.description,
                    placement: 'left',
                  }]
                    ])
                  ]))
                }), 128 /* KEYED_FRAGMENT */))
              ]))
            }), 128 /* KEYED_FRAGMENT */)),
            (!$setup.paginatedPermissions.length)
              ? (_openBlock(), _createElementBlock("tr", _hoisted_14, [
                  _createElementVNode("td", _hoisted_15, _toDisplayString(_ctx.$t("core.messages.noRecordsFound")), 1 /* TEXT */)
                ]))
              : _createCommentVNode("v-if", true)
          ])
        ])
      ]),
      _createVNode($setup["UiPagination"], {
        modelValue: $setup.currentPage,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.currentPage) = $event)),
        count: $setup.filteredPermissions.length,
        perPage: $setup.perPage,
        i18n: _ctx.$t,
        class: "h-16 border-t border-gray-200 bg-white shadow-sm px-4 sm:px-6"
      }, null, 8 /* PROPS */, ["modelValue", "count", "i18n"])
    ])
  ]))
}