import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "text-xl font-semibold text-gray-900" }
const _hoisted_3 = { class: "mt-2 text-sm text-gray-700" }
const _hoisted_4 = { class: "grid sm:grid-cols-8 grid-cols-1 gap-4" }
const _hoisted_5 = { class: "col-span-4" }
const _hoisted_6 = {
  for: "level",
  class: "block text-sm font-medium leading-6 mb-2 text-gray-900"
}
const _hoisted_7 = { class: "col-span-3" }
const _hoisted_8 = {
  for: "isActive",
  class: "block text-sm font-medium leading-6 mb-2 text-gray-900"
}
const _hoisted_9 = { class: "col-span-3 h-full flex flex-col" }
const _hoisted_10 = {
  for: "level",
  class: "block text-sm font-medium leading-6 mb-2 text-gray-900"
}
const _hoisted_11 = {
  key: 0,
  class: "col-span-3"
}
const _hoisted_12 = {
  controls: "",
  class: "w-full"
}
const _hoisted_13 = ["src", "type"]
const _hoisted_14 = { class: "text-lg font-semibold text-gray-900 mb-4" }
const _hoisted_15 = {
  for: "addQuestion",
  class: "block text-sm font-medium leading-6 mb-2 text-red-500"
}
const _hoisted_16 = { class: "w-full relative h-10" }
const _hoisted_17 = { class: "flex justify-end px-4 sm:px-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["UiPageHeader"], null, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t("survey." + $setup.props.action + ".title")), 1 /* TEXT */),
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("survey." + $setup.props.action + ".description")), 1 /* TEXT */)
      ]),
      _: 1 /* STABLE */
    }),
    _createElementVNode("form", {
      class: "space-y-6",
      autocomplete: "off",
      onSubmit: _withModifiers($setup.onSubmit, ["prevent"])
    }, [
      _createVNode($setup["UiPanel"], null, {
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t("userLabel.labels.level")), 1 /* TEXT */),
              _createVNode($setup["UiMultiselect"], {
                name: "level",
                label: "name",
                "value-prop": "id",
                modelValue: $setup.formState.levelId,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.formState.levelId) = $event)),
                primitive: true,
                options: $setup.levels,
                disabled: $setup.formState.questions.length > 0
              }, null, 8 /* PROPS */, ["modelValue", "options", "disabled"])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createVNode($setup["UiTextInput"], {
                name: "surveyName",
                modelValue: $setup.v$.name.$model,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.v$.name.$model) = $event)),
                type: "text",
                label: _ctx.$t('survey.labels.name'),
                errors: $setup.v$.name.$errors,
                onBlur: $setup.v$.name.$touch
              }, null, 8 /* PROPS */, ["modelValue", "label", "errors", "onBlur"])
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t("survey.labels.isActive")), 1 /* TEXT */),
              _createVNode($setup["UiCheckboxInput"], {
                modelValue: $setup.formState.isActive,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.formState.isActive) = $event)),
                name: "isActive",
                label: _ctx.$t('survey.labels.isActive')
              }, null, 8 /* PROPS */, ["modelValue", "label"])
            ]),
            _createVNode($setup["UiTextArea"], {
              id: "textToAudio",
              name: "textToAudio",
              label: _ctx.$t('survey.labels.text'),
              placeholder: _ctx.$t('survey.labels.questionLabel'),
              disabled: $setup.isTextAreaDisabled,
              errors: $setup.v$.textToAudio.$errors,
              modelValue: $setup.formState.textToAudio,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.formState.textToAudio) = $event)),
              class: "col-span-5"
            }, null, 8 /* PROPS */, ["label", "placeholder", "disabled", "errors", "modelValue"]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t("survey.view.section.questions.audio")), 1 /* TEXT */),
              _createVNode($setup["UiAudioUpload"], {
                id: "audioUpload",
                disabled: ($setup.formState.textToAudio?.length ?? 0) > 0,
                class: "flex-grow",
                errors: $setup.v$.audio.$errors,
                "text-rule": _ctx.$t('connectFlowAudio.create.uploadRules'),
                "onUpdate:file": $setup.handleFileUpdate
              }, null, 8 /* PROPS */, ["disabled", "errors", "text-rule"])
            ]),
            ($setup.audioData)
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                  _createElementVNode("audio", _hoisted_12, [
                    _createElementVNode("source", {
                      src: $setup.audioData,
                      type: $setup.getAudioFormat($setup.formState.audioFilePath || '')
                    }, null, 8 /* PROPS */, _hoisted_13)
                  ])
                ]))
              : _createCommentVNode("v-if", true)
          ])
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode($setup["UiPanel"], null, {
        content: _withCtx(() => [
          _createElementVNode("h2", _hoisted_14, _toDisplayString(_ctx.$t("survey.create.section.questions.title")), 1 /* TEXT */),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.formState.questions, (item, index) => {
            return (_openBlock(), _createBlock($setup["SurveyFormQuestion"], {
              key: item.uuid,
              index: index,
              "show-remove": $setup.formState.questions.length > 1,
              modelValue: $setup.formState.questions[index],
              "onUpdate:modelValue": ($event: any) => (($setup.formState.questions[index]) = $event),
              onRemove: () => $setup.removeQuestion(index)
            }, null, 8 /* PROPS */, ["index", "show-remove", "modelValue", "onUpdate:modelValue", "onRemove"]))
          }), 128 /* KEYED_FRAGMENT */)),
          _createElementVNode("label", _hoisted_15, _toDisplayString($setup.v$.questions?.$errors[0]?.$message), 1 /* TEXT */),
          _createElementVNode("div", _hoisted_16, [
            _createVNode($setup["UiButton"], {
              name: "addQuestion",
              variant: "white",
              "text-variant": "gray-500",
              class: "absolute right-0",
              onClick: $setup.addQuestion,
              disabled: !$setup.formState.levelId
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("survey.create.section.questions.addQuestion")), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["disabled"])
          ])
        ]),
        _: 1 /* STABLE */
      }),
      _createElementVNode("div", _hoisted_17, [
        _createVNode($setup["UiButton"], {
          variant: "white",
          "text-variant": "gray-500",
          to: { name: 'ListSurvey' }
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Cancel")), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        (
            ($setup.props.action == $setup.FormAction.CREATE &&
              _ctx.$can($setup.Permission.CREATE_SURVEY)) ||
            ($setup.props.action == $setup.FormAction.EDIT && _ctx.$can($setup.Permission.EDIT_SURVEY))
          )
          ? (_openBlock(), _createBlock($setup["UiButton"], {
              key: 0,
              type: "submit",
              variant: "primary",
              "text-variant": "white",
              themed: "",
              class: "ml-4"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Save")), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true)
      ])
    ], 32 /* NEED_HYDRATION */),
    _createVNode($setup["UiCheckDirtyBeforeRouteLeave"], {
      dirty: $setup.v$.$anyDirty
    }, null, 8 /* PROPS */, ["dirty"])
  ]))
}