import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "text-xl font-semibold text-gray-900" }
const _hoisted_3 = { class: "mt-2 text-sm text-gray-700" }
const _hoisted_4 = { class: "space-y-6" }
const _hoisted_5 = { class: "text-base font-semibold leading-6 text-gray-900" }
const _hoisted_6 = { class: "mt-1 text-sm text-gray-500" }
const _hoisted_7 = { class: "bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6" }
const _hoisted_8 = { class: "text-sm font-medium text-gray-500" }
const _hoisted_9 = { class: "mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0" }
const _hoisted_10 = { class: "flex justify-end px-4 sm:px-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["UiPageHeader"], null, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t("role.view.title")), 1 /* TEXT */),
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("role.view.description")), 1 /* TEXT */)
      ]),
      actions: _withCtx(() => [
        (_ctx.$can($setup.Permission.EDIT_ROLE))
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 0,
              to: {
            name: 'EditRoleById',
            params: {
              id: $setup.route.params.id,
            },
          },
              class: "block hover:bg-gray-50"
            }, {
              default: _withCtx(() => [
                _createVNode($setup["UiButton"], {
                  variant: "primary",
                  "text-variant": "white",
                  themed: ""
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Edit")), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["to"]))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    }),
    _createElementVNode("div", _hoisted_4, [
      _createVNode($setup["UiContainerSection"], null, {
        title: _withCtx(() => [
          _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.$t("role.view.section.title")), 1 /* TEXT */),
          _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t("role.view.section.description")), 1 /* TEXT */)
        ]),
        content: _withCtx(() => [
          _createElementVNode("dl", null, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("dt", _hoisted_8, _toDisplayString(_ctx.$t("role.labels.name")), 1 /* TEXT */),
              _createElementVNode("dd", _hoisted_9, _toDisplayString($setup.role?.name), 1 /* TEXT */)
            ])
          ])
        ]),
        _: 1 /* STABLE */
      }),
      _createElementVNode("div", _hoisted_10, [
        _createVNode($setup["UiButton"], {
          variant: "white",
          "text-variant": "gray-500",
          onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.goBack({ name: 'Permissions' })))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Back")), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        })
      ])
    ])
  ]))
}