<script setup>
import { CheckCircleIcon } from "@heroicons/vue/solid";

defineProps({
  isActive: {
    type: Boolean,
  },
  logoPath: {
    type: String,
    required: true,
  },
  contentText: {
    type: String,
    required: true,
  },
});
const emit = defineEmits(["configClick", "viewLogsClick", "activateClick"]);

const configClick = () => {
  emit("configClick");
};

const viewLogsClick = () => {
  emit("viewLogsClick");
};

const activateClick = () => {
  emit("activateClick");
};
</script>

<template>
  <div class="bg-white rounded-lg shadow-md p-4">
    <div class="text-right">
      <CheckCircleIcon
        class="inline-block h-6 w-6"
        :class="[isActive ? 'text-secondary' : 'text-slate-300']"
        aria-hidden="true"
      />
    </div>
    <img :src="logoPath" :alt="contentText" class="h-12 mx-auto mb-4" />
    <p class="text-gray-600 text-center mb-4">
      {{ contentText }}
    </p>
    <div class="flex space-x-4">
      <button v-if="isActive != undefined" class="button" @click="configClick">
        {{ $t("integration.labels.config") }}
      </button>
      <button
        v-if="isActive != undefined"
        class="button"
        @click="viewLogsClick"
      >
        {{ $t("integration.labels.viewlogs") }}
      </button>
      <button v-else class="button" @click="activateClick">
        {{ $t("integration.labels.activate") }}
      </button>
    </div>
  </div>
</template>

<style scoped>
.button {
  @apply flex-1 flex justify-center px-3 py-2 rounded-md shadow-sm border focus:ring-2;
}
</style>
