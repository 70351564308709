import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "block hover:bg-gray-50" }
const _hoisted_2 = { class: "px-4 py-4 sm:px-6" }
const _hoisted_3 = { class: "flex items-center" }
const _hoisted_4 = { class: "flex-1" }
const _hoisted_5 = {
  key: 0,
  class: "flex"
}
const _hoisted_6 = { class: "sr-only" }
const _hoisted_7 = { class: "sr-only" }
const _hoisted_8 = { class: "sr-only" }
const _hoisted_9 = { class: "sr-only" }
const _hoisted_10 = { class: "sr-only" }
const _hoisted_11 = { class: "mt-2 sm:flex sm:justify-between" }
const _hoisted_12 = { class: "sm:flex" }
const _hoisted_13 = { class: "flex items-center text-sm text-gray-500 mr-4" }
const _hoisted_14 = {
  key: 0,
  class: "mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6"
}
const _hoisted_15 = {
  key: 0,
  class: "mt-2 flex items-center text-sm text-gray-500 sm:mt-0"
}
const _hoisted_16 = ["datetime"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_tippy = _resolveDirective("tippy")!

  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.$can($setup.Permission.VIEW_CONNECT_FLOW) ? 'RouterLink' : 'span'), {
            to: {
              name: 'ViewConnectFlowById',
              params: {
                id: $setup.props.modelValue?.id,
              },
            },
            class: "truncate text-sm font-medium text-primary"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($setup.props.modelValue.name), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["to"])),
          _createElementVNode("span", {
            class: _normalizeClass([[
              $setup.props.modelValue?.isActive
                ? 'bg-green-100 text-green-800'
                : 'bg-red-100 text-red-800',
            ], "inline-flex ml-2 rounded-full px-2 text-xs font-semibold leading-5"])
          }, _toDisplayString($setup.props.modelValue.isActive
                ? _ctx.$t("core.options.Active")
                : _ctx.$t("core.options.Inactive")), 3 /* TEXT, CLASS */)
        ]),
        (_ctx.$can($setup.Permission.EDIT_CONNECT_FLOW))
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              ($setup.props.modelValue?.isActive)
                ? _withDirectives((_openBlock(), _createBlock($setup["UiButton"], {
                    key: 0,
                    size: "",
                    shape: "rounded-full",
                    variant: "inherit",
                    "text-variant": "danger",
                    shadow: "none",
                    themed: "",
                    class: "border-transparent focus:ring-danger shadow-none text-xs",
                    onClick: $setup.handleInactivateClick
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t("core.actions.Inactivate")), 1 /* TEXT */),
                      _createVNode($setup["XCircleIcon"], {
                        class: "h-6 w-6",
                        "aria-hidden": "true"
                      })
                    ]),
                    _: 1 /* STABLE */
                  })), [
                    [_directive_tippy, _ctx.$t('core.actions.Inactivate')]
                  ])
                : _withDirectives((_openBlock(), _createBlock($setup["UiButton"], {
                    key: 1,
                    size: "",
                    shape: "rounded-full",
                    variant: "inherit",
                    "text-variant": "success",
                    shadow: "none",
                    themed: "",
                    class: "border-transparent focus:ring-success shadow-none text-xs",
                    onClick: $setup.handleActivateClick
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t("core.actions.Activate")), 1 /* TEXT */),
                      _createVNode($setup["CheckCircleIcon"], {
                        class: "h-6 w-6",
                        "aria-hidden": "true"
                      })
                    ]),
                    _: 1 /* STABLE */
                  })), [
                    [_directive_tippy, _ctx.$t('core.actions.Activate')]
                  ]),
              _withDirectives((_openBlock(), _createBlock($setup["UiButton"], {
                size: "",
                shape: "rounded-full",
                variant: "inherit",
                "text-variant": "primary",
                shadow: "none",
                themed: "",
                class: "border-transparent focus:ring-primary shadow-none ml-1 text-xs",
                to: {
              name: 'EditConnectFlowById',
              params: {
                id: $props.modelValue.id,
              },
            }
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t("core.actions.Edit")), 1 /* TEXT */),
                  _createVNode($setup["PencilAltIcon"], {
                    class: "h-6 w-6",
                    "aria-hidden": "true"
                  })
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["to"])), [
                [_directive_tippy, _ctx.$t('core.actions.Edit')]
              ]),
              _createVNode($setup["UiButton"], {
                size: "",
                shape: "rounded-full",
                variant: "inherit",
                "text-variant": "primary",
                shadow: "none",
                themed: "",
                class: "border-transparent focus:ring-primary shadow-none ml-1 text-xs",
                to: {
              name: 'ModelingConnectFlow',
              params: {
                id: $props.modelValue.id,
              },
            }
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t("connectFlow.actions.modeling")), 1 /* TEXT */),
                  _createVNode($setup["ConnectFlowIcon"], {
                    class: "h-6 w-6",
                    "aria-hidden": "true"
                  })
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["to"]),
              _createVNode($setup["UiButton"], {
                size: "",
                shape: "rounded-full",
                variant: "inherit",
                "text-variant": "primary",
                shadow: "none",
                themed: "",
                class: "border-transparent focus:ring-primary shadow-none ml-1 text-xs",
                to: {
              name: 'HistoryConnectFlow',
              params: {
                id: $props.modelValue.id,
              },
            }
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t("connectFlow.actions.modeling")), 1 /* TEXT */),
                  _createVNode($setup["ArchiveIcon"], {
                    class: "h-6 w-6",
                    "aria-hidden": "true"
                  })
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["to"])
            ]))
          : _createCommentVNode("v-if", true)
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("p", _hoisted_13, [
            _createVNode($setup["FolderIcon"], {
              class: "mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400",
              "aria-hidden": "true"
            }),
            _createTextVNode(" " + _toDisplayString($props.modelValue.level.name), 1 /* TEXT */)
          ]),
          ($props.modelValue.number)
            ? (_openBlock(), _createElementBlock("p", _hoisted_14, [
                _createVNode($setup["PhoneIcon"], {
                  class: "mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400",
                  "aria-hidden": "true"
                }),
                (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.$can($setup.Permission.VIEW_NUMBER) ? 'RouterLink' : 'span'), {
                  to: {
                name: 'ViewNumberById',
                params: {
                  id: $props.modelValue.number.id,
                },
              },
                  class: "truncate text-sm font-medium text-primary"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString($setup.applyPhoneMask($props.modelValue.number.number)), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["to"]))
              ]))
            : _createCommentVNode("v-if", true)
        ]),
        ($setup.lastModifiedBy != null)
          ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
              _createVNode($setup["CalendarIcon"], {
                class: "mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400",
                "aria-hidden": "true"
              }),
              _createElementVNode("p", null, [
                _createTextVNode(_toDisplayString($setup.props.modelValue.updatedByUser
                ? _ctx.$t("connectFlow.list.updatedAt")
                : _ctx.$t("connectFlow.list.createdAt")) + " ", 1 /* TEXT */),
                _createElementVNode("time", {
                  datetime: 
                $setup.props.modelValue?.updatedAt || $setup.props.modelValue?.createdAt
              
                }, _toDisplayString($setup.formatDateAsString(
                  $setup.props.modelValue.updatedAt || $setup.props.modelValue.createdAt,
                  "LL",
                )), 9 /* TEXT, PROPS */, _hoisted_16),
                _createTextVNode(" " + _toDisplayString(_ctx.$t("connectFlow.list.by")) + " ", 1 /* TEXT */),
                (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.$can($setup.Permission.VIEW_USER) ? 'RouterLink' : 'span'), {
                  to: {
                name: 'ViewUserById',
                params: {
                  id: $setup.lastModifiedBy.id,
                },
              },
                  class: "truncate text-sm font-medium text-primary"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString($setup.lastModifiedBy.name), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["to"]))
              ])
            ]))
          : _createCommentVNode("v-if", true)
      ])
    ])
  ]))
}