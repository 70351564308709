import { createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  autocomplete: "off",
  class: "flex flex-col gap-3"
}
const _hoisted_2 = { class: "grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6" }
const _hoisted_3 = { class: "sm:col-span-3" }
const _hoisted_4 = {
  for: "filter_status",
  class: "block text-sm font-medium leading-6 text-gray-900"
}
const _hoisted_5 = { class: "grid grid-cols-2 mt-5 gap-4 place-content-around" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["UiRightPanel"], {
    modelValue: $setup.filterOpened,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.filterOpened) = $event)),
    title: _ctx.$t('filter.sidebar.title')
  }, {
    button: _withCtx(() => [
      _createVNode($setup["UiButton"], {
        size: "md",
        variant: "white",
        "text-variant": "gray-700",
        class: "border border-gray-300 hover:bg-gray-50 focus:ring-primary shadow-none mr-1",
        onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.filterOpened = true))
      }, {
        default: _withCtx(() => [
          _createVNode($setup["FilterIcon"], {
            class: "mr-3 h-5 w-5 text-gray-400",
            "aria-hidden": "true"
          }),
          _createTextVNode(" " + _toDisplayString(_ctx.$t("core.actions.Filter")), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      })
    ]),
    default: _withCtx(() => [
      _createElementVNode("form", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t("integration.logs.filters.status.label")), 1 /* TEXT */),
            _createVNode($setup["UiMultiselect"], {
              name: "filter_status",
              modelValue: $setup.filter.status,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.filter.status) = $event)),
              options: $setup.statusFilterOptions,
              placeholder: $setup.t('core.options.All'),
              "can-clear": ""
            }, null, 8 /* PROPS */, ["modelValue", "placeholder"])
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode($setup["UiButton"], {
            variant: "white",
            "text-variant": "gray-700",
            themed: "",
            onClick: _cache[2] || (_cache[2] = ($event: any) => ($setup.clearFilters()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Clear")), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode($setup["UiButton"], {
            variant: "primary",
            "text-variant": "white",
            themed: "",
            onClick: _cache[3] || (_cache[3] = ($event: any) => ($setup.applyFilters()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Apply")), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          })
        ])
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "title"]))
}