import {
  IListable,
  IMultiSelectOption,
  IUser,
  IUserLabel,
  IWord,
} from "@/definitions";

export interface ICallFilter {
  type: IMultiSelectOption<string> | null;
  status: IMultiSelectOption<string> | null;
  userLabel: IUserLabel | null;
  media: IMultiSelectOption<string> | null;
  numberClient: IMultiSelectOption<string> | null;
  user: IUser | null;
  word: IWord | null;
}

export interface ICallState extends IListable {
  filter: ICallFilter;
}

export enum CallMutations {
  SET_SEARCH = "call_setSearch",
  SET_SORT = "call_setSort",
  SET_PAGE = "call_setPage",
  SET_FILTER = "call_setFilter",
  CLEAR_FILTER = "call_clearFilter",
}

export enum CallActions {
  SET_SEARCH = "call_setSearch",
  SET_SORT = "call_setSort",
  SET_PAGE = "call_setPage",
  SET_FILTER = "call_setFilter",
  CLEAR_FILTER = "call_clearFilter",
}

export enum CallGetters {
  SEARCH = "call_search",
  SORT = "call_sort",
  PAGE = "call_page",
  FILTER = "call_filter",
  DEFAULT_FILTER = "call_defaultFilter",
  ACTIVE_FILTERS = "call_activeFilters",
}
