import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "text-xl font-semibold text-gray-900" }
const _hoisted_3 = { class: "grid grid-cols-1 md:grid-cols-3 gap-6" }
const _hoisted_4 = {
  for: "level",
  class: "block text-sm font-medium leading-6 mb-2 text-gray-900"
}
const _hoisted_5 = { class: "" }
const _hoisted_6 = {
  for: "users",
  class: "block text-sm font-medium leading-6 mb-2 text-gray-900"
}
const _hoisted_7 = { class: "flex flex-col justify-end pb-1.5" }
const _hoisted_8 = { class: "flex justify-end px-4 sm:px-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["UiPageHeader"], null, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t("userLabel." + $setup.props.action + ".title")), 1 /* TEXT */)
      ]),
      _: 1 /* STABLE */
    }),
    _createElementVNode("form", {
      class: "space-y-6",
      autocomplete: "off",
      onSubmit: _withModifiers($setup.onSubmit, ["prevent"])
    }, [
      _createVNode($setup["UiPanel"], null, {
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", null, [
              _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t("userLabel.labels.level")), 1 /* TEXT */),
              _createVNode($setup["UiMultiselect"], {
                name: "level",
                label: "name",
                "value-prop": "id",
                modelValue: $setup.formState.levelId,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.formState.levelId) = $event)),
                primitive: true,
                options: $setup.levels
              }, null, 8 /* PROPS */, ["modelValue", "options"])
            ]),
            _createVNode($setup["UiTextInput"], {
              modelValue: $setup.v$.label.$model,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.v$.label.$model) = $event)),
              name: "label",
              type: "text",
              label: _ctx.$t('userLabel.labels.label'),
              errors: $setup.v$.label.$errors,
              onBlur: $setup.v$.label.$touch
            }, null, 8 /* PROPS */, ["modelValue", "label", "errors", "onBlur"]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t("userLabel.labels.users")), 1 /* TEXT */),
              _createVNode($setup["UiMultiselect"], {
                id: "users",
                name: "users",
                label: "name",
                "value-prop": "id",
                modelValue: $setup.formState.users,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.formState.users) = $event)),
                multiple: "",
                primitive: true,
                options: $setup.users
              }, null, 8 /* PROPS */, ["modelValue", "options"])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createVNode($setup["UiCheckboxInput"], {
                modelValue: $setup.v$.isActive.$model,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.v$.isActive.$model) = $event)),
                name: "isActive",
                label: _ctx.$t('userLabel.labels.isActive'),
                class: "h-6"
              }, null, 8 /* PROPS */, ["modelValue", "label"])
            ])
          ])
        ]),
        _: 1 /* STABLE */
      }),
      _createElementVNode("div", _hoisted_8, [
        _createVNode($setup["UiButton"], {
          variant: "white",
          size: "xl",
          "text-variant": "gray-500",
          onClick: $setup.handleBackClick
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Cancel")), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        (
            ($setup.props.action == $setup.FormAction.CREATE &&
              _ctx.$can($setup.Permission.CREATE_USER_LABEL)) ||
            ($setup.props.action == $setup.FormAction.EDIT &&
              _ctx.$can($setup.Permission.EDIT_USER_LABEL))
          )
          ? (_openBlock(), _createBlock($setup["UiButton"], {
              key: 0,
              type: "submit",
              variant: "primary",
              size: "xl",
              "text-variant": "white",
              themed: "",
              class: "ml-4"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Save")), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true)
      ])
    ], 32 /* NEED_HYDRATION */),
    _createVNode($setup["UiCheckDirtyBeforeRouteLeave"], {
      dirty: $setup.v$.$anyDirty
    }, null, 8 /* PROPS */, ["dirty"])
  ]))
}