import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "relative w-full h-full" }
const _hoisted_2 = { class: "invisible group-hover:visible absolute flex flex-col bg-white border top-7 border-gray-200 rounded-md px-2 -ml-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.timeline, (bullet) => {
    return (_openBlock(), _createElementBlock("div", {
      key: `bulletAttendant-${bullet.id}`,
      class: _normalizeClass(["rounded-full absolute group h-3 w-3 hover:transition-all hover:bg-white hover:h-6 hover:w-6 p-0 hover:-mx-1", $setup.insideColors[$props.type].bullet]),
      style: _normalizeStyle({ left: $setup.getPositionBullet(bullet) })
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode($setup["PlayIcon"], {
          class: _normalizeClass(["invisible group-hover:visible w-full h-full cursor-pointer", $setup.insideColors[$props.type].play]),
          onClick: ($event: any) => ($setup.playSnippet(bullet.startedAt, bullet.endedAt))
        }, null, 8 /* PROPS */, ["class", "onClick"]),
        _createElementVNode("div", _hoisted_2, [
          ($props.type === 'words')
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(bullet.registeredWords, (word) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: `bulletAttendantWords${bullet.id}${word}`,
                  class: "whitespace-nowrap z-50 text-gray-700"
                }, _toDisplayString(word), 1 /* TEXT */))
              }), 128 /* KEYED_FRAGMENT */))
            : _createCommentVNode("v-if", true),
          ($props.type === 'insights')
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(bullet.insights, (insight) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: `bulletAttendantWords${bullet.id}${insight.id}`,
                  class: "whitespace-nowrap z-50 text-gray-700"
                }, _toDisplayString(insight.value), 1 /* TEXT */))
              }), 128 /* KEYED_FRAGMENT */))
            : _createCommentVNode("v-if", true),
          ($props.type === 'search')
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 2 }, _renderList(bullet.matchedWords, (word) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: `bulletAttendantWords${bullet.id}${word.text}`,
                  class: "whitespace-nowrap z-50 text-gray-700"
                }, _toDisplayString(word.text), 1 /* TEXT */))
              }), 128 /* KEYED_FRAGMENT */))
            : _createCommentVNode("v-if", true)
        ])
      ])
    ], 6 /* CLASS, STYLE */))
  }), 128 /* KEYED_FRAGMENT */))
}