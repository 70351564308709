import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["type", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return ($props.to === undefined)
    ? (_openBlock(), _createElementBlock("button", {
        key: 0,
        type: $props.htmlType,
        class: _normalizeClass(["border flex justify-center font-medium focus:outline-none focus:ring-2 disabled", $setup.classes]),
        disabled: $setup.props.disabled
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 10 /* CLASS, PROPS */, _hoisted_1))
    : (_openBlock(), _createBlock(_component_router_link, {
        key: 1,
        to: $props.to,
        class: _normalizeClass(["border flex justify-center font-medium focus:outline-none focus:ring-2", $setup.classes])
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3 /* FORWARDED */
      }, 8 /* PROPS */, ["to", "class"]))
}