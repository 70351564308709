import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createTextVNode as _createTextVNode, withModifiers as _withModifiers, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "font-medium text-gray-600" }
const _hoisted_2 = { class: "sm:col-span-12" }
const _hoisted_3 = { class: "block text-sm font-medium leading-6 text-gray-900 mb-2" }
const _hoisted_4 = { class: "grid grid-cols-2 mt-7 gap-4 place-content-around text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode($setup["UiNode"], {
      nodeId: $props.node.id,
      title: _ctx.$t('connectFlow.node.clientInfo.title'),
      icon: $setup.QuestionMarkCircleIcon,
      showActions: "",
      onEditNode: _cache[0] || (_cache[0] = ($event: any) => ($setup.showForm = true)),
      onRemoveNode: $setup.removeNode,
      class: _normalizeClass($setup.isFormCorrect ? '' : 'shadow-red-300 border-red-300')
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("span", null, _toDisplayString($setup.nodeText), 1 /* TEXT */)
        ]),
        _createVNode($setup["Handle"], {
          id: "timeout",
          type: "target",
          position: $setup.Position.Left,
          connectable: false,
          style: {"top":"45px"}
        }, null, 8 /* PROPS */, ["position"]),
        _createVNode($setup["Handle"], {
          id: "timeout",
          type: "source",
          connectable: false,
          position: $setup.Position.Left,
          style: {"top":"35px"}
        }, null, 8 /* PROPS */, ["position"]),
        _createVNode($setup["Handle"], {
          id: "invalid",
          type: "target",
          connectable: false,
          position: $setup.Position.Left,
          style: {"top":"auto","bottom":"25px"}
        }, null, 8 /* PROPS */, ["position"]),
        _createVNode($setup["Handle"], {
          id: "invalid",
          type: "source",
          position: $setup.Position.Left,
          connectable: false,
          style: {"top":"auto","bottom":"35px"}
        }, null, 8 /* PROPS */, ["position"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["nodeId", "title", "icon", "class"]),
    _createVNode($setup["UiRightPanel"], {
      modelValue: $setup.showForm,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.showForm) = $event)),
      title: _ctx.$t('connectFlow.node.clientInfo.title'),
      icon: $setup.QuestionMarkCircleIcon
    }, {
      default: _withCtx(() => [
        _createElementVNode("form", {
          autocomplete: "off",
          class: "flex flex-col gap-3",
          onSubmit: _withModifiers($setup.onSubmit, ["prevent"])
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t("connectFlow.node.clientInfo.select")), 1 /* TEXT */),
            _createVNode($setup["UiMultiselect"], {
              name: "audio",
              modelValue: $setup.v$.audio.$model,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.v$.audio.$model) = $event)),
              options: $setup.audioOptions,
              errors: $setup.v$.audio.$silentErrors,
              disabled: $setup.formState.text?.length > 0,
              label: "name",
              "value-prop": "id",
              "can-clear": ""
            }, null, 8 /* PROPS */, ["modelValue", "options", "errors", "disabled"])
          ]),
          _createVNode($setup["UiTextArea"], {
            label: _ctx.$t('connectFlow.node.clientInfo.write'),
            name: "text",
            modelValue: $setup.v$.text.$model,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.v$.text.$model) = $event)),
            disabled: $setup.formState.audio?.name.length > 0,
            errors: $setup.v$.text.$silentErrors
          }, null, 8 /* PROPS */, ["label", "modelValue", "disabled", "errors"]),
          _createVNode($setup["UiTextInput"], {
            label: _ctx.$t('connectFlow.node.clientInfo.timeout'),
            name: "timeout",
            modelValue: $setup.v$.timeout.$model,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.v$.timeout.$model) = $event))
          }, null, 8 /* PROPS */, ["label", "modelValue"]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode($setup["UiButton"], {
              variant: "white",
              "text-variant": "gray-700",
              themed: "",
              onClick: _cache[4] || (_cache[4] = ($event: any) => ($setup.showForm = false))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Cancel")), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode($setup["UiButton"], {
              type: "submit",
              variant: "primary",
              "text-variant": "white",
              themed: ""
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Apply")), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            })
          ])
        ], 32 /* NEED_HYDRATION */)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "title", "icon"])
  ], 64 /* STABLE_FRAGMENT */))
}