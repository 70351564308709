import { createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["UiButton"], {
    variant: "primary",
    "text-variant": "white",
    themed: "",
    onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.downloadFile($props.fileUrl)))
  }, {
    default: _withCtx(() => [
      _createVNode($setup["DownloadIcon"], {
        class: "-ml-1 mr-2 h-5 w-5",
        "aria-hidden": "true"
      }),
      _createTextVNode(" " + _toDisplayString(_ctx.$t("core.actions.Download")), 1 /* TEXT */)
    ]),
    _: 1 /* STABLE */
  }))
}