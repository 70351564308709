import { normalizeStyle as _normalizeStyle, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode($setup["StepEdge"], {
      id: $props.id,
      style: _normalizeStyle($props.style),
      path: $setup.path[0],
      "marker-end": $props.markerEnd
    }, null, 8 /* PROPS */, ["id", "style", "path", "marker-end"]),
    _createVNode($setup["EdgeText"], {
      x: $setup.props.targetX - 0,
      y: $setup.props.targetY - 30,
      style: _normalizeStyle([`stroke: ${$setup.color}`, {"stroke-width":"0.5","filter":"drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.1))"}]),
      "label-show-bg": true,
      "label-bg-padding": [8, 4],
      "label-bg-border-radius": 5
    }, {
      default: _withCtx(() => [
        (
        ($setup.edge.data?.uraOption !== undefined && $setup.edge.data?.uraOption !== null) ||
        $setup.edge.data.waitUser
      )
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createTextVNode(_toDisplayString($setup.edge.label), 1 /* TEXT */)
            ], 64 /* STABLE_FRAGMENT */))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createTextVNode(_toDisplayString(_ctx.$t("connectFlow.edge.uraOption")), 1 /* TEXT */)
            ], 64 /* STABLE_FRAGMENT */))
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["x", "y", "style"])
  ], 64 /* STABLE_FRAGMENT */))
}