import { renderSlot as _renderSlot, vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-5 w-10/12" }
const _hoisted_2 = ["name", "id"]
const _hoisted_3 = { class: "ml-3 text-sm font-medium leading-6 text-gray-900" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", {
    class: _normalizeClass([
      $setup.checkedValue ? 'bg-cyan-50 text-cyan-600' : 'bg-gray-50 text-gray-400',
      'hover:bg-cyan-50 hover:text-cyan-600 rounded shadow ring-1 ring-black ring-opacity-5 w-40 h-40',
      'flex flex-col items-center cursor-pointer relative',
    ])
  }, [
    _renderSlot(_ctx.$slots, "icon"),
    _renderSlot(_ctx.$slots, "extra-info"),
    _createElementVNode("span", _hoisted_1, [
      _withDirectives(_createElementVNode("input", {
        name: $setup.attrs.name,
        id: $setup.attrs.name,
        type: "checkbox",
        class: "h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.checkedValue) = $event))
      }, null, 8 /* PROPS */, _hoisted_2), [
        [_vModelCheckbox, $setup.checkedValue]
      ]),
      _createElementVNode("span", _hoisted_3, _toDisplayString($props.label), 1 /* TEXT */)
    ])
  ], 2 /* CLASS */))
}